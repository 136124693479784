import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { getProductsApi } from "../../helpers/backend_helper";
import { productsApiSuccess } from "./actions";
import { GET_PRODUCTS } from "./actionTypes";

function* getProducts() {
    try {         
        const response = yield call(getProductsApi);
        if (response.status === 200) {        
            yield put(productsApiSuccess(GET_PRODUCTS, response.data));
        }
    }
    catch (error) {        
        // yield put(metricsApiError(GET_PRODUCTS, error));
    }
}

function* watchGetProducts() {
    yield takeEvery(GET_PRODUCTS, getProducts);
}

function* ProductsSaga() {
    yield all(
        [
            fork(watchGetProducts),
        ]
    )
}
export default ProductsSaga