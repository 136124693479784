import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { ADD_NEW_CARD, DELETE_CARD, GET_PAYMENTS_CARDS, SET_DEFAULT_CARD } from "./actionTypes";

import { getAllPaymentsCards, updatePaymentsDefaultCard, deletePaymentsCard, putPaymenstNewCard, putPaymentsNewCard } from "../../helpers/backend_helper";
import { paymentsApiError, paymentsApiSuccess } from "./actions";

function* getPaymentsCards() {    
    try {         
        const response = yield call(getAllPaymentsCards);
        if (response.status === 200) {
            yield put(paymentsApiSuccess(GET_PAYMENTS_CARDS, response.data));
        }
        else {            
            yield put(paymentsApiError(GET_PAYMENTS_CARDS, response));
        }
    }
    catch (error) {        
        yield put(paymentsApiError(GET_PAYMENTS_CARDS, error));
    }
}

function* setDefaultCard({ payload: card }) {
    try {         
        const response = yield call(updatePaymentsDefaultCard, card.cardId);
        if (response.status === 200) {
            yield put(paymentsApiSuccess(SET_DEFAULT_CARD, response.data));
        }
        else {            
            yield put(paymentsApiError(SET_DEFAULT_CARD, response));
        }
    }
    catch (error) {        
        yield put(paymentsApiError(SET_DEFAULT_CARD, error));
    }
}

function* addNewCard({ payload: redirectUrl }) {
    try {        
        const response = yield call(putPaymentsNewCard, redirectUrl);
        if (response.status === 200) {
            yield put(paymentsApiSuccess(ADD_NEW_CARD, response.data));
        }
        else {            
            yield put(paymentsApiError(ADD_NEW_CARD, response));
        }
    }
    catch (error) {        
        yield put(paymentsApiError(ADD_NEW_CARD, error));
    }
}

function* deleteCard({ payload: card }) {
    try {         
        const response = yield call(deletePaymentsCard, card.cardId);
        if (response.status === 200) {
            yield put(paymentsApiSuccess(DELETE_CARD, response.data));
        }
        else {            
            yield put(paymentsApiError(DELETE_CARD, response));
        }
    }
    catch (error) {        
        yield put(paymentsApiError(DELETE_CARD, error));
    }
}

function* watchGetPaymentsCards() {
    yield takeEvery(GET_PAYMENTS_CARDS, getPaymentsCards);
}

function* watchSetDefaultCard() {
    yield takeEvery(SET_DEFAULT_CARD, setDefaultCard);
}

function* watchAddNewCard() {
    yield takeEvery(ADD_NEW_CARD, addNewCard);
}

function* watchDeleteCard() {
    yield takeEvery(DELETE_CARD, deleteCard);
}

function* PaymentsSaga() {
    yield all(
        [
            fork(watchGetPaymentsCards),
            fork(watchSetDefaultCard),
            fork(watchAddNewCard),
            fork(watchDeleteCard)
        ]
    )
    
}

export default PaymentsSaga;