import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import "../App.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { getStripeAccounts, impersonate, resetState, resetStateImpersonate } from '../store/impersonate/actions';

const Impersonate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [stripeData, setStripeData] = useState([]);    
    const [loading, setLoading] = useState(false)

    const { data, error, setImpersonate } = useSelector(state => ({        
        data: state.Impersonate.data,
        error: state.Impersonate.error,
        setImpersonate: state.Impersonate.setImpersonate
    }));

    useEffect(() => {    
        if (data) {
            setStripeData(data);
            setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])

    useEffect(() => {
        if (setImpersonate > 0) {
            toast.success("Set impersonate success", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(resetStateImpersonate())
        }
    }, [setImpersonate])

    useEffect(() => {
        if (data.length === 0) {
            setLoading(true);
            dispatch(getStripeAccounts()); 
        }        
    }, [dispatch]);

    const callImpersonate = (stripeId) => {
        dispatch(impersonate(stripeId));
    }

    document.title = "Impersonate | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Impersonate" pageTitle="Impersonate" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Organizations</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div>                                     
                                </div>                                

                                <CardBody>
                                    <div id="table-impersonate" className="table-card">                                        
                                        <ImpersonateTable data={data} loading={false} callImpersonateFn={callImpersonate} />  
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>
        </React.Fragment>
    )
}

const ImpersonateTable = ({ data, loading, callImpersonateFn }) => {

    const dateSortDesc = (rowA, rowB) => {
        const a = rowA.created;
        const b = rowB.created;
    
        if (a > b) {
            return -1;
        }
    
        if (b > a) {
            return 1;
        }
    
        return 0;
    };

    const columns = [
        {
            name: "User ID",
            selector: row => row.id,
            sortable: true,
        },
        {
            name: "Organization ID",
            selector: row => row.organizationId,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
        },
        {
            name: "Domain",
            selector: row => row.domain,
            sortable: true,
            // sortFunction: dateSortDesc
        },
        {
            name: "Action",
            selector: row => <button className="btn btn-outline-primary btn-sm waves-effect waves-light" onClick={() => callImpersonateFn(row.id)}>IMPERSONATE</button>,
            sortable: false,
            width: '140px',
            center: true
        }
    ];

    const filterColumns=['id', 'organizationId', 'email', 'domain']
    // const filterColumns = undefined

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} loading={loading} filterColumns={filterColumns} defaultSortFieldId={4} paginationPerPage="15" />
        </React.Fragment>
    )
}

export default Impersonate;