import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { ADD_ARTIFACT, DELETE_ARTIFACT, DISABLE_ARTIFACT, ENABLE_ARTIFACT, GET_ARTIFACTS, UPDATE_ARTIFACT } from "./actionTypes";

import { addArtifactApi, deleteArtifactApi, disableArtifactApi, enableArtifactApi, getArtifacsApi, updateArtifactApi } from "../../helpers/backend_helper";
import { jwtApiSuccess, jwtApiError } from "./actions";

function* getArtifacts(data) {
    try {        
        const response = yield call(getArtifacsApi, data.payload.id);
        if (response.status === 200) {
            yield put(jwtApiSuccess(GET_ARTIFACTS, response.data));
        }
        else {            
            yield put(jwtApiError(GET_ARTIFACTS, response));
        }
    }
    catch (error) {
        yield put(jwtApiError(GET_ARTIFACTS, error));
    }
}

function* addArtifact(data) {
    try {                     
        const response = yield call(addArtifactApi, data.payload);
        if (response.status === 200) {            
            yield put(jwtApiSuccess(ADD_ARTIFACT, response.data));
        }
        else {            
            yield put(jwtApiError(ADD_ARTIFACT, response));
        }
    }
    catch (error) {
        yield put(jwtApiError(ADD_ARTIFACT, error));
    }
}

function* updateArtifact(data) {
    try {         
        const response = yield call(updateArtifactApi, data.payload);
        if (response.status === 200) {
            yield put(jwtApiSuccess(UPDATE_ARTIFACT, response.data));
        }
        else {            
            yield put(jwtApiError(UPDATE_ARTIFACT, response));
        }
    }
    catch (error) {
        yield put(jwtApiError(UPDATE_ARTIFACT, error));
    }
}

function* enableArtifact(data) {
    try {         
        const response = yield call(enableArtifactApi, data.payload.row.id);
        if (response.status === 200) {
            yield put(jwtApiSuccess(ENABLE_ARTIFACT, response.data));
        }
        else {            
            yield put(jwtApiError(ENABLE_ARTIFACT, response));
        }
    }
    catch (error) {
        yield put(jwtApiError(ENABLE_ARTIFACT, error));
    }
}

function* disableArtifact(data) {
    try {         
        const response = yield call(disableArtifactApi, data.payload.row.id);
        if (response.status === 200) {
            yield put(jwtApiSuccess(DISABLE_ARTIFACT, response.data));
        }
        else {            
            yield put(jwtApiError(DISABLE_ARTIFACT, response));
        }
    }
    catch (error) {
        yield put(jwtApiError(DISABLE_ARTIFACT, error));
    }
}

function* deleteArtifact(data) {
    try {        
        const response = yield call(deleteArtifactApi, data.payload.row.id);
        if (response.status === 200 || response.status === 204) {            
            yield put(jwtApiSuccess(DELETE_ARTIFACT, response.data));
        }
        else {            
            yield put(jwtApiError(DELETE_ARTIFACT, response));
        }
    }
    catch (error) {
        yield put(jwtApiError(DELETE_ARTIFACT, error));
    }
}

function* watchGetArtifacts() {
    yield takeEvery(GET_ARTIFACTS, getArtifacts);
}

function* watchAddArtifact() {
    yield takeEvery(ADD_ARTIFACT, addArtifact);
}

function* watchUpdateArtifacts() {
    yield takeEvery(UPDATE_ARTIFACT, updateArtifact);
}

function* watchEnableArtifacts() {
    yield takeEvery(ENABLE_ARTIFACT, enableArtifact);
}

function* watchDisableArtifacts() {
    yield takeEvery(DISABLE_ARTIFACT, disableArtifact);
}

function* watchDeleteArtifacts() {
    yield takeEvery(DELETE_ARTIFACT, deleteArtifact);
}

function* JwtSaga() { 
    yield all(
        [
            fork(watchAddArtifact),
            fork(watchGetArtifacts),
            fork(watchUpdateArtifacts),
            fork(watchDisableArtifacts),
            fork(watchEnableArtifacts),
            fork(watchDeleteArtifacts),
        ]
    )    
}

export default JwtSaga;