import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import FeatherIcon from 'feather-icons-react';
import { getMetrics, getMetricsGroupData } from '../../store/actions';
import { ToastContainer, toast } from 'react-toastify';
import { MetricsChart } from "./MetricsChart";
import Select from 'react-select';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';
import Flatpickr from "react-flatpickr";
import { getMetricsFilterApi } from '../../helpers/backend_helper';

const ChartPanel = ({ setTableData, chartSpinner, setChartSpinner }) => {

    const dispatch = useDispatch();

    const fp = useRef(null);

    const [chartData, setChartData] = useState([]);
    const [metricsData, setMetricsData] = useState(null);
    const [getChartData, setGetChartData] = useState(false);
    const [chartPeriod, setChartPeriod] = useState(24);
    const [customDate, setCustomDate] = useState([]);
    
    const [filterSpinner, setFilterSpinner] = useState(true);

    const [apiKeysValue, setApiKeysValue] = useState([]);
    const [apiKeysOption, setApiKeysOption] = useState([]);

    const [metricValue, setMetricValue] = useState({});
    const [metricOption, setMetricOption] = useState([]);

    const [dimensionValue, setDimensionValue] = useState({});
    const [dimensionOption, setDimensionOption] = useState([]);

    const [dataCenterValue, setDataCenterValue] = useState([]);
    const [dataCenterOption, setDataCenterOption] = useState([]);

    const [dataSourceValue, setDataSourceValue] = useState([]);
    const [dataSourceOption, setDataSourceOption] = useState([]);

    const [operationNameValue, setOperationNameValue] = useState([]);
    const [operationNameOption, setOperationNameOption] = useState([]);

    const [protocolNameValue, setProtocolNameValue] = useState([]);
    const [protocolNameOption, setProtocolNameOption] = useState([]);

    const [serviceNameValue, setServiceNameValue] = useState([]);
    const [serviceNameOption, setServiceNameOption] = useState([]);

    const apiKeyRef = React.useRef();
    const metricKeyRef = React.useRef();
    const groupByKeyRef = React.useRef();

    const [modal_standard, setmodal_standard] = useState(false);
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const { metrics, chart, error } = useSelector(state => ({
        metrics: state.Metrics.metrics,
        chart: state.Metrics.metricsData,
        error: state.Metrics.error
    }));

    useEffect(() => {        
        if (metrics?.metrics) {
            let dictMetrics = [];
            for (const metricKey in metrics.metrics) {
                if (metrics.metrics.hasOwnProperty(metricKey)) {
                  const metricArray = metrics.metrics[metricKey];
                  metricArray.forEach(metric => {                    
                    dictMetrics.push({metricName: metric.metricName, dimensions: metric.dimensions})
                  });            
                }
            }
            setMetricsData(dictMetrics);

            let metric = metrics.metricName.map(x => { return {value: x.id, label: x.text + ` (${x.unit})`}});
            setMetricOption(metric);
            setMetricValue(metric[0]);
            
            // setApiKeysOption(metrics.apiKeys.map(x => {return {value: x.id, label: x.text}}));
            // setDataCenterOption(metrics.dataCenter.map(x => {return {value: x.id, label: x.text}}));
            // setDataSourceOption(metrics.dataSource.map(x => {return {value: x.id, label: x.text}}));
            // setOperationNameOption(metrics.operationName.map(x => {return {value: x.id, label: x.text}}));
            // setProtocolNameOption(metrics.protocolName.map(x => {return {value: x.id, label: x.text}}));
            // setServiceNameOption(metrics.serviceName.map(x => {return {value: x.id, label: x.text}}));            

            if(dictMetrics[0]?.dimensions?.length > 0) {
                let dimensions = dictMetrics[0].dimensions.map(x => { return {value: x, label: x}});
                setDimensionOption(dimensions);                
                setDimensionValue(dimensions[0]);
            }
            setGetChartData(true);
        }            
        setFilterSpinner(false);
    }, [metrics]);

    useEffect(() => {        
        setChartData(chart);
        let tableData = [];
        let sum = sumArray(chart.rows ?? []);
        chart.seriesLabel?.forEach(function(metric, idx) {
            tableData.push([metric, sum[idx]]);
        });
        setTableData(tableData);
        setChartSpinner(false);
    }, [chart]);

    useEffect(() => {        
        toast.error(error, {
            position: toast.POSITION.TOP_RIGHT
          });
    }, [error])

    useEffect(() => {        
        if (getChartData) {                          
            setChartSpinner(true);
            let start, end;
            if (chartPeriod === 'custom') {
                start = formatDate(customDate[0]);
                end = formatDate(customDate[1]);
            }
            else {
                start = calculateDate(chartPeriod);
                end = calculateDate(0);
            }              

            dispatch(getMetricsGroupData({                    
                MetricName: metricValue?.value,                    
                GroupBy: dimensionValue?.value,
                time_start: start,
                time_end: end,
                ApiKey: apiKeysValue.map(x => x.value),
                DataCenter: dataCenterValue.map(x => x.value),
                DataSource: dataSourceValue.map(x => x.value),
                OperationName: operationNameValue.map(x => x.value),
                ProtocolName: protocolNameValue.map(x => x.value),
                ServiceName: serviceNameValue.map(x => x.value),
            }));                            
            setGetChartData(false);            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getChartData])

    useEffect(() => {
        setFilterSpinner(true);
        dispatch(getMetrics());
    }, [dispatch]);

    useEffect(() => {
        if (metricValue?.value !== undefined) {
            getMetricsFilterApi({metric_name: metricValue.value}).then((response) => {                
                setApiKeysOption(response.data.apiKeys.map(x => {return {value: x.id, label: x.text}}));
                setDataCenterOption(response.data.dataCenter.map(x => {return {value: x.id, label: x.text}}));
                setDataSourceOption(response.data.dataSource.map(x => {return {value: x.id, label: x.text}}));
                setOperationNameOption(response.data.operationName.map(x => {return {value: x.id, label: x.text}}));
                setProtocolNameOption(response.data.protocolName.map(x => {return {value: x.id, label: x.text}}));
                setServiceNameOption(response.data.serviceName.map(x => {return {value: x.id, label: x.text}}));
            });
        }
    }, [metricValue]);

    const onChangeChartPeriod = pType => { 
        if (pType !== 'custom') {
            fp.current.flatpickr.clear();
        }
        setChartPeriod(pType);
        onChangeChart();
    };

    const onChangeChart = () => {        
        setGetChartData(true);        
    };

    const onChangeDimension = (obj) => {        
        setDimensionValue(obj);
        onChangeChart();
    };

    const onChangeApiKeys = (obj) => {        
        setApiKeysValue(obj);        
        onChangeChart();
    }

    const onChangeDataCenter = (obj) => {
        setDataCenterValue(obj);
        onChangeChart();
    }

    const onChangeDataSource = (obj) => {
        setDataSourceValue(obj);
        onChangeChart();
    }

    const onChangeOperationName = (obj) => {
        setOperationNameValue(obj);
        onChangeChart();
    }

    const onChangeProtocolName = (obj) => {
        setProtocolNameValue(obj);
        onChangeChart();
    }

    const onChangeServiceName = (obj) => {
        setServiceNameValue(obj);
        onChangeChart();
    }

    const onChangeMetric = (obj) => {        
        setMetricValue(obj);        
        let res = metricsData.filter((x) => x.metricName === obj.value);        
        let dimension = res[0]?.dimensions?.map(x => { return {value: x, label: x}});
        if (dimension !== null && dimension.length > 0) {
            setDimensionOption(dimension);
            setDimensionValue(dimension[0]);
        }
        onChangeChart();
    };

    function calculateDate(hours) {
        var today = new Date();
        today.setHours(today.getHours() - hours);
        // var calcDate = today.add(-hours);
        return formatUTCDate(today);
    }

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getUTCDate() + days);
        return date;
    };

    function formatUTCDate(d) {
        return d.getFullYear() + '-' + leftPad(d.getUTCMonth()+1, 2) + '-' + leftPad(d.getUTCDate(), 2) + 'T' + leftPad(d.getUTCHours(), 2) + ':' + leftPad(d.getUTCMinutes(), 2);
    }
    
    function formatDate(d) {
        return d.getFullYear() + '-' + leftPad(d.getMonth()+1, 2) + '-' + leftPad(d.getDate(), 2) + 'T' + leftPad(d.getHours(), 2) + ':' + leftPad(d.getMinutes(), 2);
    }

    function leftPad(number, targetLength) {
        var output = number + '';
        while (output.length < targetLength) {
            output = '0' + output;
        }
        return output;
    }

    const sumArray = (array) => {
        const newArray = [];
        array.forEach(sub => {
           sub.forEach((num, index) => {
              if (index != 0) {
                  if (newArray[index-1]) {
                     newArray[index-1] += num;
                  } else {
                     newArray[index-1] = num;
                  }
              }
           });
        });
        return newArray;
     }

    return (
        <React.Fragment>
            <Row>
                <ToastContainer />
                <Col xxl={12}>
                    <Card>
                        <CardBody className="p-0">
                            <Row className="g-0">
                                <Col xxl={8}>
                                    <div className="">
                                        <CardHeader className="border-0 align-items-center d-flex" style={{height: '70.5px'}}>
                                            <h4 className="card-title mb-0 flex-grow-1">Metrics Chart</h4>
                                            <div className='d-flex gap-1'>
                                                <LoadingSpinner show={chartSpinner} />
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 1 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(1); }}>
                                                    1H
                                                </button>
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 3 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(6); }}>
                                                    6H
                                                </button>
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 24 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(24); }}>
                                                    1D
                                                </button>
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 24*3 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(24*3); }}>
                                                    3D
                                                </button>
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 24*7 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(7*24); }}>
                                                    1W
                                                </button>
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 14*24 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(14*24); }}>
                                                    2W
                                                </button>
                                                <button type="button" className={`btn btn-soft-secondary ${chartPeriod === 30*24 ? 'btn-active-chart' : ''} btn-sm`} onClick={() => { onChangeChartPeriod(30*24); }}>
                                                    1M
                                                </button>
                                                <Flatpickr    
                                                    ref={fp}
                                                    id='metrics-chart-date-range'   
                                                    className={`btn btn-soft-secondary btn-sm ` + (chartPeriod === 'custom' ? 'btn-active-chart' : '')}     
                                                    placeholder='RANGE'                                                                                       
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        mode: "range",                                                 
                                                    }}           
                                                    onChange={function(selectedDates, dateStr, instance) {                                                          
                                                        if (selectedDates.length === 2) {
                                                            setCustomDate(selectedDates);
                                                            onChangeChartPeriod('custom')
                                                        }                                                                                                                
                                                    }}
                                                />                                              
                                            </div>
                                        </CardHeader>                                        
                                        <MetricsChart data={chartData} minDate={calculateDate(chartPeriod)} dataColors='["--vz-primary","--vz-success", "--vz-gray-300"]' />
                                    </div>
                                </Col>

                                <Col xxl={4}>
                                    <div className="border-start p-4 h-100 d-flex flex-column">

                                        <div className="w-100">
                                            <div className="d-flex align-items-center">                                                
                                                <FeatherIcon icon="filter" color="purple" />
                                                <div className="ms-3 flex-grow-1">
                                                    <h5 className="fs-16 mb-1">Filters</h5>
                                                    <p className="text-muted mb-0">Select the options</p>
                                                </div>
                                                <div className='d-flex gap-1'>
                                                    <LoadingSpinner show={filterSpinner} />
                                                </div>                                                
                                            </div>
                                            <br />

                                            <p className="text-muted" style={{marginBottom: '4px'}}>Metric</p>                                            
                                            <Select options={metricOption} value={metricValue || metricOption[0]} isLoading={filterSpinner} ref={metricKeyRef} onChange={(value) => onChangeMetric(value)} />
                                            
                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Group By</p>                            
                                            <Select options={dimensionOption} value={dimensionValue || dimensionOption[0]} isLoading={filterSpinner} ref={groupByKeyRef} onChange={(value) => onChangeDimension(value)} />

                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Api Keys</p>
                                            <Select 
                                                placeholder='All API keys'
                                                isMulti={true}
                                                options={apiKeysOption} 
                                                isLoading={filterSpinner} 
                                                ref={apiKeyRef} 
                                                onChange={(value) => onChangeApiKeys(value)} 
                                            />
                                            
                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Data Center</p>
                                            <Select 
                                                placeholder='All data centers'
                                                isMulti={true}
                                                options={dataCenterOption} 
                                                isLoading={filterSpinner}                                                 
                                                onChange={(value) => onChangeDataCenter(value)} 
                                            />

                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Data Source</p>
                                            <Select 
                                                placeholder='All data sources'
                                                isMulti={true}
                                                options={dataSourceOption} 
                                                isLoading={filterSpinner}                                                 
                                                onChange={(value) => onChangeDataSource(value)} 
                                            />

                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Operation Name</p>
                                            <Select 
                                                placeholder='All operation names'
                                                isMulti={true}
                                                options={operationNameOption} 
                                                isLoading={filterSpinner}                                                 
                                                onChange={(value) => onChangeOperationName(value)} 
                                            />                                                                     

                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Protocol Name</p>
                                            <Select 
                                                placeholder='All protocol names'
                                                isMulti={true}
                                                options={protocolNameOption} 
                                                isLoading={filterSpinner}                                                 
                                                onChange={(value) => onChangeProtocolName(value)} 
                                            />           

                                            <p className="text-muted" style={{marginTop: "10px", marginBottom: '4px'}}>Service Name</p>
                                            <Select 
                                                placeholder='All service names'
                                                isMulti={true}
                                                options={serviceNameOption} 
                                                isLoading={filterSpinner}                                                 
                                                onChange={(value) => onChangeServiceName(value)} 
                                            />      

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>              

            <Modal id="myModal"
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <ModalHeader className="modal-title"
                    id="myModalLabel" toggle={() => {
                        tog_standard();
                    }}>

                    RAW data
                </ModalHeader>
                <ModalBody>       
                    <p className="text-muted">
                        <pre>{JSON.stringify(chartData, null, 2)}</pre>    
                    </p>                    
                </ModalBody>
                <div className="modal-footer">
                    <Button
                        color="light"
                        onClick={() => {
                            tog_standard();
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>      
        </React.Fragment>
    );
}

export default ChartPanel;