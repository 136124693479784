import { API_RESPONSE_SUCCESS, GET_PRODUCTS } from "./actionTypes"

const INIT_STATE = {
    products: []
}

const Products = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_PRODUCTS:
                    return {
                        ...state,
                        products: action.payload.data
                    }
                default:
                    return state
            }
            default:
                return state
    }
}
export default Products