import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useProfile } from "../Components/Hooks/UserHooks";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();

  useEffect(() => {
    if (userProfile) {
      // setAuthorization(token);
    }
    // else if (!userProfile && !token) {
    //   dispatch(logoutUser());
    // }
  }, [userProfile, dispatch]);

  /*
    Navigate is un-auth access protected routes via url
    */
  if (!userProfile) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
