import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL, INVITE_USER, INVITE_USER_SUCCESS, INVITE_USER_FAIL, DELETE_INVITE_USER, GET_USER_CONTEXT, GET_USER_CONTEXT_SUCCESS, SET_USER_CONTEXT, SET_USER_CONTEXT_SUCCESS, RESET_ERROR, GET_ORGANIZATION, UPDATE_ORGANIZATION_SUCCESS, GET_ORGANIZATION_SUCCESS } from './actions';

const initialState = {
  users: [],
  userContext: [],
  loading: false,
  error: null,
  finishInviteUserAction: false,
  finishSetUserContext: false,
  organization: {}
};

export default function usersReducer(state = initialState, action) {
  switch(action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
        finishInviteUserAction: false
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case GET_USER_CONTEXT:
      return {
        ...state,
      };
    case GET_USER_CONTEXT_SUCCESS:
      return {
        ...state,
        userContext: action.payload.context
      };
    case SET_USER_CONTEXT:
      return {
        ...state
      };
    case SET_USER_CONTEXT_SUCCESS: 
      return {
        ...state,
        finishSetUserContext: true
      };
    case INVITE_USER:
      return {
        ...state,
        loading: true,
        finishInviteUserAction: false
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        finishInviteUserAction: true
      };
    case INVITE_USER_FAIL:
      return {
        ...state,
        loading: false,        
        error: action.payload.error
      };
    case DELETE_INVITE_USER:
      return {
        ...state
      };
    case RESET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload
      };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload
      };      
    default:
      return state;
  }
}