import React, { useEffect, useState } from 'react';
import { getTracesConnectionsApi, getTracesConnectionsEventsApi } from "../helpers/backend_helper";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Badge,
    UncontrolledCollapse,
} from "reactstrap";

import BreadCrumb from '../Components/Common/BreadCrumb';
import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { dateInYyyyMmDdHhMmSs } from '../helpers/common';

const TracesConnections = () => {    

    const [traces, setTraces] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        getTraces();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])

    const getTraces = () => {
        setLoading(true);
        callToGetTraces().then(() => setLoading(false));
    }

    const callToGetTraces = async () => {
        try {
            const response = await getTracesConnectionsApi();

            if (response.status === 200) {
                setTraces(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    document.title = "Traces (Connections) | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Traces (Connections)" pageTitle="Traces View" />

                    <Row>                        
                        <Col xl={12} lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{ height: "70.5px" }}>
                                    <h4 className="card-title mb-0 flex-grow-1">Traces (Connections) List</h4>
                                    <div className='d-flex gap-1' style={{ marginRight: '5px' }}>
                                        <LoadingSpinner show={loading} />
                                    </div>
                                </div>

                                <CardBody>
                                    <div className="table-card">
                                        <TracesTable data={traces} loading={loading} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}
export default TracesConnections;

const TracesTable = ({ data, loading }) => {    

    const columns = [
        {
            name: "Connection ID",
            selector: row => row.connectionId,
            // format: row => dateInYyyyMmDdHhMmSs(new Date(row.timestamp)),
            sortable: true,            
        },
        {
            name: "Start Time",
            selector: row => row.connectionStartTime,
            sortable: true,            
        },
        {
            name: "End Time",
            selector: row => row.lastHeartbeat,
            sortable: true,            
        },
        {
            name: "Status",
            selector: row => row.isConnected ? "Connected" : "Disconnected",
            sortable: true,            
        },
        {
            name: "Service Name",
            selector: row => row.serviceName,
            sortable: true,
        },
        {
            name: "Protocol Name",
            selector: row => row.protocolName,
            sortable: true,
        }
    ];

    // Utility function to format date strings
    const formatDate = (dateString) => {
        const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit'
        };
        return new Date(dateString).toLocaleString(undefined, options);
    };

    // Utility function to format cell content based on its type
    const formatCellContent = (content) => {
        if (content === null || content === undefined) return 'N/A';
        if (typeof content === 'boolean') return content ? 'Yes' : 'No';
        if (typeof content === 'object') return JSON.stringify(content);
        if (typeof content === 'string' && content.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
            return formatDate(content);
        }
        return String(content);
    };

    const ConnectionDataDisplay = ({ data }) => {
        return (
            <>
                <h5 style={{ marginTop: 0 }}>Connection Details:</h5>
                <div style={{ marginBottom: '20px', padding: '15px', border: '1px solid #ddd', borderRadius: '5px' }}>                
                    {Object.entries(data).map(([key, value]) => (
                        <p key={key} style={{ margin: '5px 0' }}>
                            <strong>{key}:</strong> {formatCellContent(value)}
                        </p>
                    ))}
                </div>
            </>
        );
    };

    const EventsTable = ({ events }) => {
        if (!events || events.length === 0) {
            return <p>No events to display.</p>;
        }
    
        // Assuming events is an array of objects with properties
        const headers = Object.keys(events[0]);        
    
        return (
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header} style={{ border: '0.1px solid gray', padding: '8px', textAlign: 'left' }}>
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {events.map((event, index) => (
                        <tr key={index}>
                            {headers.map((header) => (
                                <td key={`${index}-${header}`} style={{ border: '0.1px solid gray', padding: '8px' }}>
                                    {typeof event[header] === 'object' 
                                        ? JSON.stringify(event[header]) 
                                        : String(event[header])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const ExpandedComponent = ({ data }) => {

        const [events, setEvents] = useState(null);
        const [error, setError] = useState(null);

        useEffect(() => {
            const fetchEvents = async () => {
                try {
                    const result = await getTracesConnectionsEventsApi(data.connectionId);
                    setEvents(result.data);
                } catch (e) {
                    console.error(e);
                    setError(e.message);
                }
            };
    
            fetchEvents();
        }, [data.connectionId]);

        return (
            // <>
            //     <pre>{JSON.stringify(data, null, 2)}</pre>
            //     {error ? (
            //         <p>Error: {error}</p>
            //     ) : events ? (
            //         // <pre>{JSON.stringify(events, null, 2)}</pre>
            //         <EventsTable events={events} />
            //     ) : (
            //         <p>Loading events...</p>
            //     )}
            // </>
            <div style={{ fontFamily: 'var(--vz-font-monospace)', fontSize: '11px',  padding: '13px' }}>
                <ConnectionDataDisplay data={data} />
                
                <h5>Events:</h5>
                {error ? (
                    <p style={{ color: 'red' }}>Error: {error}</p>
                ) : events ? (
                    <EventsTable events={events} />
                ) : (
                    <p>Loading events...</p>
                )}
            </div>            
        );
    }

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} paginationPerPage="15" filterColumns={['spanName', 'serviceName']}
                expandableRows={true} expandableRowsComponent={ExpandedComponent} />
        </React.Fragment>
    )
}