import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';

import Section from './Section';
import TicketDescription from './TicketDescription';
import TicketDetails from './TicketDetails';
import { getUserTicketCommentsApi, getUserTicketsApiById, addUserCommentToTicketApi } from '../../helpers/backend_helper';

import { ToastContainer, toast } from 'react-toastify';
import { useSearchParams, useNavigate } from "react-router-dom";

const TicketDetailsIdx = () => {

    const [ticket, setTicket] = useState({});
    const [ticketComments, setTicketComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const [urlParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        getTicket();
        getTicketComments();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])

    const getTicket = () => {
        setLoading(true);
        callToGetTicketInfo().then(() => setLoading(false));
    }

    const getTicketComments = () => {
        setLoading(true);
        callToGetTicketComments().then(() => setLoading(false));
    }

    const callToGetTicketInfo = async () => {
        try {
            const response = await getUserTicketsApiById(urlParams.get('id'));

            if (response.status === 200) {
                setTicket(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callToGetTicketComments = async () => {
        try {
            const response = await getUserTicketCommentsApi(urlParams.get('id'));

            if (response.status === 200) {
                setTicketComments(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const addCommentToTicket = (id, comment) => {
        setLoading(true);
        callToAddComment(id, comment).then(() => {
            setLoading(false);
            getTicketComments();
        });
    }

    const callToAddComment = async (id, comment) => {
        try {
            var data = { ticket_id: id, content: comment };
            const response = await addUserCommentToTicketApi(data);

            if (response.status === 200) {
                toast.success("Comment added successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Section ticket={ticket} navigate={navigate} />
                    </Row>
                    <Row>
                        <TicketDescription ticket={ticket} comments={ticketComments} addCommentFn={addCommentToTicket} />
                        <TicketDetails ticket={ticket} comments={ticketComments} />
                    </Row>

                    <ToastContainer />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default TicketDetailsIdx;