import React, { useEffect, useState } from 'react';

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
  } from "reactstrap";

import BreadCrumb from './BreadCrumb';
import TableDatatable from './TableDatatable';
import LoadingSpinner from './LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const SimpleTableViewComponent = ({title, pageTitle, apiCall, columns, filterColumns = [], pagination = true, exportToCsv = false, exportToCsvApi}) => {

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        getTableData();
    }, []);

    useEffect(() => {                
        if (error) {            
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])  
    
    const getTableData = () => {
        setLoading(true);
        callToGetTableData().then(() => setLoading(false));
    }

    const callToGetTableData = async () => {
        try {
            const response = await apiCall();

            if (response.status === 200) {                
                setTableData(response.data);                
            }
            else {
                setError(response);
            }            
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const handleDownload = () => {
        setLoading(true);
        exportToCsvApi().then(async (response) => {
            if (response.status === 200) {                
                var blob = new Blob([response.data], {
                    type: "text/plain;charset=utf-8",
                  });
                saveAs(blob, `export-data.csv`);
                setLoading(false);
            }
            else {
                setError(response);
                setLoading(false);
            }
        });
    }

    document.title = title + " | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={title} pageTitle={pageTitle} />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">{pageTitle}</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                        {exportToCsv &&
                                            <Button color="primary" className="btn-sm" onClick={() => handleDownload()}><i className="fas fa-sync-alt"></i> Export data</Button>  
                                        } 
                                    </div>                                    
                                </div>                                                     

                                <CardBody>
                                    <div className="table-card">
                                        <TableDatatable data={tableData} columns={columns} filterColumns={filterColumns} paginationPerPage="15" pagination={pagination} />
                                    </div>
                                </CardBody>
                            </Card>                
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}
export default SimpleTableViewComponent;