import React from "react";
import { Input, Button, InputGroup, Container, Row, Col } from 'reactstrap';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>    
    <Container fluid className="filter-table">
    <Row>
      <div class="border border-dashed border-end-0 border-start-0 card-body">
        <form>
          <div class="row">
            <div class="col-sm-5">
              <div class="search-box me-2 mb-2 d-inline-block col-12">
                {/* <input placeholder="Search..." id="search-bar-0" class="form-control border-0 search" value=""> */}
                <Input
                  type="text"
                  placeholder="Filter..."
                  value={filterText}
                  onChange={onFilter}
                  style={{ width: '400px', border: 'none'}}
                  className="ml-auto"
                />
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Row>
    </Container>

    {/* <Container fluid>
      <Row>
        <Col lg={6}></Col>
        <Col lg={6}>
          <InputGroup>
            <Input
                type="text"
                placeholder="Filter..."
                value={filterText}
                onChange={onFilter}
                style={{ width: '200px' }}
                className="ml-auto"
                />
            <Button color="primary" className="ml-auto" onClick={onClear}>
            X
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Container>     */}
  </>
);

export default FilterComponent;