import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_GETCODE, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { login } from "../../../helpers/backend_helper";

function* loginGetCode({ payload: { user, history } }) {
  // try {    
  //   const response = yield call(fireBaseBackend.loginUser,
  //     user.email
  //   );
  //   if (response.status === 200) {
  //     yield put(loginGetCodeSuccess(response));                      
  //   } else {
  //     yield put(apiError(response));
  //   }
  // } catch (error) {
  //   yield put(apiError(error));
  // }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, user);
    if (response.status === 200) {            
      yield put(loginSuccess(response));
      history('/');      
    } else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {        
    // async function removeSession() {
    //   await AsyncSessionStorage.removeItem("authUser");  
    // }
    // removeSession();
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const fireBaseBackend = getFirebaseBackend();
    //   const response = yield call(
    //     fireBaseBackend.socialLoginUser,
    //     data,
    //     type,
    //   );
    //   sessionStorage.setItem("authUser", JSON.stringify(response));
    //   yield put(loginSuccess(response));
    // } else {
    //   const response = yield call(postSocialLogin, data);
    //   sessionStorage.setItem("authUser", JSON.stringify(response));
    //   yield put(loginSuccess(response));
    // }
    history('/dashboard')
    // history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_GETCODE, loginGetCode);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
