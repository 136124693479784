import { API_RESPONSE_SUCCESS, API_RESPONSE_ERROR, GET_ARTIFACTS, ADD_ARTIFACT, ENABLE_ARTIFACT, DISABLE_ARTIFACT, DELETE_ARTIFACT, UPDATE_ARTIFACT, RESET_STATE } from "./actionTypes"

const INIT_STATE = {
    error: null, 
    success: false,
    successAddArtifact: false,
    artifacts: [],
}

const jwt = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_ARTIFACTS:
                    return {
                        ...state, 
                        artifacts: action.payload.data
                    }
                case ADD_ARTIFACT:
                case UPDATE_ARTIFACT:
                    return {
                        ...state,
                        successAddArtifact: true
                    }
                case ENABLE_ARTIFACT:
                case DISABLE_ARTIFACT:
                case DELETE_ARTIFACT:
                    return {
                        ...state,
                        success: true
                    }
                default:
                    return state;
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_ARTIFACTS:
                case ADD_ARTIFACT:
                case UPDATE_ARTIFACT:
                case DELETE_ARTIFACT:
                case ENABLE_ARTIFACT:
                case DISABLE_ARTIFACT:
                    return {
                        ...state, 
                        error: action.payload.error
                    }
                default:
                    return state;
            }
        case RESET_STATE:
            state.success = INIT_STATE.success;
            state.successAddArtifact = INIT_STATE.successAddArtifact;
            return state;        
        default:
            return state;
    }
}
export default jwt;