import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../Components/Common/BreadCrumb';
import DeleteModal from '../Components/Common/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentsCards, setDefaultCard, addNewCard, deleteCard } from '../store/actions';
import { useNavigate } from "react-router-dom";

import "../App.css";
import visa from "../assets/images/cards/visa.png";
import mastercard from "../assets/images/cards/mastercard.png";
import americanexpress from "../assets/images/cards/americanexpress.png";
import defaultcard from "../assets/images/cards/default.png";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { loadStripe } from '@stripe/stripe-js/pure';
import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';

const Payments = ({setError, setUpdate }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedCard, setDeleteCard] = useState(null);
    const [cardsData, setCardsData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false)

    const { cards, error, stripeSession, finishSetDefaultCard, finishDeleteCard } = useSelector(state => ({
        cards: state.Payments.cards,
        error: state.Payments.error,
        stripeSession: state.Payments.stripeSession,
        finishSetDefaultCard: state.Payments.finishSetDefaultCard,
        finishDeleteCard: state.Payments.finishDeleteCard
    }));

    useEffect(() => {
        if (cards) {
            setCardsData(cards);
            setInitLoading(false);
            setLoading(false);
        }
    }, [cards]);

    useEffect(() => {
        if (stripeSession.link !== undefined) {
            window.location.href = stripeSession.link;            
        }
    }, [stripeSession]);

    useEffect(() => {
        setLoading(true);
        dispatch(getPaymentsCards());
    }, [dispatch]);

    useEffect(() => {
        if (finishSetDefaultCard > 0) {
            dispatch(getPaymentsCards());
        }
    }, [finishSetDefaultCard]);

    useEffect(() => {
        if (finishDeleteCard === true) {
            dispatch(getPaymentsCards());
        }
    }, [finishDeleteCard]);

    const onClickCardDelete = (card) => {
        setDeleteCard(card);
        setDeleteModal(true);
    };

    const handleDeleteTodo = () => {
        if (selectedCard) {
            dispatch(deleteCard(selectedCard));
            dispatch(getPaymentsCards());
            setLoading(true);
        }
        setDeleteModal(false);
    };

    const onClickSetDefaultCard = (card) => {
        dispatch(setDefaultCard(card));
        setLoading(true);
    }

    const onClickAddNewCard = () => {
        dispatch(addNewCard("/billing?tab=payment-methods"));
    }

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />

            <Row>
                <Col lg={12}>
                    <Card>
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1"></h4>
                            <div className='d-flex gap-1' style={{ marginRight: '5px' }}>
                                <LoadingSpinner show={loading} />
                            </div>
                            <div className="flex-shrink-0">
                                <button type="button" className="btn btn-primary" onClick={() => onClickAddNewCard()}>
                                    ADD NEW CREDIT CARD
                                </button>
                            </div>
                        </div>

                        <CardBody>
                            <div id="table-payments" className="table-card">
                                {/* {cardsData != null
                                            ?   <PaymentsTable data={cardsData} 
                                                    deleteFunction={onClickCardDelete} 
                                                    setDefaultFunction={onClickSetDefaultCard} />  
                                            :   <div style={{margin: "10px"}}>
                                                    <ScaleLoader color="#36d7b7" />
                                                </div>
                                        } */}
                                <PaymentsTable data={cardsData} loading={false}
                                    deleteFunction={onClickCardDelete}
                                    setDefaultFunction={onClickSetDefaultCard} />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>            

        </React.Fragment>
    )
}

const PaymentsTable = ({ data, deleteFunction, setDefaultFunction, loading }) => {

    const columns = [
        {
            name: "Credit Card",
            selector: row => <CardIcon type={row.cardType} name={row.ending} />,
            sortable: false,
        },
        {
            name: "Name on card",
            selector: row => row.owner,
            sortable: true,
        },
        {
            name: "Expiring",
            selector: row => row.expiringMonth + "/" + row.expiringYear,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => <IsDefault card={row} setDefaultFunction={setDefaultFunction} />,
            sortable: false,
        },
        {
            name: "",
            selector: row => <Action card={row} deleteFunction={deleteFunction} />,
            sortable: false,
            // style: {width: "50px"}
            center: true
        }
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} loading={loading} />
        </React.Fragment>
    )
}

const CardIcon = ({ type, name }) => {
    let card = <></>

    switch (type) {
        case "visa":
            card = <><img className="credit-card__img" src={visa} alt="visa" />Visa ending in {name}</>
            break;
        case "mastercard":
            card = <><img className="credit-card__img" src={mastercard} alt="mastercaard" />Mastercard ending in {name} </>
            break;
        case "americanexpress":
            card = <><img className="credit-card__img" src={americanexpress} alt="americanexpress" />AmericanExpress ending in {name} </>
            break;
        default:
            const capitalized = type.charAt(0).toUpperCase() + type.slice(1);
            card = <><img className="credit-card__img" src={defaultcard} alt={type} />{capitalized} ending in {name}</>
            break
    }

    return (card);
    // return (
    //     <>
    //         {type === "visa" && 

    //         }
    //         {type === "mastercard" && 
    //             <><img className="credit-card__img" src={mastercard} alt="mastercaard"/>Mastercard ending in {name} </>
    //         }
    //         {type === "americanexpress" && 
    //             <><img className="credit-card__img" src={americanexpress} alt="americanexpress"/>AmericanExpress ending in {name} </>
    //         }
    //     </>
    // )
}

const IsDefault = ({ card, setDefaultFunction }) => {
    return (
        <>
            {card.isDefault === true
                ? <button className="btn btn-info btn-sm waves-effect waves-light" disabled={true}>DEFAULT CARD</button>
                : <button type="button" className="btn btn-outline-primary btn-sm waves-effect waves-light" onClick={() => setDefaultFunction(card)}>SET AS DEFAULT</button>
            }
        </>
    )
}

const Action = ({ card, deleteFunction }) => {
    return (
        <div className="hstack gap-2">
            <button className="btn btn-sm btn-soft-danger" onClick={() => deleteFunction(card)}>
                <i className="ri-delete-bin-5-fill align-bottom" />
            </button>
        </div>
    )
}

export default Payments;