import React, { useEffect, useState } from 'react';
// import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Modal, ModalBody, ModalHeader, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledDropdown,
  } from "reactstrap";
import BreadCrumb from '../Components/Common/BreadCrumb';
import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getPayUrl, getSubscriptionManageLink, getSubscriptionProducts, getSubscriptions, resetApikeysDataState, resetState } from '../store/actions';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { useNavigate, useHistory, useLocation } from 'react-router-dom';

const Subscriptions = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const history = useHistory();
        
    const [productsData, setProductsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [msLink, setMsLink] = useState(null);

    const { error, subscriptionProducts, payUrl, manegeSubsriptionsLink } = useSelector(state => ({        
        error: state.Apikeys.error,
        subscriptionProducts: state.Apikeys.subscriptionProducts,
        payUrl: state.Apikeys.payUrl,
        manegeSubsriptionsLink: state.Apikeys.manegeSubsriptionsLink
    }));

    useEffect(() => {        
        if (error) {
            setLoading(false);
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(resetState());
        }
    }, [error])

    useEffect(() => {
        if (subscriptionProducts) {            
            setProductsData(subscriptionProducts);                    
            setLoadingProducts(false);
            setLoading(false);
        }
    }, [subscriptionProducts]);

    useEffect(() => {
        if (manegeSubsriptionsLink) {
            setMsLink(manegeSubsriptionsLink.url)
        }
    }, [manegeSubsriptionsLink]);    

    useEffect(() => {
        if (payUrl) {        
            window.location.replace(payUrl.url);            
        }
    }, [payUrl]);

    useEffect(() => {
        setLoading(true);
        setLoadingProducts(true);
        dispatch(resetApikeysDataState());
        dispatch(getSubscriptionManageLink());
        dispatch(getSubscriptionProducts());
    }, [dispatch]);

    const callPayUrl = (price_id) => {        
        dispatch(getPayUrl({"id_stripe_plan": price_id, "back_url": window.location.href}));
    };

    document.title = "Subscriptions | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Subscriptions" pageTitle="List subscriptions" />

                        {productsData.map((product, idx) => (                            
                            idx == 0
                            ? <ProductSection data={product} msLink={msLink} callPayUrl={callPayUrl} loading={loading} />
                            : <ProductSection data={product} callPayUrl={callPayUrl} loading={loading} />                                                     
                        ))}

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}

export default Subscriptions;

const ProductSection = ({ data, msLink, loading, callPayUrl }) => {

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                            <h4 className="card-title mb-0 flex-grow-1">{data.product?.name}</h4>
                            <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                <LoadingSpinner show={loading} />
                            </div>

                            {msLink !== undefined &&
                                <div className="flex-shrink-0">                                        
                                    <button type="button" className="btn btn-primary" onClick={() => window.location.replace(msLink)}>
                                        MANAGE SUBSCRIPTIONS
                                    </button>
                                </div>
                            }
                        </div>                                                     

                        <CardBody>
                            <div className="table-card">
                                <ProductsTable data={data.selections} toast={toast} getPayUrlFunction={callPayUrl} />
                            </div>
                        </CardBody>
                    </Card>                
                </Col>
            </Row>
        </React.Fragment>
    )
}

const ProductsTable = ({ data, toast, getPayUrlFunction }) => {

    const buyAction = (id) => {
        getPayUrlFunction(id);        
    }

    const columns = [
        {
            name: "Billing plan",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status != null ? <Badge color="success"> {row.status} </Badge> : "",
            sortable: true,
            center: true,
        },
        {
            name: "Monthly price",
            selector: row => row.monthlyPrice != null ? (row.monthlyPrice / 100 + ' USD / mo') : "",
            sortable: true,
        },
        {
            name: "Yearly price",
            selector: row => row.yearlyPrice != null ? (row.yearlyPrice / 100 + ' USD / mo') : "",
            sortable: true,
        },
        {
            name: "Actions",            
            selector: row => {                
                if (row.productBilling.filter(x => x.idStripeProductPlan !== undefined).length > 0) {
                    return (
                        <UncontrolledDropdown className="dropdown">
                            <DropdownToggle
                            role="button"
                            tag="button"
                            className="btn btn-soft-secondary btn-sm dropdown"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            >
                            <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                {row.productBilling
                                    .filter(x => x.idStripeProductPlan !== undefined)
                                    .map((x, idx) => (
                                    <li>
                                        <DropdownItem
                                        className="edit-item-btn"
                                        to="#api-key-modal"
                                        data-bs-toggle="modal"
                                        onClick={() => buyAction(x.idStripeProductPlan)}                    
                                        >
                                        <b>Buy</b> <small>[{x.flatFeeAmount / 100} / {x.flatFeeInterval}]</small>
                                        </DropdownItem>
                                    </li>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )
                }                
            },
            sortable: false,
            width: '120px',
            center: true,
            allowOverflow: true,
		    button: true,
        }
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} pagination={false} />
        </React.Fragment>
    )
}
