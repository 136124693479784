import SimpleTableViewComponent from "../Components/Common/SimpleTableViewComponent";
import { customerActiveApi } from "../helpers/backend_helper";
import { Badge } from "reactstrap";

const CustomerActive = () => {

    const title = "Customer active";
    const pageTitle = "Customer active";
    const apiCall = customerActiveApi;
    const columns = [
        {
            name: "Domains",
            selector: row => row.stats.domains?.map(d => d).join(', '),
            format: row => row.stats.domains?.map(d => d).join(', '),
            sortable: true,
            wrap: true,
        },
        {
            name: "Organizations",
            selector: row => row.organizations,
            format: row => row.organizations.map(o => o.name).join(', '),
            sortable: true,
            wrap: true,
        },
        {
            name: "MRR",            
            selector: row => row.stats.mrr,            
            sortable: true,
            // width: '100px',
        },
        {
            name: "Api Keys Count",
            selector: row => row.stats.apiKeysCount,            
            sortable: true,
            // width: '100px',
        },
        {
            name: "Category",
            selector: row => row.stats.category,
            format: row => <Badge color="info"> {row.stats.category} </Badge>,
            sortable: true,
            // width: '100px',
        },
    ];
    const filterColumns = [];


    return (
        <SimpleTableViewComponent 
            title={title}
            pageTitle={pageTitle}
            apiCall={apiCall}
            columns={columns}
            filterColumns={filterColumns}
        />
    )
}
export default CustomerActive;