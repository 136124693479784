import { ADD_NEW_CARD, API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, DELETE_CARD, GET_PAYMENTS_CARDS, SET_DEFAULT_CARD } from "./actionTypes"

const INIT_STATE = {
    cards: null,
    error: {},
    stripeSession: {},
    finishSetDefaultCard: 0,
    finishDeleteCard: false,
}

let increment = 0;

const paymentCards = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_PAYMENTS_CARDS:
                    return {
                        ...state, 
                        cards: action.payload.data
                    }
                case ADD_NEW_CARD:
                    return {
                        ...state,
                        stripeSession: action.payload.data
                    }
                case SET_DEFAULT_CARD:
                    return {
                        ...state,
                        finishSetDefaultCard: ++increment
                    }
                case DELETE_CARD:
                    return {
                        ...state,
                        finishDeleteCard: ++increment
                    }
                default:
                    return state;
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case SET_DEFAULT_CARD:
                case ADD_NEW_CARD:
                case DELETE_CARD:
                case GET_PAYMENTS_CARDS:
                    return {
                        ...state, 
                        error: action.payload.error
                    }
                default:
                    return state;
            }
        default:
            return state;
    }
}
export default paymentCards;