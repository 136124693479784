import { GET_APIKEYS, API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_SUBSCRIPTIONS, CREATE_APIKEY, GET_APIKEY_LIMITS, UPDATE_APIKEY_LIMITS, GET_APIKEY_IP, 
    RESET_STATE, RESET_LIMIT_DATA, RESET_APIKEYS_DATA, UPDATE_APIKEY_STATUS, RESET_APIKEY_UPDATE_INFO, DELETE_APIKEY, GET_SUBSCRIPTION_PRODUCTS, GET_PAY_URL,
    GET_SUBSCRIPTIONS_MANAGE_LINK } from "./actionTypes"

export const apikeysApiSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const apikeysApiError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getApikeys = () => ({
    type: GET_APIKEYS,    
});

export const getSubscriptions = () => ({
    type: GET_SUBSCRIPTIONS,
});

export const createApiKey = (product) => ({
    type: CREATE_APIKEY,
    payload: { product }
});

export const getApikeyLimits = (id) => ({
    type: GET_APIKEY_LIMITS,
    payload: { id }
});

export const updateApikeyLimits = (data) => ({
    type: UPDATE_APIKEY_LIMITS,
    payload: { data }
});

export const updateApikeyStatus = (data) => ({
    type: UPDATE_APIKEY_STATUS,
    payload: { data }
});

export const getApikeyIp = (id) => ({
    type: GET_APIKEY_IP,
    payload: { id }
});

export const resetState = () => ({
    type: RESET_STATE
});

export const resetLimitDataState = () => ({
    type: RESET_LIMIT_DATA
});

export const resetApikeysDataState = () => ({
    type: RESET_APIKEYS_DATA
});

export const resetApikeyUpdateInfo = () => ({
    type: RESET_APIKEY_UPDATE_INFO
});

export const deleteApikey = (id) => ({
    type: DELETE_APIKEY,
    payload: { id }
});

export const getSubscriptionProducts = () => ({
    type: GET_SUBSCRIPTION_PRODUCTS
});

export const getPayUrl = (data) => ({
    type: GET_PAY_URL,
    payload: { data }
});

export const getSubscriptionManageLink = () => ({
    type: GET_SUBSCRIPTIONS_MANAGE_LINK
})