import { useEffect, useState } from "react";
import { getTokenManager } from "../../helpers/token_manager";

const useProfile = () => {
    const [userProfile, setUserProfile] = useState({});
    const tokenManager = getTokenManager();

    useEffect(() => {
      setUserProfile(tokenManager.getUserDataFromToken());
    }, []);

    return { userProfile };
  };

export { useProfile };
