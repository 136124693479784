import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Button, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../Components/Common/BreadCrumb';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import classnames from "classnames";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import TableDatatable from '../Components/Common/TableDatatable';
import { dateInYyyyMmDdHhMmSs } from '../helpers/common';
import { getNotificationApi, setNotificationsValueApi } from '../helpers/backend_helper';

const Notifications = () => {

    const [activeTab, setActiveTab] = useState("1");
    const [history, setHistory] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [info, setInfo] = useState();

    const [groupedNotifications, setGroupedNotifications] = useState({});

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        getNotificationData();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
    }, [error])

    useEffect(() => {
        if (info) {
            toast.success(info, {
                position: toast.POSITION.TOP_RIGHT
            });
            setInfo(null);
        }
    }, [info])

    useEffect(() => {
        if (notifications) {
            const groupByProduct = (notifications) => {
                const grouped = {};
                notifications.forEach(notification => {
                    const productName = notification.notification.idProduct;
                    if (!grouped[productName]) {
                        grouped[productName] = [];
                    }
                    grouped[productName].push(notification);
                });
                return grouped;
            };

            const grouped = groupByProduct(notifications);
            setGroupedNotifications(grouped);
        }
    }, [notifications]);

    const getNotificationData = () => {
        setLoading(true);
        callToGetNotificationData().then(() => setLoading(false));
    }

    const callToGetNotificationData = async () => {
        try {
            const response = await getNotificationApi();

            if (response.status === 200) {
                setNotifications(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const setNotificationsValue = async (idNotification, email, value) => {
        try {
            const response = await setNotificationsValueApi({ idNotification, email, value });            
            if (response.status === 200) {
                setInfo("Successfully saved changes");
                const updatedNotifications = notifications.map(notification => {
                    if (notification.idNotification === idNotification) {
                        return { ...notification, value };
                    }
                    return notification;
                });
                setNotifications(updatedNotifications);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    document.title = "Notifications | CoinAPI.io Customer Portal";

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Notifications" pageTitle="Details" />

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                    role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "1" })}
                                            onClick={() => {
                                                tabChange("1");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            My Notifications
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "2" })}
                                            onClick={() => {
                                                tabChange("2");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            History
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                                <div className='d-flex gap-1' style={{ marginRight: '-10px', float: 'right', marginTop: '-30px' }}>
                                    <LoadingSpinner show={loading} />
                                </div>
                            </CardHeader>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">



                                    {Object.entries(groupedNotifications).map(([productName, notifications]) => (
                                        <>
                                            <CardHeader key={productName}><b>{productName}</b></CardHeader>
                                            <CardBody>
                                                <div className="row">

                                                    <ul className="list-unstyled mb-0">
                                                        {notifications.map(notification => (
                                                            <>

                                                                <li className="d-flex" key={notification.idNotification}>
                                                                    <div className="flex-grow-1">
                                                                        <label htmlFor="directMessage"
                                                                            className="form-check-label fs-14">{notification.notification.name}</label>
                                                                        <p className="text-muted">{notification.notification.description}</p>
                                                                    </div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="form-check form-switch">
                                                                            <Input 
                                                                                className="form-check-input" 
                                                                                type="checkbox"
                                                                                role="switch"                                                                             
                                                                                defaultChecked={notification.value === 1 ? true : false}                                                                                 
                                                                                onClick={() => setNotificationsValue(notification.idNotification, notification.email, notification.value === 1 ? 0 : 1)} />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                            // <li key={notification.idNotification}>
                                                            //     {notification.notification.name}
                                                            // </li>
                                                        ))}
                                                    </ul>

                                                </div>                                                

                                            </CardBody>

                                        </>

                                    ))}

                                </TabPane>
                                <TabPane tabId="2">
                                    <CardBody>
                                        <div className="table-card">
                                            <HistoryTable data={history} loading={loading} />
                                        </div>
                                    </CardBody>
                                </TabPane>
                                {/* <TabPane tabId="2">
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                        action="#">
                                        <CardBody>

                                            <div className="form__group">
                                                <label className="form__label">Billing email</label>
                                                <p className='text-muted'>Invoices and other billing notifications will be sent here</p>
                                                
                                                <Input type="text" className="form-control"
                                                    name="company"
                                                    value={validation.values.company || ''}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    readOnly={account.company_blocked}
                                                    invalid={
                                                        validation.touched.company && validation.errors.company ? true : false
                                                    }
                                                />
                                                <div className='invalid-tooltip'>{validation.errors.company}</div>
                                            </div>

                                            <div className="row row-margin-top">
                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Address</label>

                                                        <Input type="text" className="form-control"
                                                            name="address"
                                                            value={validation.values.address || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.address && validation.errors.address ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.address}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">City</label>

                                                        <Input type="text" className="form-control"
                                                            name="city"
                                                            value={validation.values.city || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.city && validation.errors.city ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.city}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row row-margin-top">

                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Region/Province</label>

                                                        <Input type="text" className="form-control"
                                                            name="region"
                                                            value={validation.values.region || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.region && validation.errors.region ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.region}</div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Postal code</label>

                                                        <Input type="text" className="form-control"
                                                            name="postalCode"
                                                            value={validation.values.postalCode || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.postalCode && validation.errors.postalCode ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.postalCode}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row row-margin-top">
                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Country</label>

                                                        <select className="form-select"
                                                            name="country"
                                                            value={validation.values.country || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            readOnly={account.company_blocked}
                                                        >
                                                            {countries &&
                                                                countries.map((country, index) =>
                                                                    <option key={index} value={country.alpha2}>{country.name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Website</label>

                                                        <Input type="text" className="form-control"
                                                            name="website"
                                                            value={validation.values.website || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.website && validation.errors.website ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.website}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row row-margin-top">
                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Coupon</label>

                                                        <Input type="text" className="form-control"
                                                            name="coupon"
                                                            value={validation.values.coupon || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.coupon && validation.errors.coupon ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.coupon}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">
                                                            VAT-EU <small>(If you are based in the EU and have the VAT-EU number)</small>
                                                        </label>

                                                        <Input type="text" className="form-control"
                                                            name="vat"
                                                            value={validation.values.vat || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.vat && validation.errors.vat ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.vat}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardFooter>
                                            <div className="row" style={{ float: "right", height: "50px" }}>
                                                <div className="col-md-8 form-group" >
                                                    <Button type="submit" className="btn btn-primary">
                                                        UPDATES
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                </TabPane> */}
                            </TabContent>

                        </Card>
                    </Col>

                </Row>

                <ToastContainer />

            </Container>
        </div>
    )
}
export default Notifications;

const HistoryTable = ({ data, loading }) => {

    const columns = [
        {
            name: "Timestamp (UTC)",
            selector: row => row.timestamp,
            format: row => dateInYyyyMmDdHhMmSs(new Date(row.timestamp)),
            sortable: true,
            // width: '260px',
        },
        {
            name: "Text",
            selector: row => row.spanName,
            sortable: true,
            // width: '260px',
        },
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} paginationPerPage="15" filterColumns={[]} />
        </React.Fragment>
    )
}