import React from 'react';
import { Button, Card, CardBody, Col, Label, Row, Input, Form } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import avatar8 from "../../assets/images/users/user-dummy-img.jpg";
import avatar10 from "../../assets/images/users/user-dummy-img.jpg";
import avatar6 from "../../assets/images/users/user-dummy-img.jpg";
import image4 from "../../assets/images/small/img-4.jpg";
import image5 from "../../assets/images/small/img-5.jpg";

import * as Yup from "yup";
import { useFormik } from "formik";

const TicketDescription = ({ticket, comments, addCommentFn}) => {

    const validation = useFormik({
        
        enableReinitialize: true,

        initialValues: {
            comment: ""
        },
        validationSchema: Yup.object({
            comment: Yup.string().required(),            
        }),
        onSubmit: (values, actions) => {            
            addCommentFn(ticket.id, values.comment);
            actions.resetForm();            
        }
    });

    return (
        <React.Fragment>
            <Col xxl={8}>
                <Card>
                    {/* <CardBody className="p-4">
                        <h6 className="fw-semibold text-uppercase mb-3">Ticket Description</h6>
                        <p className="text-muted display-linebreak">{ticket?.description}</p>                                                
                    </CardBody> */}
                    <CardBody className="p-4">
                        <h5 className="card-title mb-4">Comments</h5>

                        <SimpleBar className="px-3 mx-n3">
                            { comments.map((comment, index) => (                                                               
                                <div className="d-flex mb-4" key={index}>
                                    <div className="flex-shrink-0">
                                        <img src={avatar8} alt="" className="avatar-xs rounded-circle material-shadow" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="fs-13">{comment.author?.name} <small className="text-muted">{new Date(comment.created_at).toLocaleString()}</small></h5>
                                        <p className="text-muted display-linebreak">{comment.body}</p>
                                        {/* <Link to="#" className="badge text-muted bg-light"><i className="mdi mdi-reply"></i> Reply</Link> */}
                                        {/* <div className="d-flex mt-4">
                                            <div className="flex-shrink-0">
                                                <img src={avatar10} alt="" className="avatar-xs rounded-circle material-shadow" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="fs-13">Alexis Clarke <small className="text-muted">22 Dec 2021 - 02:32PM</small></h5>
                                                <p className="text-muted">Please be sure to check your Spam mailbox to see if your email filters have identified the email from Dell as spam.</p>
                                                <Link to="#" className="badge text-muted bg-light"><i className="mdi mdi-reply"></i> Reply</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            ))
                            }                                                      
                        </SimpleBar>
                        <Form 
                            action="#" 
                            className="mt-3"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <Row className="g-3">
                                <Col lg={12}>
                                    <Label className="form-label">Leave a Comments</Label>
                                    <Input 
                                        type="textarea" 
                                        className="multiline-placeholder form-control bg-light border-light" 
                                        name="comment" 
                                        value={validation.values.comment}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        rows="3" 
                                        placeholder="Enter comments"
                                        invalid={
                                            validation.touched.comment && validation.errors.comment
                                              ? true
                                              : false
                                          }
                                        />   
                                    <div className="invalid-tooltip">
                                        {validation.errors.comment}
                                    </div>                                                                         
                                </Col>
                                <Col lg={12} className="text-end">
                                    <Button type="submit" className="btn btn-success">Post Comments</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>

                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TicketDescription;