import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Button, Col, Form, Input, Row } from 'reactstrap';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';

import { getCountriesApi, getOrganizationDataApi, getTaxIdsApi, updateOrganizationDataApi } from '../../helpers/backend_helper';

const InvoiceAddress = () => {

    const [error, setError] = useState();
    const [updated, setUpdated] = useState();
    const [organization, setOrganization] = useState({});
    const [countries, setCountries] = useState([]);
    const [taxTypes, setTaxTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        callToDictionaryData();
        getOrganizationData();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
        if (updated) {
            toast.success(updated, {
                position: toast.POSITION.TOP_RIGHT
            });
            setUpdated(null);
        }
    }, [error, updated])

    const getOrganizationData = () => {
        setLoading(true);
        callToGetOrganizationData().then(() => {
            setLoading(false);            
        });
    }

    const callToGetOrganizationData = async () => {
        try {
            const response = await getOrganizationDataApi();

            if (response.status === 200) {
                setOrganization(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callToDictionaryData = async () => {
        getCountriesApi().then((response) => setCountries(response.data));
        getTaxIdsApi().then((response) => setTaxTypes(response.data));
    }    

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            company: organization.company || null,
            country: organization.country || null,
            address1: organization.address1 || null,
            address2: organization.address2 || null,
            city: organization.city || null,
            postalCode: organization.postalCode || null,
            region: organization.region || null,
            vatIdType: organization.vatIdType || null,
            vatIdValue: organization.vatIdValue || null,
            purchaseOrder: organization.purchaseOrder || null,
            coupon: organization.coupon || null,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            updateOrganizationDataApi(values).then((response) => {
                if (response.status === 200) {
                    setUpdated("Invoice addresses updated successfully");                    
                }
                else {
                    setError(response);
                }
            })
                .catch((e) => {
                    // console.log(e);
                    setError(e)
                });
        }
    });

    return (
        <Row>
            <Col lg={12}>
                <Card>                    
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        action="#">

                        <CardBody>
                            <div className="row row-margin-top">
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Company Name</label>

                                        <Input type="text" className="form-control"
                                            name="company"
                                            value={validation.values.company || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.company && validation.errors.company ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.company}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-margin-top">
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Address 1</label>

                                        <Input type="text" className="form-control"
                                            name="address1"
                                            value={validation.values.address1 || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.address1 && validation.errors.address1 ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.address1}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Address 2</label>

                                        <Input type="text" className="form-control"
                                            name="address2"
                                            value={validation.values.address2 || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.address2 && validation.errors.address2 ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.address2}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-margin-top">
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">City</label>

                                        <Input type="text" className="form-control"
                                            name="city"
                                            value={validation.values.city || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.city && validation.errors.city ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.city}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Postal code</label>

                                        <Input type="text" className="form-control"
                                            name="postalCode"
                                            value={validation.values.postalCode || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.postalCode && validation.errors.postalCode ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.postalCode}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-margin-top row-border-bottom">

                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Region/Province</label>

                                        <Input type="text" className="form-control"
                                            name="region"
                                            value={validation.values.region || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.region && validation.errors.region ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.region}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Country</label>

                                        {/* <select className="form-select"
                                                name="country"
                                                value={validation.values.country}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                            > */}
                                        <Input
                                            type="select"
                                            className="form-control"
                                            name="country"
                                            value={validation.values.country}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.country && validation.errors.country
                                                    ? true
                                                    : false
                                            }
                                            placeholder="Select Country"
                                        >
                                            {countries &&
                                                countries.map((country, index) =>
                                                    <option key={index} value={country.alpha2}>{country.name}</option>
                                                )
                                            }
                                        </Input>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-margin-top row-border-bottom">
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">VAT Type</label>

                                        <select className="form-select"
                                            name="vatIdType"
                                            value={validation.values.vatIdType || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        // readOnly={account.company_blocked}
                                        >
                                            {/* {taxTypes &&
                                                    taxTypes.map((obj, index) =>
                                                        <option key={index} value={obj}>{obj.country_label}</option>
                                                    )
                                                }
                                                 */}
                                            {Object.entries(taxTypes).map(([key, value]) => (
                                                <option key={key} value={key}>
                                                    {value.country_label} ({value.description})
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">
                                            VAT Number
                                        </label>

                                        <Input type="text" className="form-control"
                                            name="vatIdValue"
                                            value={validation.values.vatIdValue || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.vatIdValue && validation.errors.vatIdValue ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.vatIdValue}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-margin-top">
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">Coupon Code</label>

                                        <Input type="text" className="form-control"
                                            name="coupon"
                                            value={validation.values.coupon || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.coupon && validation.errors.coupon ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.coupon}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">
                                            Purchase order (PO) number
                                        </label>

                                        <Input type="text" className="form-control"
                                            name="purchaseOrder"
                                            value={validation.values.purchaseOrder || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.purchaseOrder && validation.errors.purchaseOrder ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.purchaseOrder}</div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>

                        <CardFooter>
                            <div className="row" style={{ float: "left", height: "50px" }}>
                                <div className="col-md-12 form-group" >
                                    <Button type="submit" className="btn btn-primary">
                                        SAVE CHANGES
                                    </Button>
                                </div>                                
                            </div>
                            <div className='spinner-tab'>
                                <LoadingSpinner show={loading} />
                            </div>  
                        </CardFooter>
                    </Form>
                </Card>
            </Col>
            
            <ToastContainer />

        </Row>
    );
}
export default InvoiceAddress;