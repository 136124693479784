import SimpleTableViewComponent from "../Components/Common/SimpleTableViewComponent";
import { getQuotasHorizontalApi } from "../helpers/backend_helper";
import { Progress } from "reactstrap";
import { formatValueWithUnit } from '../helpers/common';

const QuotasHorizontal = () => {
    const title = "Quotas";
    const pageTitle = "Quotas Horizontal";
    const apiCall = getQuotasHorizontalApi;
    const columns = [
        {
            name: "Organization ID",
            selector: row => row.organization.id,            
            sortable: true,
            wrap: true,
        },
        {
            name: "Organization Name",
            selector: row => row.organization.name,            
            sortable: true,
            wrap: true,
        },
        {
            name: "Product",
            selector: row => row.quota.idProduct,            
            sortable: true,
            wrap: true,
        },
        {
            name: "Name",
            selector: row => row.quota.name,            
            sortable: true,
            wrap: true,
        },
        {
            name: "Type",
            selector: row => row.quota.type,            
            sortable: true,        
            wrap: true,
        },
        {
            name: "Usage percentage",            
            selector: row => row.quota.valueQuota === 0 ? (row.valueQuota > 0 ? 100 : 0) : (row.quota.valueCurrentUsage / row.quota.valueQuota) * 100,
            format: row => <>
            <div style={{width: '168px'}}>
                <Progress value={row.quota.valueQuota === 0 ? (row.valueQuota > 0 ? 100 : 0) : (row.quota.valueCurrentUsage / row.quota.valueQuota) * 100} color="success" className="animated-progess custom-progress"></Progress>
            </div>
            </>,
            sortable: true,
            width: '200px',            
        },
        {
            name: "Current Usage",            
            selector: row => formatValueWithUnit(row.quota.valueCurrentUsage, row.quota.valueUnit),            
            sortable: true,
            wrap: true,
        },
        {
            name: "Your Quota",
            selector: row => row.quota.valueQuota,  
            format: row => formatValueWithUnit(row.quota.valueQuota, row.quota.valueUnit),          
            sortable: true,
            wrap: true,
        },
        {
            name: "Default Quota",
            selector: row => row.quota.valueQuota,    
            format: row => formatValueWithUnit(row.quota.valueQuota, row.quota.valueUnit),                  
            sortable: true,
            wrap: true,
        },       
        {
            name: "Adjustable",
            selector: row => row.quota.adjustable,
            sortable: true,
            width: '150px',
        },
    ];


    return (
        <SimpleTableViewComponent 
            title={title}
            pageTitle={pageTitle}
            apiCall={apiCall}
            columns={columns}            
        />
    )
}
export default QuotasHorizontal;