import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Grid, _ } from 'gridjs-react';
import BreadCrumb from '../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { getInvoicesPaymentsDue, getInvoicesPaymentsOrder } from '../store/actions';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import ScaleLoader from "react-spinners/ScaleLoader";
import TableDatatable from '../Components/Common/TableDatatable';
// import { useProfile } from "../Components/Hooks/UserHooks";


import visa from "../assets/images/cards/visa.png";
import mastercard from "../assets/images/cards/mastercard.png";
import americanexpress from "../assets/images/cards/americanexpress.png";

import { getInvoicePdfApi } from '../helpers/backend_helper';
import { api } from '../config';
import { INVOICE_HOSTED, INVOICE_PDF } from '../helpers/url_helper';
import { joinHostWithPath } from '../helpers/common';


const Invoices = ({ setError, setUpdate }) => {

    const dispatch = useDispatch();
    // const { userProfile } = useProfile();

    const [paymentsDueData, setPaymentsDueData] = useState([]);
    const [paymentsOrderData, setPaymentsOrderData] = useState([]);
    const [loadOrderSpinner, setLoadOrderSpinner] = useState(false);
    const [loadDueSpinner, setLoadDueSpinner] = useState(false);

    const { invoicesDue, invoicesOrder, error } = useSelector(state => ({
        invoicesDue: state.Invoices.invoicesDue,
        invoicesOrder: state.Invoices.invoicesOrder,
        error: state.Invoices.error
    }));

    useEffect(() => {
        if (invoicesDue) {
            setPaymentsDueData(invoicesDue);
            setLoadDueSpinner(false);
        }
    }, [invoicesDue]);

    useEffect(() => {
        if (invoicesOrder) {
            setPaymentsOrderData(invoicesOrder);
            setLoadOrderSpinner(false);
        }
    }, [invoicesOrder]);

    useEffect(() => {
        setLoadDueSpinner(true);
        setLoadOrderSpinner(true);
        dispatch(getInvoicesPaymentsDue());
        dispatch(getInvoicesPaymentsOrder());
    }, [dispatch]);

    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className='align-items-center d-flex' style={{ height: '70.5px' }}>
                            <h4 className="card-title mb-0 flex-grow-1">Payments Due</h4>
                            <div className='d-flex gap-1'>
                                <LoadingSpinner show={loadDueSpinner} />
                            </div>
                        </CardHeader>

                        <CardBody>
                            <div id="table-payments-due" className="table-card">
                                {/* {paymentsDueData != null
                                        ?   <PaymentsDueTable data={paymentsDueData} loadOrderSpinner={loadOrderSpinner} />
                                        :   <div style={{margin: "10px"}}>
                                                <ScaleLoader color="#36d7b7" />
                                            </div>
                                    } */}
                                <PaymentsDueTable data={paymentsDueData} loading={false} />
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className='align-items-center d-flex' style={{ height: '70.5px' }}>
                            <h4 className="card-title mb-0 flex-grow-1">Order and invoice history</h4>
                            <div className='d-flex gap-1'>
                                <LoadingSpinner show={loadOrderSpinner} />
                            </div>
                        </CardHeader>

                        <CardBody>
                            <div id="table-orders" className="table-card">
                                {/* {paymentsOrderData != null
                                        ?   <OrdersTable data={paymentsOrderData} />
                                        :   <div style={{margin: "10px"}}>
                                                <ScaleLoader color="#36d7b7" />
                                            </div>
                                    }                                     */}
                                <OrdersTable data={paymentsOrderData} loading={false} />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

const PaymentsDueTable = ({ data, loading }) => {

    const columns = [
        {
            name: "Invoice Date",
            selector: row => new Date(row.created).toISOString().split('T')[0],
            sortable: true,
        },
        {
            name: "Invoice ID",
            selector: row => <StripeDownload order={row} />,
            sortable: false,
        },
        {
            name: "Due Date",
            selector: row => new Date(row.dueDate ? row.dueDate : row.created).toISOString().split('T')[0],
            sortable: true,
        },
        {
            name: "Amount",
            selector: row => (row.amountUsdCents / 100).toFixed(2) + " " + row.currency.toUpperCase(),
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => <StripePay order={row} />,
            sortable: false,
            // style: {width: "50px"}
            center: true
        }
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} loading={loading} />
        </React.Fragment>
    )
}

const OrdersTable = ({ data, loading }) => {

    const columns = [
        {
            name: "Invoice Date",
            selector: row => new Date(row.created).toISOString().split('T')[0],
            sortable: true,
        },        
        {
            name: "Payment date",
            selector: row => new Date(row.paidDate).toISOString().split('T')[0],
            sortable: true,
        },
        {
            name: "Invoice ID",
            selector: row => <StripeDownload order={row} />,
            sortable: false,
        },
        {
            name: "Amount",
            selector: row => (row.amountUsdCents / 100).toFixed(2) + " " + row.currency.toUpperCase(),
            sortable: true
        }
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} loading={loading} />
        </React.Fragment>
    )
}

const StripeDownload = ({ order }) => {
    const url = joinHostWithPath(api.API_URL, INVOICE_PDF + "?id=" + order.id);
    return (
        <>
            <a type="button" href={url} className="btn btn-outline-primary btn-sm waves-effect waves-light">{order.invoiceNumber}</a>
        </>

    )
}

const StripePay = ({ order }) => {
    const url = joinHostWithPath(api.API_URL, INVOICE_HOSTED + "?id=" + order.id);
    return (
        <>
            <a type="button" href={url} target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-sm waves-effect waves-light">PAY</a>
        </>

    )
}

const CardIcon = ({ type }) => {
    return (
        <>
            {type === "visa" &&
                <><img className="credit-card__img" src={visa} alt={type} /></>
            }
            {type === "mastercard" &&
                <><img className="credit-card__img" src={mastercard} alt={type} /></>
            }
            {type === "americanexpress" &&
                <><img className="credit-card__img" src={americanexpress} alt={type} /></>
            }
        </>
    )
}

export default Invoices;