import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from '../Components/Common/LoadingSpinner';

import * as Yup from "yup";
import { useFormik } from "formik";

import "../App.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TableDatatable from "../Components/Common/TableDatatable";
import { addArtifact, deleteArtifact, disableArtifact, enableArtifact, getArtifacts, resetStateArtifact } from "../store/actions";
import { useSearchParams, useNavigate } from "react-router-dom";

const ApiKeyAuthConfiguration = () => {

  const dispatch = useDispatch();
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);

  const { artifacts, error, successAddArtifact, success } = useSelector((state) => ({
    artifacts: state.Jwt.artifacts,
    error: state.Jwt.error,
    successAddArtifact: state.Jwt.successAddArtifact,
    success: state.Jwt.success
  }));

  useEffect(() => {
    setLoading(true);
    dispatch(getArtifacts(urlParams.get('id')));
  }, [dispatch]);

  useEffect(() => {
    if (error) {    
      setLoading(false)
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  useEffect(() => {
    if (artifacts) {
        setLoading(false)
    }
  }, [artifacts]);

  useEffect(() => {
    if (successAddArtifact) {
        setLoading(true)
        dispatch(getArtifacts(urlParams.get('id')));
        dispatch(resetStateArtifact());
    }
  }, [successAddArtifact]);

  useEffect(() => {
    if (success) {
        dispatch(getArtifacts(urlParams.get('id')));
        dispatch(resetStateArtifact());
    }
  }, [success]);  

  document.title = "Auth Configuration | CoinAPI.io Customer Portal";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      value: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      value: Yup.string().required(),
    }),
    onSubmit: (values, actions) => {
      setLoading(true);
      values.type = "Jwt";
      dispatch(addArtifact(urlParams.get('id'), values))
      actions.resetForm();
    },
  });

  const onClickDeleteArtifact = (data) => {
    setLoading(true);    
    dispatch(deleteArtifact(data));
  }

  const onClickChangeStatusArtifact = (data) => {
    setLoading(true);   
    if (data.isEnabled === true) {
        dispatch(disableArtifact(data));
    }
    else {
        dispatch(enableArtifact(data));
    }    
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Api Keys" pageTitle="Auth Configuration" />

          <Row>
            <Col lg={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >                              

                <Card>
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                        Import JWT Key
                    </h4>
                    <div className='d-flex gap-1'>
                        <Button type="button" className="btn btn-info" onClick={() => {
                                dispatch(resetStateArtifact());
                                navigate(-1);
                            }}>
                            BACK TO PREVIOUS PAGE
                        </Button>   
                    </div>                           
                  </div>

                  <CardBody>
                    <div className="row">
                      <div className="form-group">
                        <FormGroup>
                          <Label>JWT Key Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="name"
                            value={validation.values.name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.name &&
                              validation.errors.name
                                ? true
                                : false
                            }
                            placeholder="Enter Name"
                          />
                          <div className="invalid-tooltip">
                            {validation.errors.name}
                          </div>
                        </FormGroup>
                      </div>
                      <div className="form-group">
                        <FormGroup>
                          <Label>JWT Key</Label>
                          <Input
                            type="textarea"
                            className="multiline-placeholder form-control"
                            name="value"
                            rows="6"
                            value={validation.values.value}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.value && validation.errors.value
                                ? true
                                : false
                            }
                            placeholder="-----BEGIN PUBLIC KEY-----&#13;&#13;&#13;&#13;&#13;-----END PUBLIC KEY-----"
                          />                            
                          <div className="invalid-tooltip">
                            {validation.errors.value}
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </CardBody>

                  <CardFooter>                    
                    <div className="align-items-center d-flex">
                    <div className="mb-0 flex-grow-1"></div>
                    <div className="flex-shrink-0">  
                        <button type="submit" className="btn btn-primary">
                            IMPORT JWT KEY
                        </button>
                    </div>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    JWT Artifact
                  </h4>
                  <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                    <LoadingSpinner show={loading} />
                  </div>
                </div>

                <CardBody>
                  <div id="table-users" className="table-card">
                    <JwtTable data={artifacts} deleteFunction={onClickDeleteArtifact} enableDisableFunction={onClickChangeStatusArtifact} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const JwtTable = ({ data, deleteFunction, enableDisableFunction }) => {
  const columns = [
    {
      name: "JWT Key Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "JWT Fingerprint",
      selector: (row) => row.jwtFingerprint,
      sortable: true,
    },
    {
        name: "JWT Algorithm",
        selector: (row) => row.jwtAlgorithm,
        sortable: true,
    },
    {
        name: "Status",
        selector: row => <DisableEnableComponent onClickFn={enableDisableFunction} row={row} /> ,
        sortable: false,
        width: '120px',
        center: true
    },    
    {
      name: "",
      selector: row => <Action data={row} deleteFunction={deleteFunction} />,
      sortable: false,
      width: '120px',
      center: true,
      
    },
  ];

  return (
    <React.Fragment>
      <TableDatatable data={data} columns={columns} />
    </React.Fragment>
  );
};

const Action = ({ data, deleteFunction }) => {
  return (
      <div className="hstack gap-2">
          <button className="btn btn-sm btn-soft-danger" onClick={() => deleteFunction(data)}>
              <i className="ri-delete-bin-5-fill align-bottom" />
          </button>
      </div>
  )
}

const DisableEnableComponent = ({ row, onClickFn }) => {
    return (
        <button type="button" onClick={() => onClickFn(row)} className={`btn btn-outline-${ row.isEnabled === true ? 'success' : 'danger' } btn-sm waves-effect waves-light`} >{ row.isEnabled === true ? 'ENABLED' : 'DISABLED' }</button>
    );
}

export default ApiKeyAuthConfiguration;
