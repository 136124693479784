import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, inviteUser, deleteInviteUser, resetError, getOrganization, updateOrganization } from "../store/actions";

import LoadingSpinner from '../Components/Common/LoadingSpinner';

import * as Yup from "yup";
import { useFormik } from "formik";

import "../App.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TableDatatable from "../Components/Common/TableDatatable";

const Users = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [organizationData, setOrganization] = useState({});

  const { users, error, organization } = useSelector((state) => ({
    users: state.usersReducer.users,
    error: state.usersReducer.error,
    organization: state.usersReducer.organization,
    // finishInviteUserAction: state.usersReducer.finishInviteUserAction,
  }));

  useEffect(() => {
    setLoading(true);
    dispatch(getOrganization());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setLoading(false)
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetError(null));
    }
  }, [error]);

  useEffect(() => {
    if (organization) {
      setOrganization(organization)
    }
  }, [organization]);

  useEffect(() => {
    if (users) {
      setLoading(false)
    }
  }, [users]);

  document.title = "Users | CoinAPI.io Customer Portal";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      role: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required(),
      role: Yup.string().required(),
    }),
    onSubmit: (values, actions) => {
      setLoading(true);
      dispatch(inviteUser(values));
      actions.resetForm();
    },
  });

  const validationOrg = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: organizationData.name || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),      
    }),
    onSubmit: (values, actions) => {
      // setLoading(true);      
      dispatch(updateOrganization(values));
      actions.resetForm();
    },
  });

  const onClickDeleteUser = (user) => {
    setLoading(true);
    dispatch(deleteInviteUser(user));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Users" pageTitle="Users" />

          <Row>
            <Col lg={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validationOrg.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Organization
                    </h4>
                  </div>

                  <CardBody>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <FormGroup>
                          <Label>Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="name"
                            value={validationOrg.values.name}
                            onChange={validationOrg.handleChange}
                            onBlur={validationOrg.handleBlur}
                            invalid={
                              validationOrg.touched.name &&
                              validationOrg.errors.name
                                ? true
                                : false
                            }
                            placeholder="Enter Name"
                          />
                          <div className="invalid-tooltip">
                            {validationOrg.errors.name}
                          </div>
                        </FormGroup>
                      </div>                      
                    </div>
                  </CardBody>

                  <CardFooter>
                    {/* <div className="row">? */}
                    <div className="align-items-center d-flex">
                      <div className="mb-0 flex-grow-1"></div>
                      <div className="flex-shrink-0">
                        <button type="submit" className="btn btn-primary">
                          UPDATE ORGANIZATION
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-10 form-group"></div>
                      <div className="col-md-2 form-group">

                      </div> */}
                    {/* </div> */}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Invite Team Members
                    </h4>
                  </div>

                  <CardBody>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            value={validation.values.email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                            placeholder="Enter Email"
                          />
                          <div className="invalid-tooltip">
                            {validation.errors.email}
                          </div>
                        </FormGroup>
                      </div>
                      <div className="col-md-6 form-group">
                        <FormGroup>
                          <Label>Role</Label>
                          <Input
                            type="select"
                            className="form-control"
                            name="role"
                            value={validation.values.role}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.role && validation.errors.role
                                ? true
                                : false
                            }
                            placeholder="Select Role"
                          >
                            <option >
                              Select role
                            </option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                          </Input>
                          <div className="invalid-tooltip">
                            {validation.errors.role}
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </CardBody>

                  <CardFooter>
                    {/* <div className="row">? */}
                    <div className="align-items-center d-flex">
                      <div className="mb-0 flex-grow-1"></div>
                      <div className="flex-shrink-0">
                        <button type="submit" className="btn btn-primary">
                          INVITE USER
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-md-10 form-group"></div>
                      <div className="col-md-2 form-group">

                      </div> */}
                    {/* </div> */}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    User Management
                  </h4>
                  <div className='d-flex gap-1' style={{ marginRight: '5px' }}>
                    <LoadingSpinner show={loading} />
                  </div>
                </div>

                <CardBody>
                  <div id="table-users" className="table-card">
                    <UsersTable data={users} deleteFunction={onClickDeleteUser} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const UsersTable = ({ data, deleteFunction }) => {
  const columns = [
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.contactType,
      sortable: true,
    },
    {
      name: "Actions",
      selector: row => <Action user={row} deleteFunction={deleteFunction} />,
      sortable: false,
      width: '120px',
      center: true,

    },
  ];

  return (
    <React.Fragment>
      <TableDatatable data={data} columns={columns} />
    </React.Fragment>
  );
};

const Action = ({ user, deleteFunction }) => {
  return (
    <div className="hstack gap-2">
      <button className="btn btn-sm btn-soft-danger" onClick={() => deleteFunction(user)}>
        <i className="ri-delete-bin-5-fill align-bottom" />
      </button>
    </div>
  )
}

export default Users;
