import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_METRICS, GET_METRICS_GROUP_DATA } from "./actionTypes";

import { getMetricsApi, getMetricsGroupApi } from "../../helpers/backend_helper";
import { metricsApiError, metricsApiSuccess } from "./actions";

function* getMetrics() {    
    try {         
        const response = yield call(getMetricsApi);
        if (response.status === 200) {        
            yield put(metricsApiSuccess(GET_METRICS, response.data));
        }
        else {            
            yield put(metricsApiError(GET_METRICS, response));
        }
    }
    catch (error) {        
        yield put(metricsApiError(GET_METRICS, error));
    }
}

function* getMetricsGroup({ payload: { filters } }) {
    try {         
        const response = yield call(getMetricsGroupApi, filters);
        if (response.status === 200) {
            yield put(metricsApiSuccess(GET_METRICS_GROUP_DATA, response.data));
        }
        else {            
            yield put(metricsApiError(GET_METRICS_GROUP_DATA, response));
        }
    }
    catch (error) {
        yield put(metricsApiError(GET_METRICS_GROUP_DATA, error));
    }
}

function* watchGetMetrics() {
    yield takeEvery(GET_METRICS, getMetrics);
}

function* watchGetMetricsGroup() {
    yield takeEvery(GET_METRICS_GROUP_DATA, getMetricsGroup);
}

function* MetricsSaga() {
    yield all(
        [
            fork(watchGetMetrics),
            fork(watchGetMetricsGroup)
        ]
    )
}
export default MetricsSaga;