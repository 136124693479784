import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    CardFooter,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import LoadingSpinner from '../../Components/Common/LoadingSpinner';
import Select from 'react-select';

import * as Yup from "yup";
import { useFormik } from "formik";

import { useSearchParams, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addUserTicketApi } from "../../helpers/backend_helper";

import { FileUpload } from 'primereact/fileupload';

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "../../App.css";
import { api } from "../../config";
import { joinHostWithPath } from "../../helpers/common";

const AddNewTicket = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [filesUpload, setFilesUpload] = useState([]);

    const fileUploadRef = useRef(null)    

    const priorityOptions = [
        { value: "low", label: "General guidance" },
        { value: "normal", label: "System impaired" },
        { value: "high", label: "Production system impaired" },
        { value: "urgent", label: "Production system down" }
    ];

    useEffect(() => {
        if (error) {
            setLoading(false)
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [error]);

    const addTicket = (data) => {
        setLoading(true);
        callToAddTicket(data).then(() => {
            setLoading(false);
            navigate(-1);
        });
    }

    const callToAddTicket = async (data) => {
        try {                   
            const response = await addUserTicketApi(data);

            if (response.status === 200) {
                toast.success("Ticket added successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const uploadFileFinish = (e) => {        
        if (e.xhr.status === 200) {            
            var response = e.xhr.response;
            var tokensJson = JSON.parse(response);            
            validation.setFieldValue("tokens", tokensJson.tokens);
            var filesUpload = [];
            e.files.map((file, idx) => {
                filesUpload.push({ name: file.objectURL, token: tokensJson.tokens[idx] });
            });
            setFilesUpload(filesUpload);
        }
    }

    const getTokens = () => {

        var uploadedFiles = fileUploadRef.current.getUploadedFiles();
        var currentAttachment = [];

        uploadedFiles.map((uFile) => {
            var attachment = filesUpload.filter((file) => file.name == uFile.objectURL);
            if (attachment.length > 0) {
                currentAttachment.push(attachment[0]);
            }
        });
        
        validation.setFieldValue("tokens", currentAttachment.map((file) => file.token));
        setFilesUpload(currentAttachment);
    };    
    

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            priority: "low",
            subject: "",
            description: "",
            tokens: []
        },
        validationSchema: Yup.object({
            priority: Yup.string().required(),
            subject: Yup.string().required(),
            description: Yup.string().required(),
        }),
        onSubmit: (values, actions) => {
            // setLoading(true);            
            addTicket(values);
        },

    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Add new ticket" pageTitle="Customer tickets" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        Add new ticket
                                    </h4>
                                    <div className='d-flex gap-1'>
                                        <Button type="button" className="btn btn-info" onClick={() => {
                                            navigate(-1);
                                        }}>
                                            BACK TO PREVIOUS PAGE
                                        </Button>
                                    </div>
                                </div>

                                <CardBody>
                                    <div className="row">
                                        <Form
                                            // onSubmit={(e) => {
                                            //     e.preventDefault();
                                            //     console.log(validation);
                                            //     validation.handleSubmit();                                                
                                            //     return false;
                                            // }}
                                            action="#"
                                        >
                                            <div className="form-group">
                                                <FormGroup>
                                                    <Label>Priority</Label>
                                                    <Select name="priority"
                                                        defaultValue={priorityOptions.find(option => option.value === 'low')}
                                                        options={priorityOptions}
                                                        onChange={(option) => validation.setFieldValue("priority", option.value)}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.priority &&
                                                            validation.errors.priority
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <div className="invalid-tooltip">
                                                        {validation.errors.priority}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="form-group">
                                                <FormGroup>
                                                    <Label>Subject</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="subject"
                                                        value={validation.values.subject}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.subject &&
                                                                validation.errors.subject
                                                                ? true
                                                                : false
                                                        }
                                                        placeholder="Enter subject"
                                                    />
                                                    <div className="invalid-tooltip">
                                                        {validation.errors.subject}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="form-group">
                                                <FormGroup>
                                                    <Label>Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        className="multiline-placeholder form-control"
                                                        name="description"
                                                        rows="6"
                                                        value={validation.values.description}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.description && validation.errors.description
                                                                ? true
                                                                : false
                                                        }
                                                        placeholder="Enter a detailed description"
                                                    />
                                                    <div className="invalid-tooltip">
                                                        {validation.errors.description}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </Form>
                                        <div className="form-group">
                                            <FormGroup>
                                                <Label>Attachment</Label>
                                                <FileUpload ref={fileUploadRef} name="files[]" url={joinHostWithPath(api.API_URL, '/api/ticket/attachment')} 
                                                    multiple={true} accept="image/*" 
                                                    maxFileSize={10000000}
                                                    emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} 
                                                    onUpload={uploadFileFinish}
                                                    auto={true}
                                                    
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardFooter>
                                    <div className="align-items-center d-flex">
                                        <div className="mb-0 flex-grow-1"></div>
                                        <div className="flex-shrink-0">
                                            <button type="submit" className="btn btn-primary" 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    getTokens();
                                                    validation.handleSubmit();                                                
                                                    return false;
                                            }}>
                                                OPEN CASE
                                            </button>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>

                        </Col>
                    </Row>

                    <ToastContainer />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AddNewTicket;