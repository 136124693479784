import { GET_APIKEYS, API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_SUBSCRIPTIONS, CREATE_APIKEY, GET_APIKEY_LIMITS, UPDATE_APIKEY_LIMITS, RESET_STATE, GET_APIKEY_IP, RESET_LIMIT_DATA, RESET_APIKEYS_DATA, UPDATE_APIKEY_STATUS, RESET_APIKEY_UPDATE_INFO, DELETE_APIKEY, GET_SUBSCRIPTION_PRODUCTS, GET_PAY_URL, GET_SUBSCRIPTIONS_MANAGE_LINK } from "./actionTypes"

const INIT_STATE = {
    subscriptions: null,
    apikeys: null,
    apikey: null,
    apikeylimits: null,
    apikeyip: [],
    error: null, 
    finishAddApikey: false,
    finishUpdateApikey: false,
    finishUpdateApiKeyLimit: false,
    subscriptionProducts: null,
    payUrl: null,
    manegeSubsriptionsLink: null
}

const apikeys = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_SUBSCRIPTIONS:
                    return {
                        ...state, 
                        subscriptions: action.payload.data
                    }
                case GET_SUBSCRIPTIONS_MANAGE_LINK:
                    return {
                        ...state, 
                        manegeSubsriptionsLink: action.payload.data
                    }                    
                case GET_APIKEYS:
                    return {
                        ...state, 
                        apikeys: action.payload.data
                    }
                case CREATE_APIKEY:
                    return {
                        ...state, 
                        apikey: action.payload.data
                    }
                case GET_APIKEY_LIMITS:
                    return {
                        ...state,
                        apikeylimits: action.payload.data
                    }
                case UPDATE_APIKEY_LIMITS:
                    return {
                        ...state,
                        finishUpdateApiKeyLimit: action.payload.data
                    }
                case UPDATE_APIKEY_STATUS:
                case DELETE_APIKEY:
                    return {
                        ...state,
                        finishUpdateApikey: action.payload.data
                    }
                case GET_APIKEY_IP:
                    return { 
                        ...state,
                        apikeyip: action.payload.data
                    }
                case GET_SUBSCRIPTION_PRODUCTS:
                    return {
                        ...state,
                        subscriptionProducts: action.payload.data
                    }
                case GET_PAY_URL:
                    return {
                        ...state,
                        payUrl: action.payload.data
                    }
                default:
                    return state;
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_SUBSCRIPTIONS:
                case GET_APIKEYS:
                case CREATE_APIKEY:
                case GET_APIKEY_LIMITS:
                case UPDATE_APIKEY_LIMITS:
                case GET_APIKEY_IP:
                case UPDATE_APIKEY_STATUS:
                case DELETE_APIKEY:
                case GET_SUBSCRIPTION_PRODUCTS:
                case GET_PAY_URL:
                case GET_SUBSCRIPTIONS_MANAGE_LINK:
                    return {
                        ...state, 
                        error: action.payload.error
                    }
                default:
                    return state;
            }
        case RESET_STATE:            
            state.error = INIT_STATE.error;
            state.finishAddApikey = INIT_STATE.finishAddApikey;            
            state.finishUpdateApiKeyLimit = INIT_STATE.finishUpdateApiKeyLimit;
            return state;
        case RESET_LIMIT_DATA:
            state.apikeylimits = INIT_STATE.apikeylimits;
            state.apikeyip = INIT_STATE.apikeyip;
            return state;
        case RESET_APIKEYS_DATA:
            state.apikeys = INIT_STATE.apikeys
            return state;
        case RESET_APIKEY_UPDATE_INFO:
            state.finishUpdateApikey = INIT_STATE.finishUpdateApikey
            return state;
        default:
            return state;
    }
}
export default apikeys;