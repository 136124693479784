import React, { useEffect, useState } from 'react';

import { CardBody, CardFooter, Button, Form, Input, Row } from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from "formik";
import Widgets from '../../Components/Common/Widgets';
import { getUsageDetailsApi, updateUsageDetailsApi } from '../../helpers/backend_helper';


const SpendManagement = () => {
    
    const [enableOverage, setEnableOverage] = useState(false);
    const [usageDetails, setUsageDetails] = useState();
    const [error, setError] = useState();
    const [updated, setUpdated] = useState();

    useEffect(() => {        
        getUsageDetails();
    }, [])

    useEffect(() => {
        if (usageDetails) {
            if (usageDetails.isSpendMgmtEnabled) {
                setEnableOverage(true);
            }
            setUsageDetails(usageDetails);
            formik.setValues(usageDetails)
        }
    }, [usageDetails]);

    const formik = useFormik({
        initialValues: {
            isSpendMgmtEnabled: usageDetails?.isSpendMgmtEnabled,
            dailySpendHardLimit: usageDetails?.dailySpendHardLimit,
            dailySpendNotifyThreshold: usageDetails?.dailySpendNotifyThreshold,
        },
        onSubmit: (values) => {
            const updatedValues = {
                ...values,
                dailySpendHardLimit: values.isSpendMgmtEnabled ? (values.dailySpendHardLimit !== '' ? values.dailySpendHardLimit : null) : null,
                dailySpendNotifyThreshold: values.isSpendMgmtEnabled ? (values.dailySpendNotifyThreshold !== '' ? values.dailySpendNotifyThreshold : null) : null,
            };
            callUpdateUsageDetails(updatedValues).then(() => {
                setUsageDetails(updatedValues);
            });
        }
    });

    const getUsageDetails = () => {
        callGetUsageDetails();
    }

    const callGetUsageDetails = async () => {
        try {
            const response = await getUsageDetailsApi();

            if (response.status === 200) {
                setUsageDetails(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callUpdateUsageDetails = async (data) => {
        try {
            const response = await updateUsageDetailsApi(data);

            if (response.status === 200) {
                setUpdated("Changes have been saved");
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
        if (updated) {
            toast.success(updated, {
                position: toast.POSITION.TOP_RIGHT
            });
            setUpdated(null);
        }
    }, [error, updated])

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}
            action="#">
            <CardBody>
                {/*<div className="row row-border-bottom">
                    <div className="col-md-12">
                        Set a spend amount towards metered resources. Configure notifications, set a webhook, or a budget limit.
                    </div>
                </div>*/}
                <div className="row row-border-bottom" style={{
                    marginTop: '10px'
                }}>
                    <div className="col-md-1 form-group" style={{
                        minWidth: '60px',
                        maxWidth: '60px'
                    }}>
                        <div className="form-check form-switch form-switch-lg" dir="ltr">
                            <Input type="checkbox" className="form-check-input" role="switch"
                                id="isSpendMgmtEnabled"
                                value={formik.values.isSpendMgmtEnabled || false}
                                checked={formik.values.isSpendMgmtEnabled || false}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setEnableOverage(e.target.checked);
                                }}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="col-md-10 form-group">
                        <h4>Spend Management</h4>
                        Set a usage credits spend amount. Configure notifications, set a webhook, or a budget limit.
                    </div>
                    
                </div>

                {enableOverage &&
                    <div className="row" style={{
                        padding: '20px',
                        backgroundColor: '#f8f9fa',
                    }}>
                        <Row>
                            <Widgets widgets={[
                                {
                                    id: 1,
                                    label: "Credits Consumed Today",
                                    // badge: "ri-arrow-up-circle-line text-success",
                                    icon: "ri-exchange-dollar-line",
                                    counter: usageDetails?.creditUsageToday,
                                    decimals: 2,
                                    suffix: "",
                                    prefix: "$"
                                },
                                {
                                    id: 2,
                                    label: "Credits Consumed Yesterday",
                                    // badge: "ri-arrow-up-circle-line text-success",
                                    icon: "ri-exchange-dollar-line",
                                    counter: usageDetails?.creditUsageYesterday,
                                    decimals: 2,
                                    suffix: "",
                                    prefix: "$"
                                }/*,
                                {
                                    id: 3,
                                    label: "3 Month Average Spend",
                                    // badge: "ri-arrow-down-circle-line text-danger",
                                    icon: "ri-pulse-line",
                                    counter: usageDetails?.statsUsage?.average3MonthUsage,
                                    decimals: 2,
                                    suffix: "$",
                                    prefix: ""
                                },*/
                            ]} />
                        </Row>

                        <Row style={{ marginBottom: '10px' }}>
                            <div className="col-md-6 form-group">
                                <label>Set daily credit usage budget</label>
                                <div className='text-muted'>If your organization exceeds this budget in a given day (UTC), we will reject any new connections or API calls until end of this day.</div>

                                <div className="input-group mt-2" style={{ width: '120px' }}>
                                    <span className="input-group-text">$</span>
                                    <Input
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        placeholder="not set"
                                        className="form-control"
                                        aria-label="Amount (to the nearest cent)"
                                        id="dailySpendHardLimit"
                                        value={formik.values.dailySpendHardLimit ?? ''}
                                        onChange={(e) => {
                                            const value = e.target.value === '' ? '' : parseFloat(e.target.value);
                                            formik.setFieldValue('dailySpendHardLimit', value);
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Set a daily credit usage email notification threshold</label>
                                <div className='text-muted'>If your organization exceeds this threshold in a given day (UTC), an email notification will be send.</div>

                                <div className="input-group mt-2" style={{ width: '120px' }}>
                                    <span className="input-group-text">$</span>
                                    <Input
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        placeholder="not set"
                                        className="form-control"
                                        aria-label="Amount (to the nearest cent)"
                                        id="dailySpendNotifyThreshold"
                                        value={formik.values.dailySpendNotifyThreshold ?? ''}
                                        onChange={(e) => {
                                            const value = e.target.value === '' ? '' : parseFloat(e.target.value);
                                            formik.setFieldValue('dailySpendNotifyThreshold', value);
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>                            
                        </Row>
                        

                        <Row>
                            <div className="col-md-12 form-group" style={{ marginTop: '10px' }}>
                                <div className="form__group">
                                    <label className="">Set a webhook URL, to receive a POST request when notification or the budget amount is reached.</label>

                                    <div className="input-group">
                                        <Input type="text" className="form-control"
                                            name="webhookUrl"
                                            placeholder={'https://my-app.com/webhook'}
                                            value={formik.values.WebhookUrl}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            style={{ maxWidth: '500px' }}
                                        />
                                        {/*<Button onClick={() => alert('IS DONE')} className="btn btn-success">
                                            Test Webhook
                                        </Button>*/}
                                    </div>
                                </div>
                            </div>
                        </Row>

                    </div>

                }
            </CardBody>
            <CardFooter>
                <div className="row">
                    <div className="col-md-12">
                        <Button type="submit" className="btn btn-primary" style={{ minWidth: '120px' }}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            </CardFooter>
        
            <ToastContainer />
        </Form>
    )
}
export default SpendManagement;