import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_INVOICES_PAYMENTS_DUE, GET_INVOICES_PAYMENTS_ORDER } from "./actionTypes"

const INIT_STATE = {
    invoicesDue: null,
    invoicesOrder: null,
    error: {},    
}

const Invoices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_INVOICES_PAYMENTS_DUE:
                    return {
                        ...state, 
                        invoicesDue: action.payload.data
                    }
                case GET_INVOICES_PAYMENTS_ORDER:
                    return {
                        ...state,
                        invoicesOrder: action.payload.data
                    }                    
                default:
                    return state;
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_INVOICES_PAYMENTS_DUE:
                case GET_INVOICES_PAYMENTS_ORDER:
                    return {
                        ...state, 
                        error: action.payload.error
                    }
                default:
                    return state;
            }
        default:
            return state;
    }
}
export default Invoices;