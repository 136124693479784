import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Button, Col, Container, Form, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../Components/Common/BreadCrumb';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import classnames from "classnames";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { getOrganizationDataApi, updateOrganizationDataApi } from '../helpers/backend_helper';
import { setAuthorization } from '../helpers/api_helper';

const Organization = () => {

    const [activeTab, setActiveTab] = useState("1");
    const [organization, setOrganization] = useState({});    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [info, setInfo] = useState();

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {        
        getOrganizationData();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
    }, [error])

    useEffect(() => {
        if (info) {
            toast.success(info, {
                position: toast.POSITION.TOP_RIGHT
            });
            setInfo(null);
        }
    }, [info])

    const getOrganizationData = () => {
        setLoading(true);
        callToGetOrganizationData().then(() => setLoading(false));
    }

    const callToGetOrganizationData = async () => {
        try {
            const response = await getOrganizationDataApi();

            if (response.status === 200) {
                setOrganization(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: organization.id || null,
            name: organization.name || null,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            // RestDailyPercentageUsed: Yup.number("Rest daily percentage used must be a valid percentage valu").min(0).max(100),
            // AddDailyLimit: Yup.number("Rest daily limit must be a valid long value")
        }),
        onSubmit: (values) => {
            updateOrganizationDataApi(values).then((response) => {
                if (response.status === 200) {
                    setInfo("Organization updated successfully");
                }
                else {
                    setError(response);
                }
            })
            .catch((e) => { 
                // console.log(e);
                setError(e) 
            });
        }
    });

    document.title = "Orgaznization | CoinAPI.io Customer Portal";

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Organization" pageTitle="Details" />

                <Row>
                    <Col lg={12}>
                        <Card>

                            {/* <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">Account details</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div>
                                </div>                                                                                      */}
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                                action="#">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                <i className="fas fa-home"></i>
                                                Organization Details
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}>
                                                <i className="fas fa-home"></i>
                                                Business legal details
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                    <div className='d-flex gap-1' style={{ marginRight: '-10px', float: 'right', marginTop: '-25px' }}>
                                        <LoadingSpinner show={loading} />
                                    </div>
                                </CardHeader>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <CardBody>
                                            <div className="row row-border-bottom">
                                                <div className="col-md-6">
                                                    <div className="form__group">
                                                        <label className="form__label">Organization name</label>

                                                        <Input type="text" className="form-control"
                                                            name="name"
                                                            value={validation.values.name || ''}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.name && validation.errors.name ? true : false
                                                            }
                                                        />
                                                        <div className='invalid-tooltip'>{validation.errors.name}</div>
                                                    </div>
                                                </div>
                                            </div>                                                                               
                                        </CardBody>
                                    </TabPane>
                                    {/* <TabPane tabId="2">

                                        <CardBody>
                                            
                                        </CardBody>

                                    </TabPane> */}
                                </TabContent>

                                <CardFooter>
                                    <div className="row" style={{ float: "right", height: "50px" }}>
                                        <div className="col-md-8 form-group" >
                                            <Button type="submit" className="btn btn-primary">
                                                UPDATES
                                            </Button>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>

                </Row>

                <ToastContainer />

            </Container>
        </div >
    )
}
export default Organization;