import SimpleTableViewComponent from "../Components/Common/SimpleTableViewComponent";
import { getActivityApi } from "../helpers/backend_helper";

const Activity = () => {

    const title = "Audit Trail";
    const pageTitle = "Organization Audit Trail";
    const apiCall = getActivityApi;
    const columns = [
        {
            name: "Time",
            selector: row => row.eventTimestamp,            
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email,            
            sortable: true,
            wrap: true,
        },
        {
            name: "IP Address",
            selector: row => row.ipAddress,
            sortable: true,
        },
        {
            name: "Description",
            selector: row => row.description,
            sortable: true,
            wrap: true,
        },
    ];
    const filterColumns = ['email', 'description', 'ipAddress'];


    return (
        <SimpleTableViewComponent 
            title={title}
            pageTitle={pageTitle}
            apiCall={apiCall}
            columns={columns}
            filterColumns={filterColumns}
        />
    )
}

export default Activity;
