import React, { useEffect, useState } from 'react';
import { getTracesApi, getTracesCsvApi } from "../helpers/backend_helper";

import {
    Card,
    CardBody,
    Button,
    Col,
    Container,
    Row,
    Badge,
    UncontrolledCollapse,
} from "reactstrap";

import BreadCrumb from '../Components/Common/BreadCrumb';
import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { dateInYyyyMmDdHhMmSs } from '../helpers/common';
import { saveAs } from 'file-saver';

const Traces = () => {

    const timeFilter = [
        { value: 5, label: 'Last 5 minutes' },
        { value: 15, label: 'Last 15 minutes' },
        { value: 60, label: 'Last 1 hour' },
        { value: 240, label: 'Last 4 hours' },
        { value: 1440, label: 'Last 24 hours' },
    ];        
    const httpStatusFilter = [
        { value: 0, label: 'All'},
        { value: 2, label: 'Success' },
        { value: 4, label: 'Client Error' },
        { value: 5, label: 'Server Error'}
    ];

    const [traces, setTraces] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [time, setTime] = useState(timeFilter[0].value);
    const [status, setStatus] = useState(httpStatusFilter[0].value);   

    useEffect(() => {
        getTraces();
    }, [time, status]);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])

    const getTraces = () => {
        setLoading(true);
        callToGetTraces().then(() => setLoading(false));
    }

    const callToGetTraces = async () => {
        try {
            const response = await getTracesApi(time, status);

            if (response.status === 200) {
                setTraces(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const handleDownload = () => {
        setLoading(true);
        getTracesCsvApi(time, status).then(async (response) => {
            if (response.status === 200) {                
                var blob = new Blob([response.data], {
                    type: "text/plain;charset=utf-8",
                  });
                saveAs(blob, `export-data.csv`);
                setLoading(false);
            }
            else {
                setError(response);
                setLoading(false);
            }
        });
    }

    document.title = "Traces (Requests) | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Traces (Requests)" pageTitle="Traces View" />

                    <Row>
                        <Col xl={3} lg={4}>
                            <Card>

                                <div className="accordion accordion-flush">

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button bg-transparent shadow-none"
                                                type="button"
                                                id="flush-headingTime"
                                            >
                                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                                    Time
                                                </span>
                                            </button>
                                        </h2>
                                        <UncontrolledCollapse
                                            toggler="#flush-headingTime"
                                            defaultOpen
                                        >
                                            <div
                                                id="flush-collapseTime"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingTime"
                                            >
                                                <div className="accordion-body text-body pt-0">

                                                    <div className="d-flex flex-column gap-2 mt-0">
                                                        {timeFilter.map((time, index) => {
                                                            return (
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="timeFilter"
                                                                        id={time.label}
                                                                        defaultChecked={index === 0 ? true : false}
                                                                        onChange={() => setTime(time.value)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={time.label}
                                                                    >
                                                                        {time.label}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button bg-transparent shadow-none collapsed"
                                                type="button"
                                                id="flush-headingStatus"
                                            >
                                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                                    HTTP STATUS
                                                </span>
                                            </button>
                                        </h2>
                                        <UncontrolledCollapse
                                            toggler="#flush-headingStatus"
                                            defaultOpen>
                                            <div
                                                id="flush-collapseStatus"
                                                className="accordion-collapse collapse show"
                                            >
                                                <div className="accordion-body text-body pt-1">
                                                    <div className="d-flex flex-column gap-2 mt-0">
                                                        {httpStatusFilter.map((status, index) => {
                                                            return (
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="statusFilter"
                                                                        id={status.label}
                                                                        defaultChecked={index === 0 ? true : false}
                                                                        onChange={() => setStatus(status.value)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={status.label}
                                                                    >
                                                                        {status.label}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>

                                </div>

                            </Card>
                        </Col>
                        <Col xl={9} lg={8}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{ height: "70.5px" }}>
                                    <h4 className="card-title mb-0 flex-grow-1">Traces List</h4>
                                    <div className='d-flex gap-1' style={{ marginRight: '5px' }}>
                                        <LoadingSpinner show={loading} />
                                        <Button color="primary" className="btn-sm" onClick={() => handleDownload()}><i className="fas fa-sync-alt"></i> Export data</Button>  
                                    </div>
                                </div>

                                <CardBody>
                                    <div className="table-card">
                                        <TracesTable data={traces} loading={loading} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}
export default Traces;

const TracesTable = ({ data, loading }) => {

    function formatDuration(duration) {
        if (duration < 1000) {
            return duration + ' ns';
        } else if (duration < 1000000) {
            return (duration / 1000).toFixed(2) + ' μs';
        } else if (duration < 1000000000) {
            return (duration / 1000000).toFixed(2) + ' ms';
        } else {
            return (duration / 1000000000).toFixed(2) + ' s';
        }
    }

    const columns = [
        {
            name: "Timestamp (UTC)",
            selector: row => row.timestamp,
            format: row => dateInYyyyMmDdHhMmSs(new Date(row.timestamp)),
            sortable: true,
            // width: '260px',
        },
        {
            name: "Method",
            selector: row => row.spanName,
            sortable: true,
            // width: '260px',
        },
        {
            name: "Product",
            selector: row => row.serviceName,
            sortable: true,
            // width: '260px',
        },
        {
            name: "http",
            selector: row => row.spanAttributes["http.status_code"],
            format: row => {
                let color = "info"
                const status = row.spanAttributes["http.status_code"];
                if (status >= 200 && status < 300) {
                    color = "success";
                }
                else if (status >= 300 && status < 400) {
                    color = "warning";
                }
                else if (status >= 400 && status < 500) {
                    color = "danger";
                }
                return (<Badge color={color}> {row.spanAttributes["http.status_code"]} </Badge>)
            },
            sortable: true,
        },
        {
            name: "Response time",
            selector: row => row.duration,
            format: row => formatDuration(row.duration),
            sortable: true,
            // width: '200px',
            right: true,
        }
    ];

    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data.spanAttributes, null, 2)}</pre>;

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} paginationPerPage="15" filterColumns={['spanName', 'serviceName']}
                expandableRows={true} expandableRowsComponent={ExpandedComponent} />
        </React.Fragment>
    )
}