import { ADD_NEW_CARD, API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, DELETE_CARD, GET_PAYMENTS_CARDS, SET_DEFAULT_CARD } from "./actionTypes"

export const paymentsApiSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const paymentsApiError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPaymentsCards = () => ({
    type: GET_PAYMENTS_CARDS,
    payload: {}    
});

export const setDefaultCard = card => ({
    type: SET_DEFAULT_CARD,
    payload: card
});

export const deleteCard = card => ({
    type: DELETE_CARD,
    payload: card
});

export const addNewCard = sessionId => ({
    type: ADD_NEW_CARD,
    payload: sessionId
});