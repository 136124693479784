import React, { useEffect, useState } from 'react';
import { getAgentTicketsApi } from "../../helpers/backend_helper";
import { useNavigate } from 'react-router-dom';

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
    Button
  } from "reactstrap";

import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableDatatable from '../../Components/Common/TableDatatable';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { dateInYyyyMmDdHhMmSs, formatDateDifference } from '../../helpers/common';

const AgentTickets = () => {

    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        getTickets();
    }, []);

    useEffect(() => {                
        if (error) {            
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])

    const getTickets = () => {
        setLoading(true);
        callToGetAgentTickets().then(() => setLoading(false));
    }

    const callToGetAgentTickets = async () => {
        try {
            const response = await getAgentTicketsApi();

            if (response.status === 200) {
                setTickets(response.data);                
            }
            else {
                setError(response);
            }            
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }    

    document.title = "Agent tickets | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Agent Tickets" pageTitle="Agent tickets" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">Tickets</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div>                                    
                                </div>                                                     

                                <CardBody>
                                    <div className="table-card">
                                        <TicketsTable data={tickets} loading={loading}  />
                                    </div>
                                </CardBody>
                            </Card>                
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );

}

export default AgentTickets;

const TicketsTable = ({ data, loading }) => {

    const columns = [
        {
            name: "ID",
            selector: row => row.idTicketZendesk,
            sortable: true,
            width: '100px',
        },
        {
            name: "Subject",
            selector: row => row.subject,
            sortable: true,
            wrap: true,
        },
        {
            name: "Organization",
            selector: row => row.organization?.name,
            sortable: true,            
        },
        {
            name: "Contact",
            selector: row => row.additionalEmailContact?.email,
            sortable: true,            
        },        
        {
            name: "Created",
            selector: row => dateInYyyyMmDdHhMmSs(new Date(row.createdAt)),
            sortable: true,
            width: '200px',
        },
        {
            name: "Last activity",
            selector: row => dateInYyyyMmDdHhMmSs(new Date(row.updatedAt)),
            sortable: true,
            width: '200px',            
        },
        {
            name: "Severity",
            selector: row => row.severity,
            format: row => <Badge color="primary"> {row.severity} </Badge>,
            sortable: true,
            width: '180px',
        },
        {
            name: "Status",
            selector: row => row.status,
            format: row => <Badge color="info"> {row.status} </Badge>,
            sortable: true,
            width: '100px',
        },
        {
            name: "Priority Score",
            selector: row => row.priorityScore,
            sortable: true,
            width: '100px',
        },
        // {
        //     name: "Actions",
        //     selector: row => <>
        //         <LinkComponent row={row} to={`/support-tickets/details?id=${row.id}`} text="DETAILS" />                
        //     </>,
        //     sortable: false,
        //     width: '150px',
        //     center: true
        // },
    ];    

    const navigate = useNavigate();

    const rowClickFn = (row) => {                                
        window.open(`https://coinapi.zendesk.com/agent/tickets/${row.idTicketZendesk}`);
    }

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} paginationPerPage="15" onRowClicked={(row) => rowClickFn(row)} 
            pointerOnHover={true} highlightOnHover={true} filterColumns={['subject', 'status']} />
        </React.Fragment>
    )
}

const LinkComponent = ({ text, to }) => {
    return (
        <Link to={to} className="btn btn-outline-primary btn-sm waves-effect waves-light" >{text}</Link>
    );
}