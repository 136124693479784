import SimpleTableViewComponent from "../Components/Common/SimpleTableViewComponent";
import { getProblemsListApi } from "../helpers/backend_helper";

const Problems = () => {

    const title = "Problems";
    const pageTitle = "Problems";
    const apiCall = getProblemsListApi;
    const columns = [
        {
            name: "Problem ID",
            selector: row => row.id,
            sortable: true,
            wrap: true,
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable: true,
            wrap: true,
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            wrap: true,
        },
        {
            name: "Severity",
            selector: row => row.severity,
            sortable: true,
            wrap: true,
        },
        {
            name: "Created At",
            selector: row => row.createdAt,
            sortable: true,
            wrap: true,
        },
    ];
    const filterColumns = ['status', 'severity'];

    return (
        <SimpleTableViewComponent 
            title={title}
            pageTitle={pageTitle}
            apiCall={apiCall}
            columns={columns}
            filterColumns={filterColumns}
        />
    )
}
export default Problems;