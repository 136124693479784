import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_INVOICES_PAYMENTS_DUE, GET_INVOICES_PAYMENTS_ORDER } from "./actionTypes";

import { getInvoicesNoPaidApi, getInvoicesPaidApi  } from "../../helpers/backend_helper";
import { invoicesApiSuccess, invoicesApiError } from "./actions";

function* getInvoicesPaid() {    
    try {        
        const response = yield call(getInvoicesPaidApi);
        if (response.status === 200) {
            yield put(invoicesApiSuccess(GET_INVOICES_PAYMENTS_ORDER, response.data));
        }
        else {                  
            yield put(invoicesApiError(GET_INVOICES_PAYMENTS_ORDER, response));
        }
    }
    catch (error) {               
        yield put(invoicesApiError(GET_INVOICES_PAYMENTS_ORDER, error));
    }
}

function* getInvoicesNoPaid() {    
    try {         
        const response = yield call(getInvoicesNoPaidApi);
        if (response.status === 200) {
            yield put(invoicesApiSuccess(GET_INVOICES_PAYMENTS_DUE, response.data));
        }
        else {            
            yield put(invoicesApiError(GET_INVOICES_PAYMENTS_DUE, response));
        }
    }
    catch (error) {        
        yield put(invoicesApiError(GET_INVOICES_PAYMENTS_DUE, error));
    }
}


function* watchGetInvoicesPaid() {
    yield takeEvery(GET_INVOICES_PAYMENTS_ORDER, getInvoicesPaid);
}

function* watchGetInvoicesNoPaid() {
    yield takeEvery(GET_INVOICES_PAYMENTS_DUE, getInvoicesNoPaid);
}

function* InvoicesSaga() {
    yield all(
        [
            fork(watchGetInvoicesPaid),
            fork(watchGetInvoicesNoPaid)
        ]
    )
}

export default InvoicesSaga;