import { GET_STRIPE_ACCOUNTS, API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, IMPERSONATE, RESET_STATE } from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: {},
    setImpersonate: 0
}

let increment = 0;

const impersonate = (state = INIT_STATE, action) => {    
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_STRIPE_ACCOUNTS:
                    return {
                        ...state,
                        data: action.payload.data
                    }
                case IMPERSONATE:
                    return {
                        ...state,
                        setImpersonate: ++increment
                    }
                default:
                    return state;
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_STRIPE_ACCOUNTS:
                case IMPERSONATE:
                    return {
                        ...state,
                        error: action.payload.error
                    }
                default:
                    return state;
            }
        case RESET_STATE: 
            state.setImpersonate = INIT_STATE.setImpersonate
            return state;
        default:
            return state;
    }
}
export default impersonate;