module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: process.env.REACT_APP_API_URL,
  },
  firebaseConfig: {
    apiKey: "AIzaSyAg7XISUnKzBRqs0mLu2e2bGLE7DzMfhCs",
    authDomain: "coinapi-70158.firebaseapp.com",
    projectId: "coinapi-70158",
    storageBucket: "coinapi-70158.appspot.com",
    messagingSenderId: "180294369105",
    appId: "1:180294369105:web:4030b9b1e5786f1cdc5d70",
    measurementId: "G-9XXBVHKDTY",
  },
  coinAPILogin: "https://login.coinapi.io"
};
