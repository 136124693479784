import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Button, Col, Form, Input, Row } from 'reactstrap';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';

import { getOrganizationDataApi, updateOrganizationDataApi } from '../../helpers/backend_helper';

const InvoiceNotification = () => {

    const [organization, setOrganization] = useState({});
    const [error, setError] = useState();
    const [updated, setUpdated] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {        
        getOrganizationData();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
        if (updated) {
            toast.success(updated, {
                position: toast.POSITION.TOP_RIGHT
            });
            setUpdated(null);
        }
    }, [error, updated])

    const getOrganizationData = () => {
        setLoading(true);
        callToGetOrganizationData().then(() => {
            setLoading(false);
        });
    }

    const callToGetOrganizationData = async () => {
        try {
            const response = await getOrganizationDataApi();

            if (response.status === 200) {
                setOrganization(response.data);           
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            billingEmail: organization.billingEmail || null,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            updateOrganizationDataApi(values).then((response) => {
                if (response.status === 200) {
                    setUpdated("Invoice notification updated successfully");                    
                }
                else {
                    setError(response);
                }
            })
                .catch((e) => {
                    setError(e)
                });
        }
    });

    return (
        <Row>
            <Col lg={12}>
                <Card>

                    {/* <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                        <h4 className="card-title mb-0 flex-grow-1">Account details</h4>
                        <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                            <LoadingSpinner show={loading} />
                        </div>
                    </div>                                                                                      */}
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        action="#">

                        <CardBody>
                            <div className="row row-margin-top">
                                <div className="col-md-6">
                                    <div className="form__group">
                                        <label className="form__label">
                                            Billing email
                                        </label>
                                        <p className='text-muted'>Invoices and other billing notifications will be sent here</p>

                                        <Input type="text" className="form-control"
                                            name="billingEmail"
                                            value={validation.values.billingEmail || ''}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.billingEmail && validation.errors.billingEmail ? true : false
                                            }
                                        />
                                        <div className='invalid-tooltip'>{validation.errors.billingEmail}</div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>

                        <CardFooter>
                            <div className="row" style={{ float: "left", height: "50px" }}>
                                <div className="col-md-8 form-group" >
                                    <Button type="submit" className="btn btn-primary">
                                        UPDATES
                                    </Button>
                                </div>
                            </div>
                            <div className='spinner-tab'>
                                <LoadingSpinner show={loading} />
                            </div>  
                        </CardFooter>
                    </Form>
                </Card>
            </Col>

            <ToastContainer />
        </Row>
    );
}
export default InvoiceNotification;