import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Grid, _ } from 'gridjs-react';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ChartPanel from './ChartPanel';
import MetricTable from './MetricTable';

const Metrics = () => {

    const [metricsTableData, setMetricsTableData] = useState([]);
    const [chartSpinner, setChartSpinner] = useState(true);
    
    document.title = "Metrics | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Metrics"  />

                <Row>
                    <Col>
                        <ChartPanel setTableData={setMetricsTableData} chartSpinner={chartSpinner} setChartSpinner={setChartSpinner} />
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <MetricTable tableData={metricsTableData} chartSpinner={chartSpinner} />
                    </Col>
                </Row>                

            </Container>
        </div>
        </React.Fragment>
    );
};

export default Metrics;