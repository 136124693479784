import React, { useEffect, useState } from 'react';

import { CardBody, CardFooter, Button, Form, Input, Row, Spinner} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";

import * as Yup from "yup";
import { useFormik } from "formik";
import { addFoundsApi, getCreditsMethodsApi } from '../../helpers/backend_helper';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCard } from '../../store/actions';
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';

import {loadStripe} from '@stripe/stripe-js/pure';

const AddFounds = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState();
    const [updated, setUpdated] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const { stripeSession } = useSelector(state => ({                
        stripeSession: state.Payments.stripeSession,
    }));

    useEffect(() => {
        getPaymentMethods();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
        if (updated) {
            toast.success(updated, {
                position: toast.POSITION.TOP_RIGHT
            });
            setUpdated(null);
        }
    }, [error, updated])

    useEffect(() => {
        if (stripeSession.sessionId !== undefined) {            
            loadStripe(stripeSession.stripeApiKey).then(x => {
                x.redirectToCheckout({
                    sessionId: stripeSession.sessionId
                })
                
            });            
        }
    }, [navigate, stripeSession]);

    const formikPayment = useFormik({
        enableReinitialize: true,
        initialValues: {            
            amount: '',
            paymentMethodId: '',
            redirectUrl: '/billing?tab=add-service-credits'
        },
        validationSchema: Yup.object({
            amount: Yup.number().required("Amount is required").min(5, "Amount must be at least $5").max(5000, "Amount must be at most $5000"),
            paymentMethodId: Yup.string().required("Payment method is required"),
        }),
        onSubmit: (values) => {
            setLoadingBtn(true);     
            callAddFounds(values).then(() => {                
                formikPayment.resetForm();
                setLoadingBtn(false);                
            });            
        }
    });

    const getPaymentMethods = () => {
        setLoading(true);
        callGetCreditsMethods().then(() => setLoading(false));
    }

    const callGetCreditsMethods = async () => {
        try {            
            const response = await getCreditsMethodsApi();

            if (response.status === 200) {
                setPaymentMethods(response.data.map(x => ({ value: x.id, label: x.description }) ));                
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callAddFounds = async (data) => {
        try {            
            const response = await addFoundsApi(data);

            if (response.status === 200) {
                if (response.data.completed === true) {
                    setUpdated("Usage Credits added successfully");
                }
                else {
                    window.open(response.data.redirectUrl, "_blank", "noreferrer");
                    setUpdated("In a new window, complete the payment to add Usage Credits to your account");
                }
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const onClickAddNewCard = () => {
        dispatch(addNewCard("/billing?tab=add-service-credits"));
    }
    
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                formikPayment.handleSubmit();
                return false;
            }}
            action="#">
            <CardBody>               

                <div className="row row-border-bottom">
                    <div className="col-md-12">
                        How much would you like to add to your balance?
                    </div>
                </div>

                <Row className="mt-3">
                    <div className="col-md-12 form-group">
                        <label>Payment amount</label>
                        <div className="input-group" style={{ width: '120px' }}>
                            <span className="input-group-text">$</span>
                            <Input type="text" placeholder="10" className="form-control"
                                id="amount"
                                value={formikPayment.values.amount}
                                onChange={formikPayment.handleChange}
                                onBlur={formikPayment.handleBlur}
                                invalid={
                                    formikPayment.errors.amount ? true : false
                                }
                            />
                            <div className='invalid-tooltip' style={{ marginTop: '0px', width: '100%' }}>{formikPayment.errors.amount}</div>
                        </div>
                        <p className='text-muted mt-2'>Enter an amount between $5 and $5000</p>
                    </div>
                </Row>

                <Row >
                    <div className="col-md-12 form-group mb-4">
                        <label>Select a payment method</label>
                        <Select
                            style={{ maxWidth: '350px' }}
                            defaultValue={formikPayment.values.paymentMethodId}
                            options={paymentMethods}
                            id="paymentMethodId"
                            onChange={(option) => formikPayment.setFieldValue("paymentMethodId", option.value)}
                            invalid={
                                formikPayment.errors.paymentMethodId ? true : false
                            }
                        />
                        <div className='invalid-tooltip'
                            style={{ display: formikPayment.errors.paymentMethodId ? 'block' : 'none', marginTop: '0px' }}>
                            {formikPayment.errors.paymentMethodId}
                        </div>

                    </div>
                </Row>
                <Row>
                    <div className='col-md-12 form-group mb-2'>
                        <Link onClick={onClickAddNewCard} >Add new payment method +</Link>
                    </div>
                </Row>

            </CardBody>

            <CardFooter>
                <div className="row" style={{ float: "left", height: "50px" }}>
                    <div className="col-md-12 form-group" >
                        <Button type="submit" className="btn btn-primary" disabled={loadingBtn}>
                            {loadingBtn &&
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{marginRight: "10px"}}
                                />
                            }
                            ADD TO MY BALANCE
                        </Button>
                      
                    </div>
                </div>
                <div className='spinner-tab'>
                    <LoadingSpinner show={loading} />
                </div>  
            </CardFooter>
            
            <ToastContainer />
        </Form>
    )
}
export default AddFounds;