export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_APIKEYS = "GET_APIKEYS";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const CREATE_APIKEY = "CREATE_APIKEY";
export const GET_APIKEY_LIMITS = "GET_APIKEY_LIMITS";
export const GET_APIKEY_IP = "GET_APIKEY_IP";
export const UPDATE_APIKEY_LIMITS = "UPDATE_APIKEY_LIMITS";
export const RESET_STATE = "RESET_STATE";
export const RESET_LIMIT_DATA = "RESET_LIMIT_DATA";
export const RESET_APIKEY_UPDATE_INFO = "RESET_APIKEY_UPDATE_INFO";
export const RESET_APIKEYS_DATA = "RESET_APIKEYS_DATA";
export const UPDATE_APIKEY_STATUS = "UPDATE_APIKEY_STATUS";
export const DELETE_APIKEY = "DELETE_APIKEY";
export const GET_SUBSCRIPTION_PRODUCTS = "GET_SUBSCRIPTION_PRODUCTS";
export const GET_PAY_URL = "GET_PAY_URL";
export const GET_SUBSCRIPTIONS_MANAGE_LINK = "GET_SUBSCRIPTIONS_MANAGE_LINK";