import DataTable from 'react-data-table-component';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ScaleLoader from "react-spinners/ScaleLoader";
import FilterComponent from './FilterComponent';

const TableDatatable  = ({ data, columns, filterColumns, loading, defaultSortFieldId, onSelectedChange, selectable = false, 
    onRowClicked, paginationPerPage = 10, pagination = true, pointerOnHover = false, highlightOnHover = false, 
    expandableRows = false, expandableRowsComponent }) => {

    const [chartMode, setChartMode] = useState('');

    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    // const filteredItems = data.result;    
	// const filteredItems = data.result.filter(
	// 	item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	// );

    const rowsPerPage = [10, 15, 20, 30, 50];

    let filteredItems = [];
    if (filterColumns != null) {
        filteredItems = data.filter(item => {            
            const filterTextLower = filterText.toLowerCase();
            // const filterColumns = ['id', 'name', 'email'];
          
            for (const column of filterColumns) {
              const columnValue = item[column];
              if (columnValue && columnValue.toString().toLowerCase().includes(filterTextLower)) {
                return true;
              }
            }
          
            return false;
        });
    }
    else {
        filteredItems = data
    }
    
    const handleChange = ({ selectedRows }) => {        
        onSelectedChange(selectedRows)
    }

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);

	}, [filterText, resetPaginationToggle]);

    const { mode } = useSelector(state => ({
        mode: state.Layout.layoutModeType
    }));

    useEffect(() => {
        setChartMode(mode);
    }, [mode]);

    const modifiedColumns = columns.map(column => ({
        ...column,
        name: column.name.toUpperCase()
    }));

    if (filterColumns !== undefined && filterColumns.length > 0) {
        return (
            <DataTable
                    columns={modifiedColumns}                                
                    data={filteredItems}
                    pagination={pagination}
                    paginationPerPage={paginationPerPage}
                    paginationResetDefaultPage={resetPaginationToggle} 
                    paginationRowsPerPageOptions={rowsPerPage}
                    subHeader                    
                    subHeaderComponent={subHeaderComponentMemo}
                    theme={chartMode === "dark" ? "dark" : "default"}
                    defaultSortFieldId={defaultSortFieldId}
                    progressPending={loading} 
                    selectableRows={selectable}
                    onSelectedRowsChange={handleChange}
                    selectableRowsHighlight={true}
                    onRowClicked={onRowClicked}
                    pointerOnHover={pointerOnHover}
                    highlightOnHover={highlightOnHover}
                    expandableRows={expandableRows}
                    expandableRowsComponent={expandableRowsComponent}                    
                    progressComponent={<ScaleLoader color="#36d7b7" style={{margin: '10px'}}/>}
            />
        );
    }
    else {
        return (
            <DataTable
                    columns={modifiedColumns}                                
                    data={data}
                    pagination={pagination}
                    paginationPerPage={paginationPerPage}
                    paginationRowsPerPageOptions={rowsPerPage}                       
                    theme={chartMode === "dark" ? "dark" : "default"}
                    defaultSortFieldId={defaultSortFieldId}
                    progressPending={loading} 
                    selectableRows={selectable}
                    onSelectedRowsChange={handleChange}
                    onRowClicked={onRowClicked}
                    pointerOnHover={pointerOnHover}
                    highlightOnHover={highlightOnHover}
                    expandableRows={expandableRows}
                    expandableRowsComponent={expandableRowsComponent}                
                    progressComponent={<ScaleLoader color="#36d7b7" style={{margin: '10px'}}/>}
            />
        );
    }
    
}

export default TableDatatable;