import React, { useEffect, useState } from 'react';
import { customerEventsApi, customerEventsConfirmApi, customerEventsConfirmSelectedApi } from "../helpers/backend_helper";

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
  } from "reactstrap";

import BreadCrumb from '../Components/Common/BreadCrumb';
import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';

const CustomerEvents = () => {

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [confirmedEvent, setConfirmedEvent] = useState();
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {                
        if (error) {            
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])

    useEffect(() => {                
        if (confirmedEvent) {            
            toast.success(confirmedEvent, {
                position: toast.POSITION.TOP_RIGHT
            });
            setConfirmedEvent(null)
        }
    }, [confirmedEvent])

    const getEvents = () => {
        setLoading(true);
        callToGetEvents().then(() => setLoading(false));
    }

    const callToGetEvents = async () => {
        try {
            const response = await customerEventsApi();

            if (response.status === 200) {
                setEvents(response.data);                
            }
            else {
                setError(response);
            }            
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callToEventsConfirm = async (id) => {
        try {
            const response = await customerEventsConfirmApi(id);

            if (response.status === 200) {            
                setConfirmedEvent("Confirmed event");
                getEvents();                         
            }
            else {
                setError(response);
            }            
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const handleTableSelcted = (selectedEvents) => {        
        setSelectedEvents(selectedEvents);        
        // dispatch(createOrder(exchangeName, selectedEvents.flatMap(x => x.symbol_id)))
    };

    const confirmedEventOnClick = async () => {
        const events = selectedEvents.flatMap(x => x.idUserEvent);
        
        setSelectedEvents([]);
        try {
            const response = await customerEventsConfirmSelectedApi(events);

            if (response.status === 200) {             
                setConfirmedEvent("Confirmed events");
                getEvents();                         
            }
            else {
                setError(response);
            }            
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    document.title = "Customer events | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Events" pageTitle="Customer events" />                        

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">Customer Events</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div> 
                                    {selectedEvents.length > 0 &&
                                        <div className="flex-shrink-0">                                                                         
                                            <button type="button" className="btn btn-primary" onClick={confirmedEventOnClick}>
                                                CONFIRM SELECTED ITEMS
                                                <Badge pill color="success" className="position-absolute top-0 start-100 translate-middle">+{selectedEvents.length}
                                                    <span className="visually-hidden">selected events</span>
                                                </Badge>
                                            </button>
                                        </div>                      
                                    }
                                </div>

                                <CardBody>
                                    <div className="table-card">
                                        <EventsTable data={events} confirmEventFn={callToEventsConfirm} loading={loading} onSelectedChange={handleTableSelcted} />
                                    </div>
                                </CardBody>
                            </Card>                
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}

export default CustomerEvents; 

const EventsTable = ({ data, confirmEventFn, loading, onSelectedChange }) => {


    const columns = [
        {
            name: "Email",
            selector: row => row.email,
            sortable: true,
            width: '260px',
        },
        {
            name: "Product Name",
            selector: row => row.idProduct,
            sortable: true,            
        },
        {
            name: "Event Type",
            selector: row => row.eventType,
            sortable: true,
        },
        {
            name: "Text",
            selector: row => row.eventText,
            sortable: true,
            width: '320px',
            wrap: true
        },        
        {
            name: "Date",
            selector: row => row.eventTime,
            sortable: true,
            width: '230px',
        },
        {
            name: "Processed By",
            selector: row => row.processedBy,
            sortable: true,
        },
        {
            name: "Processed Date",
            selector: row => row.processedTime,
            sortable: true,
            width: '230px',
        },
        {
            name: "",
            selector: row => (row.processedTime === undefined) ? (
                <>
                    <div className="hstack gap-2">
                        <button className="btn btn-sm btn-soft-success" onClick={() => confirmEventFn(row.idUserEvent)}>
                            <i className=" ri-calendar-check-line align-bottom" />
                        </button>
                    </div>
                </>
            ) : '',
            sortable: false,
            // style: {width: "50px"}
            center: true
        }
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} 
                filterColumns={['email', 'idProduct', 'eventType', 'eventText', 'processedBy']} 
                paginationPerPage="50" selectable={true} onSelectedChange={onSelectedChange} />
        </React.Fragment>
    )
}