import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Modal, ModalBody, ModalHeader, Form } from 'reactstrap';
import BreadCrumb from '../Components/Common/BreadCrumb';
import { useDispatch, useSelector, connect } from 'react-redux';
import { createApiKey, deleteApikey, getApikeys, getProducts, resetApikeyUpdateInfo, resetLimitDataState, resetState, updateApikeyStatus } from '../store/actions';
import Select from 'react-select';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useSearchParams } from "react-router-dom";

import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import DeleteModal from '../Components/Common/DeleteModal';

import * as Yup from "yup";
import { useFormik } from "formik";

const Apikeys = () => {

    const dispatch = useDispatch();
    const [urlParams] = useSearchParams();

    const [apikeysData, setApikeysData] = useState([]);
    const [newApiKey, setNewApiKey] = useState(null);
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedApikey, setDeleteApikey] = useState(null);
    const [productsData, setProductsData] = useState([]);

    const { apikeys, apikey, error, finishUpdateApikey, products } = useSelector(state => ({
        apikeys: state.Apikeys.apikeys,
        apikey: state.Apikeys.apikey,
        error: state.Apikeys.error,
        finishUpdateApikey: state.Apikeys.finishUpdateApikey,
        products: state.Products.products
    }));

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(resetState());
        }
    }, [error])

    useEffect(() => {
        if (apikeys) {
            setApikeysData(apikeys);
            setLoading(false);
        }
    }, [apikeys])

    useEffect(() => {
        if (products) {
            setProductsData(products.map(x => { return {value: x.id, label: x.name}}) )            
        }
    }, [products])

    useEffect(() => {
        if (finishUpdateApikey) {
            setLoading(true);            
            dispatch(getApikeys(urlParams.get('id')));
            dispatch(resetApikeyUpdateInfo());
        }
    }, [finishUpdateApikey]);

    useEffect(() => {
        if (apikey) {
            setNewApiKey(apikey);
            setLoading(true);
            dispatch(getApikeys(urlParams.get('id'))); 
        }
    }, [apikey]);

    useEffect(() => {
        setLoading(true);
        dispatch(resetLimitDataState());
        dispatch(getApikeys(urlParams.get('id'))); 
        dispatch(getProducts());
    }, [dispatch]);

    const onClickCreateApiKey = () => {    
        setShowModal(true);
        // dispatch(createApiKey(urlParams.get('id')));
    }

    const callCreateApiKey = (value) => {        
        dispatch(createApiKey(value));
    }

    const onClickChangeStatusApiKey = (apikey) => {
        dispatch(updateApikeyStatus(apikey));
    }

    const handleDeleteApikey = () => {
        if (selectedApikey) {
            dispatch(deleteApikey(selectedApikey));
        }
        setDeleteModal(false);
    }

    const onClickDeleteApikey = (apikey) => {        
        setDeleteApikey(apikey);
        setDeleteModal(true);
    };

    document.title = "Api Keys | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>
           
            <CreateApiKeyModal show={showModal} apikey={newApiKey} products={productsData} onBtnClick={callCreateApiKey} onCloseClick={() => { setShowModal(false); setNewApiKey(null); }} />

            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteApikey()}
                onCloseClick={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Api Keys" pageTitle="List Api Keys" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">Api Keys</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div>
                                </div>
                                <div className="card-header align-items-center d-flex">
                                    <div className="mb-0 flex-grow-1" style={{marginRight: "10px"}}>
                                    You can manage, create, delete, enable, or disable API Key at any time. The existence of each additional API Key for a subscription (enabled or disabled) above the plan quota is charged 1 USD per day, with a minimum of one day.
                                    </div>
                                    
                                    <div className="flex-shrink-0">                                        
                                        <button onClick={onClickCreateApiKey} type="button" className="btn btn-primary">
                                            CREATE APIKEY
                                        </button>
                                    </div>
                                </div>                          

                                <CardBody>
                                    <div className="table-card">
                                        {/* {cardsData != null
                                            ?   <PaymentsTable data={cardsData} 
                                                    deleteFunction={onClickCardDelete} 
                                                    setDefaultFunction={onClickSetDefaultCard} />  
                                            :   <div style={{margin: "10px"}}>
                                                    <ScaleLoader color="#36d7b7" />
                                                </div>
                                        } */}
                                        <ApiKeysTable data={apikeysData} onClickChangeStatusApiKey={onClickChangeStatusApiKey} 
                                            onClickDelete={onClickDeleteApikey}/>  
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Apikeys;

const ApiKeysTable = ({ data, onClickChangeStatusApiKey, onClickDelete }) => {

    const columns = [
        {
            name: "API Key ID (First 8 chars)",
            selector: row => row.id,
            format: row => row.id.substring(0, 8),
            sortable: true,
        },
        {
            name: "API Key (First 8 chars)",
            selector: row => row.apikey,
            sortable: true,
        },
        {
            name: "Product",
            selector: row => row.productName,
            sortable: true,
        },
        {
            name: "Creation Date",
            selector: row => row.created,
            sortable: true,
        },

        {
            name: "Last Update",
            selector: row => row.lastUpdate,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => <>
                <LinkComponent row={row} to={`/apikeys/apikeyauth?id=${row.id}&apikey=${row.apikey}`} text="AUTH CONFIGURATION" />
                {/* &nbsp;
                <LinkComponent row={row} to={`/apikeys/apikeyhistory?id=${row.id}&apikey=${row.apikey}`} text="IP HISTORY" /> */}
            </>,
            sortable: false,
            width: '350px',
            center: true
        },
        {
            name: "Status",
            selector: row => <DisableEnableComponent onClickFn={onClickChangeStatusApiKey} row={row} /> ,
            sortable: false,
            width: '120px',
            center: true
        },
        {
            name: "",
            selector: row => <DeleteApikey row={row} deleteFunction={onClickDelete} />,
            sortable: false,
            width: '100px',
            center: true
        }        
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} />
        </React.Fragment>
    )
}

const LinkComponent = ({ row, text, to }) => {
    return (
        <Link to={to} className="btn btn-outline-primary btn-sm waves-effect waves-light" >{text}</Link>
    );
}

const DisableEnableComponent = ({ row, onClickFn }) => {
    return (
        <button type="button" onClick={() => onClickFn(row.id)} className={`btn btn-outline-${ row.isActive === 1 ? 'success' : 'danger' } btn-sm waves-effect waves-light`} >{ row.isActive === 1 ? 'ENABLED' : 'DISABLED' }</button>
    );
}

const DeleteApikey = ({ row, deleteFunction }) => {
    return (
        <div className="hstack gap-2">
            <button className="btn btn-sm btn-soft-danger" onClick={() => deleteFunction(row.id)}>
                <i className="ri-delete-bin-5-fill align-bottom" />
            </button>
        </div>
    )
}

const CreateApiKeyModal = ({ show, apikey, products, onBtnClick, onCloseClick }) => {
    
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            ProductId: null
        },
        validationSchema: Yup.object({
            ProductId: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            onBtnClick(values);
        }
    });

    return (
        <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader>Create new ApiKey</ModalHeader>
            <ModalBody className="py-3 px-5">

                { apikey == null &&
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();                            
                            return false;
                        }}
                        action="#">
                        <Row>
                            <Col sm={8}>
                                <Select name="ProductId" 
                                    options={products} 
                                    onChange={(val) => validation.initialValues.ProductId = val.value}
                                    // onBlur={validation.handleBlur}
                                    invalid={
                                        validation.errors.ProductId ? true : false
                                    }      
                                    />
                                    <div className='invalid-tooltip'>{validation.errors.ProductId}</div>                                    
                            </Col>
                            <Col sm={4}>
                                <button                                    
                                    type="submit"
                                    className="btn w-sm btn-success">
                                    CREATE
                                </button>    
                            </Col>                    
                        </Row>
                    </Form>
                }                

                { apikey != null &&
                    <>
                        <div className="mt-2" style={{paddingBottom: "15px"}}>
                            Once You will close the dialog You will be able to see only 8 digits of ApiKey.            
                        </div>
                        <p className='text-muted'><h2>{apikey?.apiKey}</h2></p>                
                    </>
                }

                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        onClick={onCloseClick}
                        type="button"
                        className="btn w-sm btn-light"
                        data-bs-dismiss="modal">
                        Close
                    </button>         
                </div>
            </ModalBody>
        </Modal>
    );
}
