import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { CREATE_APIKEY, DELETE_APIKEY, GET_APIKEYS, GET_APIKEY_IP, GET_APIKEY_LIMITS, GET_PAY_URL, GET_SUBSCRIPTIONS, GET_SUBSCRIPTIONS_MANAGE_LINK, GET_SUBSCRIPTION_PRODUCTS, UPDATE_APIKEY_LIMITS, UPDATE_APIKEY_STATUS } from "./actionTypes";

import { getApikeysApi, getSubscriptionsApi, getSubscriptionsStateApi, createApiKeyApi, getApikeyLimitsApi, updateApikeyLimitsApi, getApikeyIpApi, updateApikeyStatusApi, deleteApikeyApi, getSubscriptionProductsApi, payUrlApi, getSubscriptionManageLinkApi } from "../../helpers/backend_helper";
import { apikeysApiSuccess, apikeysApiError } from "./actions";

function* getSubscriptions() {    
    try {         
        const response = yield call(getSubscriptionsApi);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_SUBSCRIPTIONS, response.data));
        }
        else {            
            yield put(apikeysApiError(GET_SUBSCRIPTIONS, response));
        }
    }
    catch (error) {
        yield put(apikeysApiError(GET_SUBSCRIPTIONS, error));
    }
}

function* getSubscriptionProducts() {    
    try {         
        const response = yield call(getSubscriptionsStateApi);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_SUBSCRIPTION_PRODUCTS, response.data));
        }
        else {            
            yield put(apikeysApiError(GET_SUBSCRIPTION_PRODUCTS, response));
        }
    }
    catch (error) {
        yield put(apikeysApiError(GET_SUBSCRIPTION_PRODUCTS, error));
    }
}

function* getApikeys() {      
    try {         
        const response = yield call(getApikeysApi);        
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_APIKEYS, response.data));
        }
        else {            
            yield put(apikeysApiError(GET_APIKEYS, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(GET_APIKEYS, error));
    }
}

function* createApiKey({ payload: { product } }) {    
    try {                 
        const response = yield call(createApiKeyApi, product);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(CREATE_APIKEY, response.data));
        }
        else {            
            yield put(apikeysApiError(CREATE_APIKEY, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(CREATE_APIKEY, error));
    }
}

function* getApikeyLimits({ payload: { id } }) {    
    try {         
        const response = yield call(getApikeyLimitsApi, id);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_APIKEY_LIMITS, response.data));
        }
        else {            
            yield put(apikeysApiError(GET_APIKEY_LIMITS, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(GET_APIKEY_LIMITS, error));
    }
}

function* getApikeyIp({ payload: { id } }) {    
    try {         
        const response = yield call(getApikeyIpApi, id);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_APIKEY_IP, response.data));            
        }
        else {            
            yield put(apikeysApiError(GET_APIKEY_IP, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(GET_APIKEY_IP, error));
    }
}

function* updateApikeyLimits({ payload: { data } }) {    
    try {         
        const response = yield call(updateApikeyLimitsApi, data);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(UPDATE_APIKEY_LIMITS, true));
        }
        else {            
            yield put(apikeysApiError(UPDATE_APIKEY_LIMITS, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(UPDATE_APIKEY_LIMITS, error));
    }
}

function* updateApikeyStatus({ payload: { data } }) {    
    try {                 
        const response = yield call(updateApikeyStatusApi, data);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(UPDATE_APIKEY_STATUS, true));
        }
        else {            
            yield put(apikeysApiError(UPDATE_APIKEY_STATUS, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(UPDATE_APIKEY_STATUS, error));
    }
}

function* deleteApikey({ payload: { id } }) {    
    try {                         
        const response = yield call(deleteApikeyApi, id);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(DELETE_APIKEY, true));
        }
        else {            
            yield put(apikeysApiError(DELETE_APIKEY, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(DELETE_APIKEY, error));
    }
}

function* getPayUrl({ payload: { data } }) {      
    try {        
        const response = yield call(payUrlApi, data);
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_PAY_URL, response.data));
        }
        else {            
            yield put(apikeysApiError(GET_PAY_URL, response));
        }
    }
    catch (error) {        
        yield put(apikeysApiError(GET_PAY_URL, error));
    }
}

function* getSubscriptionManageLink() {    
    try {                 
        const response = yield call(getSubscriptionManageLinkApi);        
        if (response.status === 200) {
            yield put(apikeysApiSuccess(GET_SUBSCRIPTIONS_MANAGE_LINK, response.data));
        }
        else {                
            yield put(apikeysApiError(GET_SUBSCRIPTIONS_MANAGE_LINK, response));
        }
    }
    catch (error) {
        yield put(apikeysApiError(GET_SUBSCRIPTIONS_MANAGE_LINK, error));
    }
}

function* watchGetApikeys() {
    yield takeEvery(GET_APIKEYS, getApikeys);
}

function* watchGetSubscriptions() {
    yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptions)    
}

function* watchGetSubscriptionProducts() {
    yield takeEvery(GET_SUBSCRIPTION_PRODUCTS, getSubscriptionProducts)    
}

function* watchCreateApiKey() {
    yield takeEvery(CREATE_APIKEY, createApiKey);
}

function* watchGetApikeyLimits() {
    yield takeEvery(GET_APIKEY_LIMITS, getApikeyLimits);
}

function* watchGetApikeyIp() {
    yield takeEvery(GET_APIKEY_IP, getApikeyIp);
}

function* watchUpdateApikeyLimits() {
    yield takeEvery(UPDATE_APIKEY_LIMITS, updateApikeyLimits);
}

function* watchUpdateApikeyStatus() {
    yield takeEvery(UPDATE_APIKEY_STATUS, updateApikeyStatus);
}

function* watchDeleteApikey() {
    yield takeEvery(DELETE_APIKEY, deleteApikey);
}

function* watchGetPayUrl() {
    yield takeEvery(GET_PAY_URL, getPayUrl);
}

function* watchGetSubscriptionManageLink() {
    yield takeEvery(GET_SUBSCRIPTIONS_MANAGE_LINK, getSubscriptionManageLink);
}

function* ApikeysSaga() { 
    yield all(
        [
            fork(watchGetSubscriptions),
            fork(watchGetSubscriptionProducts),
            fork(watchGetApikeys),
            fork(watchCreateApiKey),
            fork(watchGetApikeyLimits),
            fork(watchUpdateApikeyLimits),
            fork(watchGetApikeyIp),
            fork(watchUpdateApikeyStatus),
            fork(watchDeleteApikey),
            fork(watchGetPayUrl),
            fork(watchGetSubscriptionManageLink),
        ]
    )    
}

export default ApikeysSaga;