import { SET_FIREBASE_USER, AUTH_STATE_CHANGED, AuthStateChangedStatus, SET_QUICKNODE_USER, SET_BILLING_EXTERNAL } from './actions';

const initialState = {  
  user: {},
  authStateChanged: AuthStateChangedStatus.Before
};

export default function firebaseReducer(state = initialState, action) {
  switch(action.type) {
    case SET_BILLING_EXTERNAL:
      return {
        ...state,
        billing_external: action.payload,
      };
    case SET_FIREBASE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_QUICKNODE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_STATE_CHANGED: 
      return {
        ...state,
        authStateChanged: action.payload,
      }  
    default:
      return state;
  }
}