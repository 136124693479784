import React, { useEffect, useState } from 'react';

import { CardBody, CardFooter, Button, Form, Input, Row } from 'reactstrap';

import { useFormik } from "formik";
import { getAutoRechargeDetailsApi, updateAutoRechargeDetailsApi } from '../../helpers/backend_helper';
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';

const AutoRecharge = () => {
    
    // const [autoRechargeDetails, setAutoRechargeDetails] = useState();
    const [enableAutoRecharge, setAutoRecharge] = useState(false);
    const [autoRechargeDetails, setAutoRechargeDetails] = useState();
    const [error, setError] = useState();
    const [updated, setUpdated] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAutoRechargeDetails();
    }, [])

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            setError(null);
        }
        if (updated) {
            toast.success(updated, {
                position: toast.POSITION.TOP_RIGHT
            });
            setUpdated(null);
        }
    }, [error, updated])

    useEffect(() => {
        if (autoRechargeDetails) {
            if (autoRechargeDetails.isAutoRechargeEnabled) {
                setAutoRecharge(true);
            }
            setAutoRechargeDetails(autoRechargeDetails);
            formikAutoRecharge.setValues(autoRechargeDetails)
        }
    }, [autoRechargeDetails]);

    const formikAutoRecharge = useFormik({
        initialValues: {
            isAutoRechargeEnabled: autoRechargeDetails?.isAutoRechargeEnabled,
        },
        onSubmit: (values) => {
            callUpdateAutoRechargeDetails(values).then(() => {
                setAutoRechargeDetails(values);
            });          
        }
    });

    const getAutoRechargeDetails = () => {
        setLoading(true);
        callGetAutoRechargeDetails().then(() => setLoading(false));
    }

    const callGetAutoRechargeDetails = async () => {
        try {
            const response = await getAutoRechargeDetailsApi();

            if (response.status === 200) {
                setAutoRechargeDetails(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callUpdateAutoRechargeDetails = async (data) => {
        try {
            const response = await updateAutoRechargeDetailsApi(data);

            if (response.status === 200) {
                setUpdated("Auto Recharge changes have been saved");
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }    

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                formikAutoRecharge.handleSubmit();
                return false;
            }}
            action="#">
            <CardBody>
                <div className="row row-border-bottom">
                    <div className="col-md-12">
                        Keep your service running by setting up auto-recharge. When your balance reaches a certain threshold, we will automatically charge your card.
                    </div>
                </div>

                <div className="row row-border-bottom" style={{
                    marginTop: '20px'
                }}>
                    <div className="col-md-1 form-group" style={{
                        minWidth: '60px',
                        maxWidth: '60px'
                    }}>
                        <div className="form-check form-switch form-switch-lg" dir="ltr">
                            <Input type="checkbox" className="form-check-input" role="switch"
                                id="isAutoRechargeEnabled"
                                value={formikAutoRecharge.values.isAutoRechargeEnabled || false}
                                checked={formikAutoRecharge.values.isAutoRechargeEnabled || false}
                                onChange={formikAutoRecharge.handleChange}
                                onBlur={formikAutoRecharge.handleBlur}
                                onClick={() => setAutoRecharge(!enableAutoRecharge)}
                            />
                        </div>
                    </div>
                    <div className="col-md-10 form-group">
                        <h4>Setting to Auto Recharge</h4>
                    </div>
                </div>

                {enableAutoRecharge &&
                    <div className="row" style={{
                        padding: '20px',
                        backgroundColor: '#f8f9fa',
                    }}>

                        <Row >
                            <div className="col-md-12 form-group">
                                <label>When balance goes below</label>
                                <div className="input-group" style={{ width: '120px' }}>
                                    <span className="input-group-text">$</span>
                                    <Input type="text" placeholder="10" className="form-control"
                                        id="balanceGoesBelow"
                                        value={formikAutoRecharge.values.balanceGoesBelow}
                                        onChange={formikAutoRecharge.handleChange}
                                        onBlur={formikAutoRecharge.handleBlur}
                                    />
                                </div>
                                <p className='text-muted mt-2'>Enter an amount between $5 and $4995</p>
                            </div>
                        </Row>

                        <Row >
                            <div className="col-md-12 form-group">
                                <label>Bring my balance back up to</label>
                                <div className="input-group" style={{ width: '120px' }}>
                                    <span className="input-group-text">$</span>
                                    <Input type="text" placeholder="20" className="form-control"
                                        id="balanceBackUpTo"
                                        value={formikAutoRecharge.values.balanceBackUpTo}
                                        onChange={formikAutoRecharge.handleChange}
                                        onBlur={formikAutoRecharge.handleBlur}
                                    />
                                </div>
                                <p className='text-muted mt-2'>Enter an amount between $10 and $5000</p>
                                <blockquote className="blockquote mt-3">
                                    <i>For example if your current balance is $20, you will be charged $80 to get back up to $100</i>
                                </blockquote>
                            </div>
                        </Row>

                    </div>
                }

            </CardBody>

            <CardFooter>
                <div className="row" style={{ float: "left", height: "50px" }}>
                    <div className="col-md-12 form-group" >
                        <Button type="submit" className="btn btn-primary">
                            SAVE CHANGES
                        </Button>
                    </div>
                </div>
                <div className='spinner-tab'>
                    <LoadingSpinner show={loading} />
                </div>  
            </CardFooter>

            <ToastContainer />
        </Form>
    )
}
export default AutoRecharge;