import React, { useEffect, useState } from 'react';
import { getDomainsApi } from "../helpers/backend_helper";

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
  } from "reactstrap";

import BreadCrumb from '../Components/Common/BreadCrumb';
import TableDatatable from '../Components/Common/TableDatatable';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';

const DomainView = () => {

    const [domain, setDomain] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        getDomain();
    }, []);

    useEffect(() => {                
        if (error) {            
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [error])  
    
    const getDomain = () => {
        setLoading(true);
        callToGetDomain().then(() => setLoading(false));
    }

    const callToGetDomain = async () => {
        try {
            const response = await getDomainsApi();

            if (response.status === 200) {                
                setDomain(response.data);                
            }
            else {
                setError(response);
            }            
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    document.title = "Domain view | CoinAPI.io Customer Portal";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Domain" pageTitle="Domain View" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">Domain View</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div>                                    
                                </div>                                                     

                                <CardBody>
                                    <div className="table-card">
                                        <DomainTable data={domain} loading={loading} />
                                    </div>
                                </CardBody>
                            </Card>                
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}

export default DomainView;

const DomainTable = ({ data, loading }) => {

    const columns = [
        {
            name: "Domain",
            selector: row => row.domain,
            sortable: true,
            // width: '260px',
        },
        {
            name: "StripeId Count",
            selector: row => row.stripeIDCount,
            sortable: true,            
        },
        {
            name: "Oganizations Count",
            selector: row => row.oganizationsCount,
            sortable: true,
        },
        {
            name: "Users Count",
            selector: row => row.usersCount,
            sortable: true,
        },
        {
            name: "Emails Count",
            selector: row => row.emailsCount,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
        },
        {
            name: "ApiKeys Count",
            selector: row => row.apiKeysCount,
            sortable: true,
        },
        {
            name: "Billing Products Count",
            selector: row => row.billingProductsCount,
            sortable: true,
        },
    ];

    return (
        <React.Fragment>
            <TableDatatable data={data} columns={columns} filterColumns={['domain']} paginationPerPage="15" />
        </React.Fragment>
    )
}