import axios from "axios";
import config, { api } from "../config";
import { AuthStateChangedStatus } from "../store/actions";
import store from "../store/token/store";
import { getFirebaseUser } from "./firebase_helper";
import { useSelector } from "react-redux";
import { getQuickNodeToken } from "./quicknode_helper";
import { getTokenManager } from "./token_manager";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    // return response.data ? response.data : response;
    return response;
  },
  function (error) {
    let message;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    var errorTemp = error.response?.status || error.status;
    switch (errorTemp) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        getTokenManager().refreshToken();
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message =
          error.response?.data.info ||
          error.response?.data.value.info ||
          error.response?.data.value.error ||
          error.message;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
  else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

class APIClient {
  async callRefreshToken() {
    // await getFirebaseUser().then(async (user) => {
    //     await user.getIdToken(true).then(function (idToken) {
    //       setAuthorization(idToken);
    //     });
    //   // }
    // });
  }

  get = async (url, params, responseConfig) => {
    await this.callRefreshToken();

    let response;
    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });
      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, responseConfig);
    } else {
      response = axios.get(`${url}`, responseConfig);
    }
    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient, setAuthorization };
