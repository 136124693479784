import SimpleTableViewComponent from "../Components/Common/SimpleTableViewComponent";
import { getQuotaApi } from "../helpers/backend_helper";
import { Progress } from "reactstrap";
import { formatValueWithUnit } from '../helpers/common';

const Limits = () => {

    const title = "Limits";
    const pageTitle = "Organization Limits";
    const apiCall = getQuotaApi;
    const columns = [
        {
            name: "Product",
            selector: row => row.idProduct,            
            sortable: true,
            wrap: true,
        },
        {
            name: "Name",
            selector: row => row.name,            
            sortable: true,
            wrap: true,
        },
        {
            name: "Type",
            selector: row => row.type,            
            sortable: true,      
            wrap: true,  
        },
        {
            name: "Usage percentage",            
            selector: row => row.valueQuota === 0 ? (row.valueQuota > 0 ? 100 : 0) : (row.valueCurrentUsage / row.valueQuota) * 100,
            format: row => <>
            <div style={{width: '168px'}}>
                <Progress value={row.valueQuota === 0 ? (row.valueQuota > 0 ? 100 : 0) : (row.valueCurrentUsage / row.valueQuota) * 100} color="success" className="animated-progess custom-progress"></Progress>
            </div>
            </>,
            sortable: true,
            width: '200px',            
        },
        {
            name: "Current Usage",            
            selector: row => formatValueWithUnit(row.valueCurrentUsage, row.valueUnit),            
            sortable: true,
            wrap: true,
        },
        {
            name: "Your Quota",
            selector: row => row.quota.valueQuota,  
            format: row => formatValueWithUnit(row.valueQuota, row.valueUnit),          
            sortable: true,
            wrap: true,
        },
        {
            name: "Default Quota",
            selector: row => row.quota.valueQuota,    
            format: row => formatValueWithUnit(row.valueQuota, row.valueUnit),                  
            sortable: true,
            wrap: true,
        },       
        {
            name: "Adjustable",
            selector: row => row.adjustable,
            sortable: true,
            width: '150px',
        },
    ];


    return (
        <SimpleTableViewComponent 
            title={title}
            pageTitle={pageTitle}
            apiCall={apiCall}
            columns={columns}
        />
    )
}
export default Limits;