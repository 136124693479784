import classnames from "classnames";
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import LoadingSpinner from '../../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import SpendManagement from "./SpendManagement";
import AddFounds from "./AddFunds";
import AutoRecharge from "./AutoRecharge";
import { useSearchParams } from "react-router-dom";
import Invoices from "../Invoices";
import Payments from "../Payments";
import Overview from "./Overview";
import InvoiceAddress from "./InvoiceAddress";
import InvoiceNotification from "./InvoiceNotification";

const Billing = () => {

    const [urlParams] = useSearchParams();

    const [activeTab, setActiveTab] = useState(urlParams.get("tab") ? urlParams.get("tab") : "overview");

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    document.title = "Billing | CoinAPI.io Customer Portal";

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Billing" pageTitle="Details" />

                <Row>
                    <Col lg={12}>
                        <Card>

                            {/* <div className="card-header align-items-center d-flex" style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">Account details</h4>
                                    <div className='d-flex gap-1' style={{marginRight: '5px'}}>
                                        <LoadingSpinner show={loading} />
                                    </div>
                                </div>                                                                                      */}

                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                    role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "overview" })}
                                            onClick={() => {
                                                tabChange("overview");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Overview
                                        </NavLink>
                                    </NavItem>                                        
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "invoices" })}
                                            onClick={() => {
                                                tabChange("invoices");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Invoices
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "payment-methods" })}
                                            onClick={() => {
                                                tabChange("payment-methods");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Payment Methods
                                        </NavLink>
                                    </NavItem>                                                                            
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "spend-management" })}
                                            onClick={() => {
                                                tabChange("spend-management");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Spend Management
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "add-service-credits" })}
                                            onClick={() => {
                                                tabChange("add-service-credits");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Add Usage Credits
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "auto-recharge" })}
                                            onClick={() => {
                                                tabChange("auto-recharge");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Auto Recharge
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "invoice-address" })}
                                            onClick={() => {
                                                tabChange("invoice-address");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Invoice Address
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "invoice-notifications" })}
                                            onClick={() => {
                                                tabChange("invoice-notifications");
                                            }}>
                                            <i className="fas fa-home"></i>
                                            Invoice Notifications
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="overview">                                    
                                    {activeTab === 'overview' && <Overview 
                                    setActiveTab={setActiveTab} />}
                                </TabPane>

                                <TabPane tabId="invoices">                                    
                                    {activeTab === 'invoices' && <Invoices />}
                                </TabPane>

                                <TabPane tabId="payment-methods">
                                    {activeTab === 'payment-methods' && <Payments />}
                                </TabPane>

                                <TabPane tabId="spend-management">
                                    <SpendManagement />
                                </TabPane>                        

                                <TabPane tabId="add-service-credits">
                                    {activeTab === 'add-service-credits' && <AddFounds />}
                                </TabPane>

                                <TabPane tabId="auto-recharge">                                    
                                    {activeTab === 'auto-recharge' && <AutoRecharge />}
                                </TabPane>

                                <TabPane tabId="invoice-address">
                                    {activeTab === 'invoice-address' && <InvoiceAddress />}
                                </TabPane>

                                <TabPane tabId="invoice-notifications">
                                    {activeTab === 'invoice-notifications' && <InvoiceNotification />}
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Col>

                </Row>

                <ToastContainer />

            </Container>
        </div >
    )    
}
export default Billing;