import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL, INVITE_USER, INVITE_USER_SUCCESS, INVITE_USER_FAIL, DELETE_INVITE_USER, GET_USER_CONTEXT, GET_USER_CONTEXT_SUCCESS, SET_USER_CONTEXT_SUCCESS, SET_USER_CONTEXT, GET_ORGANIZATION, GET_ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION } from './actions';

import {deleteInviteUserApi, getOrganizationApi, getUserContextApi, getUsersApi, inviteUsersApi, setUserContextApi, updateOrganizationApi} from "../../helpers/backend_helper";

function* fetchUsers() {
  try {
    const response = yield call(getUsersApi);
    yield put({ type: GET_USERS_SUCCESS, payload: { users: response.data } });
  } catch (e) {
    yield put({ type: GET_USERS_FAIL, payload: { error: e } });
  }
}

function* getUserContext() {
  try {        
    const response = yield call(getUserContextApi);
    yield put({ type: GET_USER_CONTEXT_SUCCESS, payload: { context: response.data } });
  } catch (e) {
    yield put({ type: GET_USERS_FAIL, payload: { error: e } });
  }
}

function* setUserContext(action) {
  try {        
    const response = yield call(setUserContextApi, { 'user_id': action.payload.values });
    yield put({ type: SET_USER_CONTEXT_SUCCESS, payload: { context: response.data } });
  } catch (e) {
    yield put({ type: GET_USERS_FAIL, payload: { error: e } });
  }
}

function* inviteUser(action) {
  try {    
    yield call(inviteUsersApi, action.payload.values);
    yield put({ type: INVITE_USER_SUCCESS });
    yield call(fetchUsers);
  } catch (e) {
    yield put({ type: INVITE_USER_FAIL, payload: { error: e } });
  }
}

function* deleteInviteUser(action) {
  try {
    yield call(deleteInviteUserApi, action.payload.values.id);
    yield put({ type: INVITE_USER_SUCCESS });
    yield call(fetchUsers);
  } catch (e) {
    yield put({ type: INVITE_USER_FAIL, payload: { error: e } });
  }
}

function* getOrganization() {
  try {        
    const response = yield call(getOrganizationApi);
    yield put({ type: GET_ORGANIZATION_SUCCESS, payload: response.data });
  } catch (e) {
    // yield put({ type: GET_USERS_FAIL, payload: { error: e } });
  }
}

function* updateOrganization(action) {
  try {    
    const response = yield call(updateOrganizationApi,  action.payload );
    yield put({ type: UPDATE_ORGANIZATION_SUCCESS, payload: response.data });
  } catch (e) {
    // yield put({ type: GET_USERS_FAIL, payload: { error: e } });
  }
}

export default function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(INVITE_USER, inviteUser);
  yield takeEvery(DELETE_INVITE_USER, deleteInviteUser);
  yield takeEvery(GET_USER_CONTEXT, getUserContext);
  yield takeEvery(SET_USER_CONTEXT, setUserContext);
  yield takeEvery(GET_ORGANIZATION, getOrganization);
  yield takeEvery(UPDATE_ORGANIZATION, updateOrganization);
}