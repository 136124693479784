import React, { useEffect, useState } from 'react';
import { CardBody, Card, Button, Input } from 'reactstrap';
import { getAutoRechargeDetailsApi, getCreditsApi, getOrganizationDataApi } from '../../helpers/backend_helper';
import { call } from 'redux-saga/effects';
import { ToastContainer, toast } from 'react-toastify';

const Overview = ({ setActiveTab }) => {

    const [credits, setCredits] = useState(null);
    const [autoRechargeDetails, setAutoRechargeDetails] = useState(null);
    const [usageDetails, setUsageDetails] = useState(null);
    const [organization, setOrganization] = useState(null);
    // const [hardLimit, setHardLimit] = useState(Number.parseFloat(0).toFixed(2));
    const [ribbon, setRibbon] = useState(null);
    const [ribbonTwo, setRibbonTwo] = useState(null);
    const [isEmailBillingEnabled, setInvoiceBillingEmail] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        getCredits();
        callToGetOrganizationData();
        callGetAutoRechargeDetails();
    }, []);

    useEffect(() => {
        if (usageDetails) {
            setRibbon(usageDetails.isSpendMgmtEnabled === true ? "ribbon-two-success" : "ribbon-two-danger");
        }
    }, [usageDetails]);

    useEffect(() => {
        if (autoRechargeDetails) {
            setRibbonTwo(autoRechargeDetails.isAutoRechargeEnabled === true ? "ribbon-two-success" : "ribbon-two-danger");
        }
    }, [autoRechargeDetails]);

    useEffect(() => {
        if (organization) {
            if (organization.billingEmail != null && organization.billingEmail.length > 0) {        
                setInvoiceBillingEmail(true);
            }
            else {
                setInvoiceBillingEmail(false);
            }
        }
    }, [organization]);

    const modifyOnClickFn = () => {
        setActiveTab("auto-recharge");
    }

    const modifyOnClickOverageFn = () => {
        setActiveTab("spend-management");
    }

    const modifyAddressOnClickFn = () => {
        setActiveTab("invoice-address");
    }

    const modifyEmailOnClickFn = () => {
        setActiveTab("invoice-notifications");
    }

    const getCredits = () => {
        callGetCredits();
    }

    const callGetCredits = async () => {
        try {
            const response = await getCreditsApi();

            if (response.status === 200) {
                setCredits(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callGetAutoRechargeDetails = async () => {
        try {
            const response = await getAutoRechargeDetailsApi();

            if (response.status === 200) {
                setAutoRechargeDetails(response.data);
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    const callToGetOrganizationData = async () => {
        try {
            const response = await getOrganizationDataApi();

            if (response.status === 200) {
                setOrganization(response.data);           
            }
            else {
                setError(response);
            }
        }
        catch (e) {
            const error = await e;
            setError(error);
        }
    }

    return (
        <div>
            <CardBody>
                <div className="row gap-4 p-3">

                    <Card className="col-md-6 border shadow-none mb-lg-0 material-shadow">
                        <CardBody className="text-muted">
                            <p className="mb-2 mt-3">
                                <h5>Organization Usage Credits</h5>
                                <div className='mt-4'>Credit balance</div>
                                <h1 className='mt-2'>${credits != null ? Number.parseFloat(credits.balance).toFixed(2) : Number.parseFloat(0).toFixed(2)}</h1>
                            </p>
                        </CardBody>
                    </Card>

                    <Card className="col-md-5 border shadow-none mb-lg-0 material-shadow">
                        <CardBody className="text-muted">
                            <p className="mb-2 mt-3">
                                <h5>Uninvoiced Usage Credits</h5>
                                <div className='mt-4'>Currently / Hard Limit</div>
                                <h1 className='mt-2'>${credits != null ? Number.parseFloat(credits.uninvoicedCurrently).toFixed(2) : Number.parseFloat(0).toFixed(2)} / ${credits != null ? Number.parseFloat(credits.uninvoicedLimit).toFixed(2) : Number.parseFloat(0).toFixed(2)}</h1>
                            </p>
                        </CardBody>
                    </Card>
                </div>
                <div className="row gap-4 p-3">
                    {organization &&
                        <Card className="col-md-6 border shadow-none mb-lg-0 material-shadow">
                            <CardBody className="text-muted">
                                <p className="mb-2 mt-3">
                                    <h5>Invoice Address</h5>
                                    <div><i>Company:</i> {organization.company}</div>
                                    <div>{organization.address1} {organization.address2}</div>
                                    <div>{organization.postalCode} {organization.city}, {organization.country}</div>
                                    <Button className="btn btn-primary mt-3" onClick={() => modifyAddressOnClickFn()}>EDIT ADDRESS</Button>
                                </p>
                            </CardBody>
                        </Card>                    
                    }

                    <Card className="col-md-5 border shadow-none mb-lg-0 material-shadow">
                        <CardBody className="text-muted">
                            <p className="mb-2 mt-3">
                                <div className="row" style={{
                                    marginTop: '20px'
                                }}>
                                    <div className="col-md-1 form-group" style={{
                                        minWidth: '40px',
                                        maxWidth: '40px'
                                    }}>
                                        <div className="form-check form-switch form-switch-sm" dir="ltr">
                                            <Input type="checkbox" className="form-check-input" role="switch"
                                                disabled
                                                checked={isEmailBillingEnabled}                                            
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-10 form-group">
                                        <h5>Invoice Billing Email</h5>
                                    </div>
                                </div>
                                {organization &&
                                    <div className="row">
                                        <div className="col-md-12 form-group" style={{ marginTop: '10px' }}>
                                            Your invoices and other billing notifications will be sent to <b>{organization.billingEmail}</b>
                                            {/* <div className="form__group">                                                

                                                <div className="input-group">
                                                    <Input type="text" className="form-control"
                                                        name="webhookUrl"
                                                        // placeholder={'your-mail@mail.com'}
                                                        // value={formik.values.WebhookUrl}
                                                        // onChange={formik.handleChange}
                                                        // onBlur={formik.handleBlur}
                                                        style={{ maxWidth: '500px' }}
                                                    />
                                                    <Button onClick={() => alert('IS DONE')} className="btn btn-success">
                                                        UPDATE
                                                    </Button>
                                                </div>
                                            </div> */}
                                        </div>                                        
                                    </div>                                
                                }
                                <Button className="btn btn-primary mt-3" onClick={modifyEmailOnClickFn}>EDIT EMAIL</Button>
                            </p>
                        </CardBody>
                    </Card>
                    
                </div>

                <div className="row gap-4 p-3">
                    {autoRechargeDetails &&
                        <Card className="col-md-6 ribbon-box border shadow-none mb-lg-0 material-shadow ">
                            <CardBody className="text-muted">
                                <div className={"ribbon-two " + ribbonTwo}><span>{autoRechargeDetails.isAutoRechargeEnabled === true ? "Enabled" : "Disabled"}</span></div>
                                <p className="mb-2 mt-3" style={{ marginLeft: "15px" }}>
                                    {autoRechargeDetails.isAutoRechargeEnabled === true ?
                                        <>
                                            <b>Auto recharge is on</b><br />
                                            When your credit balance goes below ${autoRechargeDetails.balanceGoesBelow}, your payment method will be charged to bring your balance up to ${autoRechargeDetails.balanceBackUpTo}.<br />
                                        </>
                                        :
                                        <>
                                            <b>Auto recharge is off</b><br />
                                        </>
                                    }
                                    <Button className="btn btn-primary mt-3" onClick={modifyOnClickFn}>MODIFY</Button>
                                </p>
                            </CardBody>
                        </Card>
                    }

                    {usageDetails &&
                        <Card className="col-md-5 ribbon-box border shadow-none mb-lg-0 material-shadow">
                            <CardBody className="text-muted">
                                <div className={"ribbon-two " + ribbon}><span>{usageDetails.isSpendMgmtEnabled === true ? "Enabled" : "Disabled"}</span></div>
                                <p className="mb-2 mt-3" style={{ marginLeft: "15px" }}>
                                    {usageDetails.isSpendMgmtEnabled === true ?
                                        <>
                                            <b>Spend Management: Enabled</b><br />
                                            {usageDetails.dailySpendHardLimit ? 
                                                <>Daily Spend Hard Limit: $ {usageDetails.dailySpendHardLimit}</> : 
                                                <>Daily Spend Hard Limit: Not set</>}<br />
                                            {usageDetails.dailySpendNotifyThreshold ? 
                                                <>Daily Spend Notify Threshold: $ {usageDetails.dailySpendNotifyThreshold}</> : 
                                                <>Daily Spend Notify Threshold: Not set</>}<br />
                                        </>
                                        :
                                        <>
                                            <b>Spend Management: Disabled</b><br />
                                            Your organization's usage is not limited.
                                        </>
                                    }
                                    <Button className="btn btn-primary mt-3" onClick={modifyOnClickOverageFn}>MODIFY</Button>
                                </p>
                            </CardBody>
                        </Card>
                    }
                </div>
            </CardBody>
            <ToastContainer />
        </div>
    )
}
export default Overview;