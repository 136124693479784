import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_METRICS, GET_METRICS_GROUP_DATA } from "./actionTypes"

export const metricsApiSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const metricsApiError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getMetrics = () => ({
    type: GET_METRICS,
    payload: {}    
});

export const getMetricsGroupData = (filters) => ({
    type: GET_METRICS_GROUP_DATA,
    payload: {filters}
})