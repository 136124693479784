import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { impersonateApiError, impersonateApiSuccess } from "./actions";
import { GET_STRIPE_ACCOUNTS, IMPERSONATE } from "./actionTypes";
import { getStripeAccountsApi, impersonateApi } from "../../helpers/backend_helper";

function* getStripeAccounts() {
    try {
        const response = yield call(getStripeAccountsApi);
        if (response.status === 200) {
            yield put(impersonateApiSuccess(GET_STRIPE_ACCOUNTS, response.data));
        }
        else {            
            yield put(impersonateApiError(GET_STRIPE_ACCOUNTS, response));
        }
    }
    catch (error) {
        yield put(impersonateApiError(GET_STRIPE_ACCOUNTS, error));
    }
}

function* impersonate(values) {
    try {    
        const response = yield call(impersonateApi, { 'user_id': values.payload });
        if (response.status === 200) {            
            yield put(impersonateApiSuccess(IMPERSONATE, response.data));
        }
        else {            
            yield put(impersonateApiError(IMPERSONATE, response));
        }
    }
    catch (error) {
        console.error(error)
        yield put(impersonateApiError(IMPERSONATE, error));
    }
}

function* watchGetStripeAccounts() {
    yield takeEvery(GET_STRIPE_ACCOUNTS, getStripeAccounts);
}

function* watchImpersonate() {
    yield takeEvery(IMPERSONATE, impersonate);
}

function* ImpersonateSaga() {
    yield all(
        [
            fork(watchGetStripeAccounts),
            fork(watchImpersonate)
        ]
    )    
}

export default ImpersonateSaga;