
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { useSelector } from "react-redux";

const MetricsChart = ({ data }) => {
    
    const calculateDateUtc = (date) => {
        var d = new Date(date);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Extract parts of the date
        const month = monthNames[d.getUTCMonth()];
        const day = d.getUTCDate();
        const hours = d.getUTCHours().toString().padStart(2, '0');
        const minutes = d.getUTCMinutes().toString().padStart(2, '0');
      
        // Format the date
        return `${month} ${day} ${hours}:${minutes}`;
    };

    const colors = [
        { hex: '#7DA9FC', rgba: 'rgba(125, 169, 252, 0.5)' },
        { hex: '#FF75A1', rgba: 'rgba(255, 117, 161, 0.5)' },
        { hex: '#505F7A', rgba: 'rgba(80, 95, 122, 0.5)' },
        { hex: '#8ED5F1', rgba: 'rgba(142, 213, 241, 0.5)' },
        { hex: '#FFCC69', rgba: 'rgba(255, 204, 105, 0.5)' },
        { hex: '#34D399', rgba: 'rgba(52, 211, 153, 0.5)' },
        { hex: '#F87171', rgba: 'rgba(248, 113, 113, 0.5)' },
        { hex: '#A78BFA', rgba: 'rgba(167, 139, 250, 0.5)' },
        { hex: '#60A5FA', rgba: 'rgba(96, 165, 250, 0.5)' },
        { hex: '#FBBF24', rgba: 'rgba(251, 191, 36, 0.5)' },
        { hex: '#6EE7B7', rgba: 'rgba(110, 231, 183, 0.5)' },
        { hex: '#D4A5A5', rgba: 'rgba(212, 165, 165, 0.5)' },
        { hex: '#F9A8D4', rgba: 'rgba(249, 168, 212, 0.5)' },
        { hex: '#C084FC', rgba: 'rgba(192, 132, 252, 0.5)' },
        { hex: '#FB7185', rgba: 'rgba(251, 113, 133, 0.5)' },
        { hex: '#93C5FD', rgba: 'rgba(147, 197, 253, 0.5)' },
        { hex: '#E879F9', rgba: 'rgba(232, 121, 249, 0.5)' },
        { hex: '#F472B6', rgba: 'rgba(244, 114, 182, 0.5)' },
        { hex: '#FBB6CE', rgba: 'rgba(251, 182, 206, 0.5)' },
        { hex: '#D1D5DB', rgba: 'rgba(209, 213, 219, 0.5)' },
        { hex: '#10B981', rgba: 'rgba(16, 185, 129, 0.5)' },
        { hex: '#3B82F6', rgba: 'rgba(59, 130, 246, 0.5)' },
        { hex: '#6366F1', rgba: 'rgba(99, 102, 241, 0.5)' },
        { hex: '#EF4444', rgba: 'rgba(239, 68, 68, 0.5)' },
        { hex: '#F59E0B', rgba: 'rgba(245, 158, 11, 0.5)' },
        { hex: '#84CC16', rgba: 'rgba(132, 204, 22, 0.5)' },
        { hex: '#22D3EE', rgba: 'rgba(34, 211, 238, 0.5)' },
        { hex: '#D946EF', rgba: 'rgba(217, 70, 239, 0.5)' },
        { hex: '#4ADE80', rgba: 'rgba(74, 222, 128, 0.5)' },
        { hex: '#A3E635', rgba: 'rgba(163, 230, 53, 0.5)' },
        { hex: '#F87171', rgba: 'rgba(248, 113, 113, 0.5)' },
        { hex: '#FB923C', rgba: 'rgba(251, 146, 60, 0.5)' },
        { hex: '#38BDF8', rgba: 'rgba(56, 189, 248, 0.5)' },
        { hex: '#F43F5E', rgba: 'rgba(244, 63, 94, 0.5)' },
        { hex: '#A855F7', rgba: 'rgba(168, 85, 247, 0.5)' },
        { hex: '#6B7280', rgba: 'rgba(107, 114, 128, 0.5)' },
        { hex: '#F87171', rgba: 'rgba(248, 113, 113, 0.5)' },
        { hex: '#4F46E5', rgba: 'rgba(79, 70, 229, 0.5)' },
        { hex: '#D97706', rgba: 'rgba(217, 119, 6, 0.5)' },
        { hex: '#EA580C', rgba: 'rgba(234, 88, 12, 0.5)' },
        { hex: '#EC4899', rgba: 'rgba(236, 72, 153, 0.5)' },
        { hex: '#E11D48', rgba: 'rgba(225, 29, 72, 0.5)' },
        { hex: '#7C3AED', rgba: 'rgba(124, 58, 237, 0.5)' },
        { hex: '#16A34A', rgba: 'rgba(22, 163, 74, 0.5)' },
        { hex: '#FCD34D', rgba: 'rgba(252, 211, 77, 0.5)' },
        { hex: '#C026D3', rgba: 'rgba(192, 38, 211, 0.5)' },
        { hex: '#DB2777', rgba: 'rgba(219, 39, 119, 0.5)' },
        { hex: '#F43F5E', rgba: 'rgba(244, 63, 94, 0.5)' },
        { hex: '#D4A5A5', rgba: 'rgba(212, 165, 165, 0.5)' },
        { hex: '#FDE68A', rgba: 'rgba(253, 230, 138, 0.5)' },
        { hex: '#8B5CF6', rgba: 'rgba(139, 92, 246, 0.5)' },
        { hex: '#0EA5E9', rgba: 'rgba(14, 165, 233, 0.5)' },
        { hex: '#1E40AF', rgba: 'rgba(30, 64, 175, 0.5)' },
        { hex: '#6366F1', rgba: 'rgba(99, 102, 241, 0.5)' },
        { hex: '#0D9488', rgba: 'rgba(13, 148, 136, 0.5)' },
        { hex: '#2563EB', rgba: 'rgba(37, 99, 235, 0.5)' },
        { hex: '#F9A8D4', rgba: 'rgba(249, 168, 212, 0.5)' },
        { hex: '#FCD34D', rgba: 'rgba(252, 211, 77, 0.5)' },
        { hex: '#7F1D1D', rgba: 'rgba(127, 29, 29, 0.5)' },
        { hex: '#0F766E', rgba: 'rgba(15, 118, 110, 0.5)' },
        { hex: '#9D174D', rgba: 'rgba(157, 23, 77, 0.5)' },
        { hex: '#CA8A04', rgba: 'rgba(202, 138, 4, 0.5)' },
        { hex: '#FDE047', rgba: 'rgba(253, 224, 71, 0.5)' },
        { hex: '#EA580C', rgba: 'rgba(234, 88, 12, 0.5)' },
        { hex: '#EC4899', rgba: 'rgba(236, 72, 153, 0.5)' },
        { hex: '#E11D48', rgba: 'rgba(225, 29, 72, 0.5)' },
        { hex: '#7C3AED', rgba: 'rgba(124, 58, 237, 0.5)' },
        { hex: '#16A34A', rgba: 'rgba(22, 163, 74, 0.5)' },
        { hex: '#FCD34D', rgba: 'rgba(252, 211, 77, 0.5)' },
        { hex: '#C026D3', rgba: 'rgba(192, 38, 211, 0.5)' },
        { hex: '#DB2777', rgba: 'rgba(219, 39, 119, 0.5)' },
        { hex: '#F43F5E', rgba: 'rgba(244, 63, 94, 0.5)' },
        { hex: '#D4A5A5', rgba: 'rgba(212, 165, 165, 0.5)' },
        { hex: '#FDE68A', rgba: 'rgba(253, 230, 138, 0.5)' },
        { hex: '#8B5CF6', rgba: 'rgba(139, 92, 246, 0.5)' },
        { hex: '#0EA5E9', rgba: 'rgba(14, 165, 233, 0.5)' },
        { hex: '#1E40AF', rgba: 'rgba(30, 64, 175, 0.5)' },
        { hex: '#6366F1', rgba: 'rgba(99, 102, 241, 0.5)' },
        { hex: '#0D9488', rgba: 'rgba(13, 148, 136, 0.5)' },
        { hex: '#2563EB', rgba: 'rgba(37, 99, 235, 0.5)' },
        { hex: '#F9A8D4', rgba: 'rgba(249, 168, 212, 0.5)' },
        { hex: '#FCD34D', rgba: 'rgba(252, 211, 77, 0.5)' },
        { hex: '#7F1D1D', rgba: 'rgba(127, 29, 29, 0.5)' },
        { hex: '#0F766E', rgba: 'rgba(15, 118, 110, 0.5)' },
        { hex: '#9D174D', rgba: 'rgba(157, 23, 77, 0.5)' },
        { hex: '#CA8A04', rgba: 'rgba(202, 138, 4, 0.5)' },
        { hex: '#FDE047', rgba: 'rgba(253, 224, 71, 0.5)' },
        { hex: '#EA580C', rgba: 'rgba(234, 88, 12, 0.5)' },
        { hex: '#EC4899', rgba: 'rgba(236, 72, 153, 0.5)' },
        { hex: '#E11D48', rgba: 'rgba(225, 29, 72, 0.5)' },
        { hex: '#7C3AED', rgba: 'rgba(124, 58, 237, 0.5)' },
        { hex: '#16A34A', rgba: 'rgba(22, 163, 74, 0.5)' },
        { hex: '#FCD34D', rgba: 'rgba(252, 211, 77, 0.5)' },
        { hex: '#C026D3', rgba: 'rgba(192, 38, 211, 0.5)' },
        { hex: '#DB2777', rgba: 'rgba(219, 39, 119, 0.5)' }
      ];

    const convertToChartData = (data) => {        
        if (data.length == 0) {
            return [];
        }
        const seriesLabels = data.seriesLabel; // Array of series labels
        const rows = data.rows;

        const output = rows.map(row => {
            const result = { date: calculateDateUtc(row[0]) }; // Initialize the result object with the date
            seriesLabels.forEach((label, index) => {
                result[label] = row[index + 1]; // Map each series label to the corresponding value
            });
            return result;
        });        
        return output;
    }    

    return (
        <>
            <ResponsiveContainer width="100%" height={600}>
                {data.rows && data.rows.length > 0 ? (
                    <BarChart
                        width={600}
                        height={500}
                        data={convertToChartData(data)}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        wrapperStyle={{ fontSize: "10px" }}
                    >
                        <CartesianGrid strokeDasharray="1 2" />
                        <XAxis dataKey="date" className="chart-font"/>
                        <YAxis className="chart-font" />
                        <Tooltip s wrapperStyle={{ fontSize: "10px" }}/>
                        {/* <Legend /> */}
                        {data?.seriesLabel?.map((s, i) => (
                            <Bar dataKey={s} stackId="a" fill={colors[i] !== undefined ? colors[i].hex : colors[80].hex} />
                        ))}
                    </BarChart>
                ) : (
                    <div className="chart-message">There is no data available for the specified period yet.</div>
                )}
            </ResponsiveContainer>
        </>
    );
};

export { MetricsChart };