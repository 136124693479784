import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

import { useDispatch, useSelector } from "react-redux";
import {
    getUserContext, setUserContext
} from "../../store/actions";

const OrganizationChange = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [contextValue, setContextValue] = useState({});
    const [contextOption, setContextOption] = useState([]);

    const { userContext, error, finishSetUserContext } = useSelector((state) => ({
        userContext: state.usersReducer.userContext,
        error: state.usersReducer.error,
        finishSetUserContext: state.usersReducer.finishSetUserContext,
    }));

    useEffect(() => {
        dispatch(getUserContext());
    }, [dispatch]);

    useEffect(() => {
        if (userContext) {
            setContextOption(
                userContext.map((x) => {
                    return {
                        value: x.user_id,
                        label: x.organization_name !== undefined ? x.organization_name : x.organization_id,
                        active: x.is_active,
                    };
                })
            );
            setContextValue(
                userContext
                    .filter((x) => x.is_active === true)
                    .map((x) => {
                        return {
                            value: x.user_id,
                            label: x.email + " [" + x.user_id + "]",
                        };
                    })[0]
            );
        }
    }, [userContext]);

    // useEffect(() => {
    //     if (userContext) {
    //         store.dispatch(
    //             setBillingExternal(
    //                 ...userContext
    //                     .filter((x) => x.is_active === true)
    //                     .map((x) => {
    //                         return x.billing_external;
    //                     })
    //             )
    //         );
    //     }
    // }, [userContext]);

    useEffect(() => {
        if (finishSetUserContext) {
            navigate(0);
        }
    }, [finishSetUserContext]);

    const onChangeContext = (value) => {        
        dispatch(setUserContext(value));
    };

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <div className="d-flex align-items-center">
                        {/* <img className="rounded-circle header-profile-user" 
                            alt="Header Avatar" /> */}
                        {/* <FeatherIcon icon="home" className="mb-2 pt-2"/> */}

                        <a
                            type="button"
                            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                        >
                            <i className='ri-building-line fs-22'></i>
                        </a>

                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text"><small>ORGANIZATION</small></span>
                            {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">User</span> */}
                        </span>
                    </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {/* <h6 className="dropdown-header"><small>SELECT ORGANIZATION</small></h6> */}
                    {/* <div className="dropdown-divider"></div> */}
                    
                    { contextOption.map((x, idx) => {

                        if (x.active) {
                            return (
                                <DropdownItem key={idx} style={{backgroundColor: 'rgb(223, 240, 250)'}}>
                                    <span>{x.label}</span>
                                </DropdownItem>
                            )
                        }
                        else {
                            return (
                                <DropdownItem key={idx} onClick={() => onChangeContext(x.value)}>
                                    {/* <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                                    <span className="align-middle" data-key="t-logout">Logout</span> */}
                                    <span>{x.label}</span>
                                </DropdownItem>
                            )
                        }                        
                    }) }

                    
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default OrganizationChange;