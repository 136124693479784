import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_METRICS, GET_METRICS_GROUP_DATA } from "./actionTypes"

const INIT_STATE = {
    metrics: {},
    metricsData: [],
    error: {},
}

const Metrics = (state = INIT_STATE, action) => {    
    switch (action.type) {
        case API_RESPONSE_SUCCESS:            
            switch(action.payload.actionType) {
                case GET_METRICS:
                    return {
                        ...state, 
                        metrics: action.payload.data
                    }
                case GET_METRICS_GROUP_DATA:
                    return {
                        ...state,
                        metricsData: action.payload.data
                    }               
                default:
                    return state;
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_METRICS:
                case GET_METRICS_GROUP_DATA:
                    return {
                        ...state, 
                        error: action.payload.error
                    }
                default:
                    return state;
            }
        default:
            return state;
    }
}
export default Metrics;