//LOGIN
export const LOGIN_GETCODE = "/api/auth/loginGetCode";
export const LOGIN_POST = "/api/auth/login";

// METRICS
export const GET_METRICS = "/api/metrics/usage/conf";
export const GET_METRICS_FILTER = "/api/metrics/usage/filters";
export const GET_METRICS_GROUP = "/api/metrics/usage/group";

// PAYMENTS
export const GET_PAYMENTS_CARDS = "/api/payments";
export const SET_DEFAULT_CARD = "/api/payments/setdefaultcard";
export const DELETE_CARD = "/api/payments/deletecard";
export const ADD_NEW_CARD = "/api/payments/addcard";

// INVOICES
export const GET_INVOICES_PAID = "/invoices/paid";
export const GET_INVOICES_NOPAID = "/invoices/nopaid";
export const INVOICE_PDF = "/invoices/pdf";
export const INVOICE_HOSTED = "/invoices/hosted";

// APIKEYS
export const GET_SUBSCRIPTIONS = "/api/subscription";
export const GET_SUBSCRIPTIONS_STATE = "/api/subscription/state";
export const GET_SUBSCRIPTIONS_MANAGE_LINK = "/api/subscription/manage_link"
export const GET_SUBSCRIPTION_PRODUCTS = "/api/subscription/products";
export const GET_APIKEY = "/api/apikeys"; // subscriptionId
export const ADD_APIKEY = "/api/apikeys";
export const GET_APIKEY_IPS = "/api/apikeys/ips"; // apikey
export const GET_APIKEY_LIMITS = "/api/apikeys/limits"; // apikey
export const UPDATE_APIKEY_LIMITS = "/api/apikeys/limits"; 
export const UPDATE_APIKEY_STATUS = "/api/apikeys/status";
export const DELETE_APIKEY = "/api/apikeys/delete";

// PAY
export const PAY = "/api/pay";

export const GET_USERS = "/api/users";
export const INVITE_USER = "/api/users/invite";
export const DELETE_INVITE_USER = "/api/users/delete";
export const GET_USER_CONTEXT = "/api/contexts";
export const GET_ORGANIZATION = "api/users/organization";
export const UPDATE_ORGANIZATION = "api/users/organization/update";

export const GET_ARTIFACTS = "/api/apikeys/auth";
export const ADD_ARTIFACT = "/api/apikeys/auth";
export const UPDATE_ARTIFACT = "/api/apikeys/auth";
export const DELETE_ARTIFACT = "/api/apikeys/auth";
export const ENABLE_ARTIFACT = "/api/apikeys/auth/enable";
export const DISABLE_ARTIFACT = "/api/apikeys/auth/disable";

export const GET_STRIPE_ACCOUNTS = "/api/contexts/impersonate/get-all-accounts";
export const IMPERSONATE = "/api/contexts/impersonate";

export const PRODUCTS = "/api/products";

export const EVENTS = "/api/user-events";
export const EVENTS_CONFIRM = "/api/user-events/mark-processed-events";

export const GET_USER_TICKETS = "/api/tickets";
export const GET_USER_TICKETS_BY_ID = "/api/ticket";
export const GET_USER_TICKET_COMMENTS = "/api/ticket/comments";
export const ADD_COMMENT_TO_TICKET = "/api/ticket/comment";
export const ADD_USER_TICKET = "/api/ticket";
export const ADD_TICKET_ATTACHMENT = "/api/ticket/attachment";

export const GET_AGENT_TICKETS = "/api/tickets-agent";

export const GET_DOMAINS = "/api/domain-view";

export const CUSTOMER_VIEW = "/api/admin/customers";

export const GET_TRACES = "/api/traces";
export const GET_TRACES_CSV = "/api/traces/csv";
export const GET_TRACES_CONNECTIONS = "/api/connectionlog/status";
export const GET_TRACES_CONNECTIONS_EVENTS = "/api/connectionlog/events";

export const UPDATE_ORGANIZATION_DATA = "/api/organization";
export const GET_ORGANIZATION_DATA = "/api/organization";
export const GET_COUNTRIES_DATA = "/api/organization/countries";
export const GET_TAX_IDS_DATA = "/api/organization/tax-ids";

export const GET_NOTIFICATIONS = "/api/notifications";
export const SET_NOTIFICATIONS = "/api/notifications";

export const GET_QUOTA = "/api/quota";
export const GET_QUOTAS_HORIZONTAL = "/api/quota-horizontal";

export const GET_USAGE_DETAILS = "/api/billing/usage";
export const UPDATE_USAGE_DETAILS = "/api/billing/update-usage";
export const GET_AUTO_RECHARGE_DETAILS = "/api/billing/auto-recharge";
export const UPDATE_AUTO_RECHARGE_DETAILS = "/api/billing/auto-recharge";
export const GET_CREDITS_METHODS = "/api/billing/add-credits/methods";
export const ADD_FOUNDS = "/api/billing/add-credits";
export const GET_CREDITS = "/api/billing/credits";
export const GET_PAYG_DATA = "/api/billing/payg";
export const UPDATE_PAYG_DATA = "/api/billing/payg";

export const GET_ACTIVITY = "/api/activity";
export const SET_ACTIVITY = "/api/activity";

export const GET_PROBLEMS_LIST = "/api/problems";

export const GET_ORGANIZATIONS_INFO = "/api/admin/organizations";
export const GET_ORGANIZATIONS_INFO_EXPORT = "/api/admin/organizations/csv";