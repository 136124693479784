const formatDateDifference = (date) => {
    const now = new Date();
    const diff = now - new Date(date);

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
        return years === 1 ? '1 year ago' : years + ' years ago';
    } else if (months > 0) {
        return months === 1 ? '1 month ago' : months + ' months ago';
    } else if (days > 0) {
        return days === 1 ? '1 day ago' : days + ' days ago';
    } else if (hours > 0) {
        return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    } else if (minutes > 0) {
        return minutes === 1 ? '1 minute ago' : minutes + ' minutes ago';
    } else {
        return 'Just now';
    }
}

const formatValueWithUnit = (value, unit) => {
    if (unit !== 'MB') {
        return `${value} ${unit}`;
    }

    const units = ['MB', 'GB', 'TB'];
    let index = 0;

    while (value >= 1024 && index < units.length - 1) {
        value /= 1024;
        index++;
    }

    if (index > 0) {
        return `${value.toFixed(2)} ${units[index]}`;
    } else { 
        return `${value} ${units[index]}`;
    }
};

const padTwoDigits = (num) => {
    return num.toString().padStart(2, "0");
}

const dateInYyyyMmDdHhMmSs = (date, dateDiveder = "-") => {

    return (
        [
            date.getUTCFullYear(),
            padTwoDigits(date.getUTCMonth() + 1),
            padTwoDigits(date.getUTCDate()),
        ].join(dateDiveder) +
        " " +
        [
            padTwoDigits(date.getUTCHours()),
            padTwoDigits(date.getUTCMinutes()),
            padTwoDigits(date.getUTCSeconds()),
        ].join(":")
    );
}

const joinHostWithPath = (host, path) => {
    // Usuń znaki ukośnika z końca hosta i początku ścieżki, jeśli istnieją
    const cleanedHost = host.endsWith('/') ? host.slice(0, -1) : host;
    const cleanedPath = path.startsWith('/') ? path.slice(1) : path;

    // Połącz hosta i ścieżkę za pomocą znaku ukośnika
    return `${cleanedHost}/${cleanedPath}`;
}

export { formatDateDifference, formatValueWithUnit, dateInYyyyMmDdHhMmSs, joinHostWithPath };