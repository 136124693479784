import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

export const getLoginCode = (params) => api.get(url.LOGIN_GETCODE, params);
export const login = (data) => api.create(url.LOGIN_POST, data);

export const getMetricsApi = () => api.get(url.GET_METRICS);
export const getMetricsFilterApi = (params) => api.get(url.GET_METRICS_FILTER, params);
export const getMetricsGroupApi = (params) => api.create(url.GET_METRICS_GROUP, params);

export const getProductsApi = () => api.get(url.PRODUCTS);

export const getAllPaymentsCards = () => api.get(url.GET_PAYMENTS_CARDS);
export const updatePaymentsDefaultCard = (cardId) => api.update(url.SET_DEFAULT_CARD, { cardId });
export const deletePaymentsCard = (cardId) => api.update(url.DELETE_CARD, { cardId });
export const putPaymentsNewCard = (redirectUrl) => api.put(url.ADD_NEW_CARD, { redirectUrl });

export const getInvoicesPaidApi = () => api.get(url.GET_INVOICES_PAID);
export const getInvoicesNoPaidApi = () => api.get(url.GET_INVOICES_NOPAID);
export const getInvoicePdfApi = (invoiceId) => api.get(url.INVOICE_PDF, { invoiceId });
export const getInvoiceHostedApi = (invoiceId) => api.get(url.INVOICE_HOSTED, { invoiceId });

export const getSubscriptionsApi = () => api.get(url.GET_SUBSCRIPTIONS);
export const getSubscriptionsStateApi = () => api.get(url.GET_SUBSCRIPTIONS_STATE);
export const getSubscriptionManageLinkApi = () => api.get(url.GET_SUBSCRIPTIONS_MANAGE_LINK);
export const getSubscriptionProductsApi = () => api.get(url.GET_SUBSCRIPTION_PRODUCTS);
export const getApikeysApi = () => api.get(url.GET_APIKEY);
export const createApiKeyApi = (product) => api.create(url.ADD_APIKEY, product);
export const getApikeyLimitsApi = (id) => api.get(url.GET_APIKEY_LIMITS, {id: id});
export const updateApikeyLimitsApi = (data) => api.update(url.UPDATE_APIKEY_LIMITS, data);
export const getApikeyIpApi = (id) => api.get(url.GET_APIKEY_IPS, {id: id});
export const updateApikeyStatusApi = (id) => api.update(url.UPDATE_APIKEY_STATUS, {id: id});
export const deleteApikeyApi = (id) => api.update(url.DELETE_APIKEY, {id: id});

export const payUrlApi = (params) => api.get(url.PAY, params);

export const getUsersApi = () => api.get(url.GET_USERS);
export const inviteUsersApi = (data) => api.create(url.INVITE_USER, data);
export const deleteInviteUserApi = (id) => api.delete(url.DELETE_INVITE_USER + "?id=" + id);
export const getUserContextApi = () => api.get(url.GET_USER_CONTEXT);
export const setUserContextApi = (data) => api.update(url.GET_USER_CONTEXT, data);
export const getOrganizationApi = () => api.get(url.GET_ORGANIZATION);
export const updateOrganizationApi = (data) => api.create(url.UPDATE_ORGANIZATION, data);

export const getArtifacsApi = (id) => api.get(url.GET_ARTIFACTS + "?id=" + id);
export const addArtifactApi = (data) => api.create(url.ADD_ARTIFACT + "?id=" + data.id, data.values);
export const deleteArtifactApi = (id) => api.delete(url.DELETE_ARTIFACT + "?artifactKey=" + id);
export const enableArtifactApi = (id) => api.update(url.ENABLE_ARTIFACT + "?artifactKey=" + id);
export const disableArtifactApi = (id) => api.update(url.DISABLE_ARTIFACT + "?artifactKey=" + id);
export const updateArtifactApi = (data) => api.update(url.UPDATE_ARTIFACT, data);

export const getStripeAccountsApi = () => api.get(url.GET_STRIPE_ACCOUNTS);
export const impersonateApi = (data) => api.update(url.IMPERSONATE, data);

export const customerEventsApi = () => api.get(url.EVENTS);
export const customerEventsConfirmApi = (id) => api.create(url.EVENTS + "?id_user_event=" + id)
export const customerEventsConfirmSelectedApi = (data) => api.create(url.EVENTS_CONFIRM, data);

export const getUserTicketsApi = () => api.get(url.GET_USER_TICKETS);
export const getUserTicketsApiById = (id) => api.get(url.GET_USER_TICKETS_BY_ID, {ticketId: id});
export const getUserTicketCommentsApi = (id) => api.get(url.GET_USER_TICKET_COMMENTS, {ticketId: id});
export const addUserCommentToTicketApi = (data) => api.create(url.ADD_COMMENT_TO_TICKET, data);
export const addUserTicketApi = (data) => api.create(url.ADD_USER_TICKET, data);

export const getAgentTicketsApi = () => api.get(url.GET_AGENT_TICKETS);

export const getDomainsApi = () => api.get(url.GET_DOMAINS);

export const customerActiveApi = () => api.get(url.CUSTOMER_VIEW + "?category=Active");
export const customerPastApi = () => api.get(url.CUSTOMER_VIEW + "?category=Past");

export const getTracesApi = (time, status) => api.get(url.GET_TRACES + "?time=" + time + "&status=" + status);
export const getTracesCsvApi = (time, status) => api.get(url.GET_TRACES_CSV + "?time=" + time + "&status=" + status);

export const getTracesConnectionsApi = () => api.get(url.GET_TRACES_CONNECTIONS);
export const getTracesConnectionsEventsApi = (id) => api.get(url.GET_TRACES_CONNECTIONS_EVENTS, {connectionId: id});

export const getOrganizationDataApi = () => api.get(url.GET_ORGANIZATION_DATA);
export const updateOrganizationDataApi = (data) => api.create(url.UPDATE_ORGANIZATION_DATA, data);
export const getCountriesApi = () => api.get(url.GET_COUNTRIES_DATA);
export const getTaxIdsApi = () => api.get(url.GET_TAX_IDS_DATA);

export const getNotificationApi = () => api.get(url.GET_NOTIFICATIONS);
export const setNotificationsValueApi = (data) => api.create(url.SET_NOTIFICATIONS, data);

export const getQuotaApi = () => api.get(url.GET_QUOTA);
export const getQuotasHorizontalApi = () => api.get(url.GET_QUOTAS_HORIZONTAL);

export const getUsageDetailsApi = () => api.get(url.GET_USAGE_DETAILS);
export const updateUsageDetailsApi = (data) => api.create(url.UPDATE_USAGE_DETAILS, data);
export const getAutoRechargeDetailsApi = () => api.get(url.GET_AUTO_RECHARGE_DETAILS);
export const updateAutoRechargeDetailsApi = (data) => api.create(url.UPDATE_AUTO_RECHARGE_DETAILS, data);
export const getCreditsMethodsApi = () => api.get(url.GET_CREDITS_METHODS);
export const addFoundsApi = (data) => api.create(url.ADD_FOUNDS, data);
export const getCreditsApi = () => api.get(url.GET_CREDITS);

export const getActivityApi = () => api.get(url.GET_ACTIVITY);
export const setActivityApi = (data) => api.create(url.SET_ACTIVITY, data);

export const getProblemsListApi = () => api.get(url.GET_PROBLEMS_LIST);

export const getOrganizationsInfoApi = () => api.get(url.GET_ORGANIZATIONS_INFO + "?category=Paying");
export const getOrganizationsInfoApiPast = () => api.get(url.GET_ORGANIZATIONS_INFO + "?");
export const getOrganizationInfoExportApi = (id) => api.get(url.GET_ORGANIZATIONS_INFO_EXPORT + "?category=Paying", null, { responseType: 'arraybuffer' });
export const getOrganizationInfoPastExportApi = (id) => api.get(url.GET_ORGANIZATIONS_INFO_EXPORT + "?", null, { responseType: 'arraybuffer' });