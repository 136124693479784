export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAIL = "INVITE_USER_FAIL";
export const DELETE_INVITE_USER = "DELETE_INVITE_USER";
export const GET_USER_CONTEXT = "GET_USER_CONTEXT";
export const GET_USER_CONTEXT_SUCCESS = "GET_USER_CONTEXT_SUCCESS"
export const SET_USER_CONTEXT = "SET_USER_CONTEXT";
export const SET_USER_CONTEXT_SUCCESS = "SET_USER_CONTEXT_SUCCESS"
export const RESET_ERROR = "RESET_ERROR";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";

export const getUsers = () => ({
  type: GET_USERS
});

export const getUserContext = () => ({
  type: GET_USER_CONTEXT
});

export const setUserContext = (values) => ({
  type: SET_USER_CONTEXT,
  payload: {values}
});

export const inviteUser = (values) => ({
  type: INVITE_USER,
  payload: { values }
});

export const deleteInviteUser = (values) => ({
  type: DELETE_INVITE_USER,
  payload: { values }
});

export const resetError = (error) => ({
  type: RESET_ERROR,
  payload: error,
});

export const getOrganization = (values) => ({
  type: GET_ORGANIZATION,
  payload: values
});

export const updateOrganization = (values) => ({
  type: UPDATE_ORGANIZATION,
  payload: values
});