import { resolveConfig } from "prettier";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../store/token/store";
import { getTokenManager } from "../helpers/token_manager";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isJobList, setIsJobList] = useState(false);
  const [isCandidateList, setIsCandidateList] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [menuItems, setMenuItems] = useState([]);
  const state = store.getState()  
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");        
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  const menubillingExternal = [{
    id: "metrics",
    label: "Usage metrics",
    icon: "bx bx-bar-chart-alt-2",
    link: "/metrics",
    // stateVariables: isDashboard,
    // click: function (e) {
    //   e.preventDefault();
    //   setIscurrentState("Metrics");
    // }
  },]

  const buildMenu = async () => {
    const menuItems = [
      {
        label: "Menu",
        isHeader: true,
      },
      {
        id: "organization",
        label: "Organization",
        icon: "bx bx-buildings",
        link: "/organization",        
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("Organization");
        // }
      },
      // {
      //   id: "notifications",
      //   label: "Notifications",
      //   icon: "bx bx-notification",
      //   link: "/notifications",        
      //   // click: function (e) {
      //   //   e.preventDefault();
      //   //   setIscurrentState("Notifications");
      //   // }
      // },
      {
        id: "billing",
        label: "Billing",
        icon: "bx bx-file",
        link: "/billing",
      },
      {
        id: "metrics",
        label: "Usage metrics",
        icon: "bx bx-bar-chart-alt-2",
        link: "/metrics",
        // stateVariables: isDashboard,
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("Metrics");
        // }
      },
      {
        id: "limits",
        label: "Quotas and Limits",
        icon: "ri-pie-chart-line",
        link: "/limits",
        // stateVariables: isDashboard,
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("Metrics");
        // }
      },
      {
        id: "subscriptions",
        label: "Subscriptions",
        icon: "bx bx-file",
        link: "/subscriptions",
        // stateVariables: isDashboard,
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("Subscriptions");
        // }
      },
      {
        id: "apikeys",
        label: "API Keys",
        icon: "bx bx-key",
        link: "/apikeys",
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("ApiKeys");
        // }
      },
      {
        id: "users",
        label: "Team members",
        icon: "bx bx-user",
        link: "/users",
        // stateVariables: isDashboard,
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("Users");
        // }
      },
      {
        id: "tickets",
        label: "Support tickets",
        icon: "ri-ticket-2-line",
        link: "/support-tickets",
        // click: function (e) {
        //   e.preventDefault();
        //   setIscurrentState("SupportTickets");
        // }
      },
      {
        id: "traces",
        label: "Traces (Requests)",
        icon: "ri-terminal-box-fill",
        link: "/traces",        
      },    
      {
        id: "traces",
        label: "Traces (Connections)",
        icon: "ri-terminal-box-fill",
        link: "/traces/connections",        
      },
      {
        id: "audit-trail",
        label: "Audit Trail",
        icon: "ri-spy-line",
        link: "/activity",
      },      
    ];    

    let firebaseUser = getTokenManager().getUserDataFromToken();

    if (firebaseUser.admin === true || firebaseUser.support === true) {
      menuItems.push(
        {
          id: "admin",
          label: "Admin",
          icon: "bx bx-layer",
          link: "/#",
          // click: function (e) {
          //   e.preventDefault();                  
          //   setIsAdmin(!isAdmin);
          //   setIscurrentState("Admin");
          //   updateIconSidebar(e);
          // },
          stateVariables: true,
          subItems: [
            {
              id: "agent-tickets",
              label: "Agent tickets",
              // icon: "ri-ticket-line",
              link: "/agent-tickets",
              parentId: "admin",                    
            },
            {
              id: "impersonate",
              label: "Impersonate",
              // icon: "bx bx-support",
              link: "/impersonate",
              parentId: "admin",  
              // click: function (e) {
              //   e.preventDefault();
              //   setIscurrentState("Impersonate");
              // }
            },
            {
              id: "events",
              label: "Customer events",
              // icon: "ri-calendar-event-line",
              link: "/events",
              parentId: "admin",  
              // click: function (e) {
              //   e.preventDefault();
              //   setIscurrentState("CustomerEvents");
              // }
            },
            {
              id: "domain",
              label: "Domain view",
              // icon: "ri-calendar-event-line",
              link: "/domain",
              parentId: "admin",  
              // click: function (e) {
              //   e.preventDefault();
              //   setIscurrentState("DomainView");
              // }
            },
            {
              id: "customer_active",
              label: "Customer active",                    
              link: "/customer-active",
              parentId: "admin",
            },
            {
              id: "customer_past",
              label: "Customer past",                    
              link: "/customer-past",
              parentId: "admin",
            },                  
            {
              id: "quotas_horizontal",
              label: "Quotas horizontal",                    
              link: "/quotaHorizontal",
              parentId: "admin",
            },
            {
              id: "problems",
              label: "Problems",                    
              link: "/problems",
              parentId: "admin",
            },
            {
              id: "organization-info",
              label: "Organizations Info Paying",                    
              link: "/organization-info",
              parentId: "admin",
            },
            {
              id: "organization-info-past",
              label: "Organizations Info All",                    
              link: "/organization-info-past",
              parentId: "admin",
            }            
          ]
        });              
    }      


    return menuItems;
  }

  useEffect(() => {    
    buildMenu().then(menu => setMenuItems(menu))
  }, [])


  // useEffect(() => {
  //   document.body.classList.remove("twocolumn-panel");
  //   if (iscurrentState !== "Dashboard") {
  //     setIsDashboard(false);
  //   }
  // }, [
  //   history,
  //   iscurrentState,
  //   isDashboard,
  //   isApps,
  //   isAdmin,
  //   isAuth,
  //   isPages,
  //   isBaseUi,
  //   isAdvanceUi,
  //   isForms,
  //   isTables,
  //   isCharts,
  //   isIcons,
  //   isMaps,
  //   isMultiLevel,
  // ]);

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
