import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_STRIPE_ACCOUNTS, IMPERSONATE, RESET_STATE } from "./actionTypes";

export const impersonateApiSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const impersonateApiError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getStripeAccounts = () => ({
    type: GET_STRIPE_ACCOUNTS,
    payload: {}
});

export const impersonate = (values) => ({
    type: IMPERSONATE,
    payload: values
})

export const resetStateImpersonate = () => ({
    type: RESET_STATE
})