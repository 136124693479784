import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AuthSaga from "./auth/login/saga";
import ProfileSaga from "./auth/profile/saga";
import PaymentsSaga from "./payments/saga";
import InvoicesSaga from "./invoices/saga";
import MetricsSaga from "./metrics/saga";
import ApikeysSaga from "./apikeys/saga";
import usersSaga from "./users/saga";
import JwtSaga from "./jwt/saga";
import ImpersonateSaga from "./impersonate/saga";
import ProductsSaga from "./products/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),    
    fork(AuthSaga),
    fork(ProfileSaga),
    //private
    fork(PaymentsSaga),
    fork(InvoicesSaga),
    fork(MetricsSaga),
    fork(ApikeysSaga),
    fork(usersSaga),
    fork(JwtSaga),
    fork(ImpersonateSaga),
    fork(ProductsSaga),
  ]);
}
