import { Button, Spinner } from 'reactstrap';

const LoadingSpinner = ( { show } ) => {    
    return (
        <>
            {show ?
                <Button variant="primary" className='btn-sm' disabled>
                    <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{marginRight: "6px"}}
                    />
                    Loading...
                </Button> 
                : ''}                                                    
        </> 
    );
}

export default LoadingSpinner;