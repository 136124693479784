import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_INVOICES_PAYMENTS_ORDER, GET_INVOICES_PAYMENTS_DUE } from "./actionTypes"

export const invoicesApiSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const invoicesApiError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getInvoicesPaymentsDue = () => ({
    type: GET_INVOICES_PAYMENTS_DUE,
    payload: {}    
});

export const getInvoicesPaymentsOrder = () => ({
    type: GET_INVOICES_PAYMENTS_ORDER,
    payload: {}
})