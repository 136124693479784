import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const SSO = () => {

    const [urlParams] = useSearchParams();

    useEffect(() => {
        var url = window.location.protocol + "//" + window.location.host;
        if (urlParams.get('return_to')) {
            url = urlParams.get('return_to');
        }
        window.open(url, "_self");
    }, []);

    return <></>
}
export default SSO;