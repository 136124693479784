import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";

import Payments from "./payments/reducer";
import Invoices from "./invoices/reducer";
import Metrics from "./metrics/reducer";
import Apikeys from "./apikeys/reducer";
import usersReducer from "./users/reducer";
import Jwt from "./jwt/reducer";
import Firebase from "./token/reducer";
import Impersonate from "./impersonate/reducer";
import Products from "./products/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  //private
  Payments,
  Invoices,
  Metrics,
  Apikeys,
  usersReducer,
  Jwt,
  Firebase,
  Impersonate,
  Products,
});

export default rootReducer;
