import React, { useEffect, useState } from "react";

import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";

const MetricTable = ( { tableData, chartSpinner } ) => {    

    const [chartMode, setChartMode] = useState('');    

    const { mode } = useSelector(state => ({
        mode: state.Layout.layoutModeType
    }));

    useEffect(() => {
        setChartMode(mode);
    }, [mode]);

    return (
        <React.Fragment>
            <Card>
                <CardHeader className='d-flex'>
                    <h4 className="card-title mb-0 flex-grow-1">Summary</h4>
                    {/* <div className='d-flex gap-1'>
                        <LoadingSpinner show={chartSpinner} />
                    </div>         */}
                </CardHeader>
                <CardBody>
                    <div id="table-payments-due" className="table-card">                        
                        <DataTable
                            columns={[
                                {
                                    name: "Group by key",
                                    selector: row => row[0],
                                    sortable: true,
                                },
                                {
                                    name: "Metric sum",
                                    selector: row => row[1],
                                    sortable: true,
                                }
                            ]}                                
                            data={tableData}
                            pagination
                            theme={chartMode === "dark" ? "dark" : "default"}
                        />
                    </div>
                </CardBody>

            </Card>
        </React.Fragment>
    )
};

export default MetricTable;