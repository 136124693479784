import { ADD_ARTIFACT, DELETE_ARTIFACT, DISABLE_ARTIFACT, ENABLE_ARTIFACT, GET_ARTIFACTS, API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, RESET_STATE } from "./actionTypes"

export const getArtifacts = (id) => ({
    type: GET_ARTIFACTS,
    payload: { id },
});

export const addArtifact = (id, values) => ({
    type: ADD_ARTIFACT,
    payload: { id, values },
});

export const enableArtifact = (row) => ({
    type: ENABLE_ARTIFACT,
    payload: { row },
});

export const disableArtifact = (row) => ({
    type: DISABLE_ARTIFACT,
    payload: { row },
});

export const deleteArtifact = (row) => ({
    type: DELETE_ARTIFACT,
    payload: { row },
});

export const jwtApiSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const jwtApiError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const resetStateArtifact = () => ({
    type: RESET_STATE
});