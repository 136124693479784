import SimpleTableViewComponent from "../Components/Common/SimpleTableViewComponent";
import { getOrganizationInfoExportApi, getOrganizationsInfoApi } from "../helpers/backend_helper";

const OrganizationsAdminActive = () => {
    const title = "Organizations Info Paying";
    const pageTitle = "Organizations";
    const apiCall = getOrganizationsInfoApi;

    const columns = [
      {
        name: "ID",
        selector: row => row.organization.id,
        sortable: true,
      },
      {
        name: "Stripe ID",
        selector: row => row.organization.stripeId,
        sortable: true,
      },
      {
        name: "Name",
        selector: row => row.organization.name,
        sortable: true,
      },
      {
        name: "Company",
        selector: row => row.organization.company,
        sortable: true,
      },
      {
        name: "Country",
        selector: row => row.organization.country,
        sortable: true,
      },
      {
        name: "Auto Recharge Enabled",
        selector: row => row.organization.autoRechargeEnabled,
        sortable: true,
      },
      {
        name: "Auto Recharge Balance Goes Below USD",
        selector: row => row.organization.autoRechargeBalanceGoesBelowUsd,
        sortable: true,
      },
      {
        name: "Auto Recharge Balance Back Up To USD",
        selector: row => row.organization.autoRechargeBalanceBackUpToUsd,
        sortable: true,
      },
      {
        name: "PAYG Enabled",
        selector: row => row.organization.paygEnabled,
        sortable: true,
      },
      {
        name: "Credit Balance",
        selector: row => row.organization.creditBalance,
        sortable: true,
      },
      {
        name: "Credit Uninvoiced Current",
        selector: row => row.organization.creditUninvoicedCurrent,
        sortable: true,
      },
      {
        name: "Credit Uninvoiced Limit",
        selector: row => row.organization.creditUninvoicedLimit,
        sortable: true,
      },
      {
        name: "Past Due USD",
        selector: row => row.organization.pastDueUsd,
        sortable: true,
      },
      {
        name: "Active API Keys Count",
        selector: row => row.organization.apiKeysActiveCount,
        sortable: true,
      },
      {
        name: "Tickets Live Wait On Provider",
        selector: row => row.organization.ticketsLiveWaitOnProvider,
        sortable: true,
      },
      {
        name: "Tickets Live Wait On Customer",
        selector: row => row.organization.ticketsLiveWaitOnCustomer,
        sortable: true,
      },
      {
        name: "Tickets Not Live",
        selector: row => row.organization.ticketsNotLive,
        sortable: true,
      },
      {
        name: "Domains",
        selector: row => row.stats.domains.join(", "),
        sortable: true,
      },
      {
        name: "MRR",
        selector: row => row.stats.mrr,
        sortable: true,
      },
      {
        name: "MRR Last Week",
        selector: row => row.stats.mrrLastW,
        sortable: true,      
      },
      {
        name: "MRR Last Month",
        selector: row => row.stats.mrrLastM,
        sortable: true,      
      },
      {
        name: "MRR Last Quarter",
        selector: row => row.stats.mrrLastQ,
        sortable: true,      
      },
      {
        name: "MRR Last Year",
        selector: row => row.stats.mrrLastY,
        sortable: true,
      },
      {
        name: "MRR Diff Last Week",
        selector: row => row.stats.mrrDiffLastW,
        sortable: true,
      },
      {
        name: "MRR Diff Last Month",
        selector: row => row.stats.mrrDiffLastM,
        sortable: true,
      },
      {
        name: "MRR Diff Last Quarter",
        selector: row => row.stats.mrrDiffLastQ,
        sortable: true,        
      },
      {
        name: "MRR Diff Last Year",
        selector: row => row.stats.mrrDiffLastY,
        sortable: true,        
      },
      {
        name: "Organizations Count",
        selector: row => row.stats.organizationsCount,
        sortable: true,
      },
      {
        name: "Users Count",
        selector: row => row.stats.usersCount,
        sortable: true,
      },
      {
        name: "Stripe ID Count",
        selector: row => row.stats.stripeIDCount,
        sortable: true,
      },
      {
        name: "Origin",
        selector: row => row.stats.origin,
        sortable: true,
      },
      {
        name: "Category",
        selector: row => row.stats.category,
        sortable: true,
      },
    ];
    const filterColumns = [];    

    return (
        <SimpleTableViewComponent 
            title={title}
            pageTitle={pageTitle}
            apiCall={apiCall}
            columns={columns}
            filterColumns={filterColumns}
            exportToCsv={true}
            exportToCsvApi={getOrganizationInfoExportApi}
        />
    )
}
export default OrganizationsAdminActive;