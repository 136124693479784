import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Modal, ModalBody, ModalHeader, Input, Form } from 'reactstrap';
import BreadCrumb from '../Components/Common/BreadCrumb';
import LoadingSpinner from '../Components/Common/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getApikeyIp, getApikeyLimits, resetLimitDataState, resetState, updateApikeyLimits } from '../store/actions';
import { useSearchParams, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

const ApikeyHistory = () => {

    const dispatch = useDispatch();
    const [urlParams] = useSearchParams();
    const navigate = useNavigate();

    const [loadingIp, setLoadingIp] = useState(false);
    const [chartMode, setChartMode] = useState('');
    const [tableData, setTableData] = useState([]); 

    const { apikeyip, error, } = useSelector(state => (        
        {            
            error: state.Apikeys.error,         
            apikeyip: state.Apikeys.apikeyip            
        }
    ));

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });        
        }        
    }, [error])

    useEffect(() => {
        if (apikeyip) {
            setTableData(apikeyip);            
            setLoadingIp(false);
        }
    }, [apikeyip])

    useEffect(() => {        
        setLoadingIp(true);        
        dispatch(getApikeyIp(urlParams.get('id'))); 
    }, [dispatch]);

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Subscriptions" pageTitle="ApiKey history" />

                    <Row>                        
                        <Col lg={12}>                         

                            <Card>
                                <CardHeader className='d-flex align-items-center' style={{height: "70.5px"}}>
                                    <h4 className="card-title mb-0 flex-grow-1">IP addresses linked</h4>
                                    <div className='d-flex gap-1'>
                                        <LoadingSpinner show={loadingIp} />
                                        <Button type="button" className="btn btn-info" onClick={() => { 
                                                dispatch(resetLimitDataState()); 
                                                navigate(-1);
                                            }}>
                                            BACK TO PREVIOUS PAGE
                                        </Button>   
                                    </div>        
                                </CardHeader>

                                <CardBody>
                                    <div id="table-payments-due" className="table-card">                        
                                        <DataTable
                                            columns={[
                                                {
                                                    name: "IP address",
                                                    selector: row => row.ip,
                                                    sortable: true,
                                                }
                                            ]}                                
                                            data={tableData}
                                            pagination                                     
                                            theme={chartMode === "dark" ? "dark" : "default"}
                                        />
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />

                </Container>
            </div>

        </React.Fragment>
    );
}

export default ApikeyHistory;