import { decodeToken, isExpired } from 'react-jwt';
import axios from 'axios';
import { setAuthorization } from './api_helper';
import {
  setFirebaseUser
} from "../store/token/actions";
import store from "../store/token/store";
import config from '../config';
import Cookies from 'js-cookie';

class TokenManager {
  constructor(initialToken, firebaseProjectId, firebaseApiKey) {
    this.GOOGLE_CERTS_URL = 'https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com';
    this.FIREBASE_PROJECT_ID = firebaseProjectId;
    this.FIREBASE_API_KEY = firebaseApiKey;
    this.REFRESH_TOKEN_URL = config.coinAPILogin;

    this.publicKeys = {};
    this.publicKeysExpiry = 0;
    this.currentToken = initialToken;
    this.tokenExpirationTime = null;

    if (initialToken) {
      this.setInitialToken(initialToken);
    }
  }

  async getGooglePublicKeys() {
    if (Date.now() < this.publicKeysExpiry) {
      return this.publicKeys;
    }

    const response = await axios.get(this.GOOGLE_CERTS_URL);
    this.publicKeys = response.data;

    const cacheControl = response.headers['cache-control'];
    const maxAge = cacheControl.split('max-age=')[1].split(',')[0];
    this.publicKeysExpiry = Date.now() + parseInt(maxAge) * 1000;

    return this.publicKeys;
  }

  getUserDataFromToken() {
    if (!this.currentToken) {
      this.refreshToken();
    }

    const decodedToken = decodeToken(this.currentToken);
    // console.log(decodedToken);
    if (!decodedToken) {
      throw new Error('Invalid token');
    }

    // Extract relevant user data from the token payload
    const userData = {
      userId: decodedToken.sub,
      email: decodedToken.email,
      emailVerified: decodedToken.email_verified,
      name: decodedToken.name,
      picture: decodedToken.picture,
      admin: decodedToken.admin ?? false,
      support: decodedToken.support ?? false,
      // Add any other relevant fields from the token payload
    };

    return userData;
  }

  async verifyToken(token) {
    const keys = await this.getGooglePublicKeys();

    return new Promise((resolve, reject) => {
      const decodedToken = decodeToken(token);
      if (!decodedToken) {
        reject(new Error('Invalid token'));
        return;
      }

      //   const kid = decodedToken.header.kid;
      //   const publicKey = keys[kid];

      //   if (!publicKey) {
      //     reject(new Error('Public key not found'));
      //     return;
      //   }

      // Additional payload checks
      const now = Math.floor(Date.now() / 1000);
      if (isExpired(token)) {
        reject(new Error('Token has expired'));
        return;
      }
      if (decodedToken.iat > now) {
        reject(new Error('Token issued in the future'));
        return;
      }
      if (decodedToken.aud !== this.FIREBASE_PROJECT_ID) {
        reject(new Error('Invalid audience'));
        return;
      }
      if (decodedToken.iss !== `https://securetoken.google.com/${this.FIREBASE_PROJECT_ID}`) {
        reject(new Error('Invalid issuer'));
        return;
      }
      if (typeof decodedToken.sub !== 'string' || decodedToken.sub.length === 0) {
        reject(new Error('Invalid subject'));
        return;
      }

      resolve(decodedToken);
    });
  }

  async getValidToken() {
    const currentTime = Math.floor(Date.now() / 1000);

    if (this.currentToken && this.tokenExpirationTime && currentTime < this.tokenExpirationTime - 60) {
      // Token is still valid (with 1 minute buffer)
      return this.currentToken;
    }

    // Token needs to be refreshed
    return this.refreshToken();
  }

  async refreshToken() {
    // try {
    // //   const response = await axios.post(this.REFRESH_TOKEN_URL, {
    // //     // Include any necessary data for refreshing the token
    // //     refreshToken: 'your_refresh_token_here'
    // //   });

    // const response = await axios.post(`${this.REFRESH_TOKEN_URL}?key=${this.FIREBASE_API_KEY}`, {
    //     grant_type: 'refresh_token',
    //     refresh_token: this.currentToken
    //   }, {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   });

    //   if (response.data && response.data.token) {
    //     const decodedToken = await this.verifyToken(response.data.token);
    //     this.currentToken = response.data.token;
    //     this.tokenExpirationTime = decodedToken.exp;
    //     return this.currentToken;
    //   } else {
    //     throw new Error('Failed to refresh token');
    //   }
    // } catch (error) {
    //   console.error('Error refreshing token:', error);
    //   throw error;
    // }
    const hostUrl = window.location.protocol + "//" + window.location.host;
    const url = `${this.REFRESH_TOKEN_URL}?return_to=${hostUrl}${window.location.pathname}&return_sso=${hostUrl}/access/sso`;
    window.open(url, "_self");
  }

  async setInitialToken(token) {
    try {
      const decodedToken = await this.verifyToken(token);
      this.currentToken = token;
      this.tokenExpirationTime = decodedToken.exp;
      store.dispatch(setFirebaseUser(this.getUserDataFromToken()));
      // Proceed with your app logic for authenticated users
    } catch (error) {
      //   console.error('Token verification failed:', error);
      //   throw error;
      this.refreshToken();
    }
  }

  async makeAuthenticatedRequest(url, method = 'GET', body = null) {
    try {
      const token = await this.getValidToken();
      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: body ? JSON.stringify(body) : null
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }
}

// // Usage example
// const tokenManager = new TokenManager('your_initial_token_here', 'YOUR_FIREBASE_PROJECT_ID');
// tokenManager.makeAuthenticatedRequest('https://api.example.com/data')
//   .then(data => console.log(data))
//   .catch(error => console.error('Request failed:', error));

let _tokenManager = null;

const initTokenManager = (initialToken, firebaseProjectId, firebaseApiKey) => {
  if (!_tokenManager) {
    if (initialToken) {
      setAuthorization(initialToken);
      localStorage.setItem("token", initialToken);
    }
    else {
      initialToken = localStorage.getItem("token");
      setAuthorization(localStorage.getItem("token"));
    }
    _tokenManager = new TokenManager(initialToken, firebaseProjectId, firebaseApiKey);
  }
  return _tokenManager;
}

const getTokenManager = () => {
  return _tokenManager;
}

const logoutTokenManager = () => {
  localStorage.removeItem("token");
  setAuthorization(null);
};

export { initTokenManager, getTokenManager, logoutTokenManager };
