import React from "react";

// import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import Metrics from "../pages/Metrics/index";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Apikeys from "../pages/Apikeys";
import Subscriptions from "../pages/Subscriptions";
import Users from "../pages/Users";
import ApikeyHistory from "../pages/ApikeyHistory";
import ApiKeyAuthConfiguration from "../pages/ApikeyAuth";
import Impersonate from "../pages/Impersonate";
import CustomerEvents from "../pages/CustomerEvents";
import SupportTickets from "../pages/SupportTickets";
import TicketDetailsIdx from "../pages/SupportTickets/TicketDetailsIdx";
import AddNewTicket from "../pages/SupportTickets/AddNewTicket";
import AgentTickets from "../pages/AgentTickets";
import DomainView from "../pages/DomainView";
import CustomerActive from "../pages/CustomerActive";
import CustomerPast from "../pages/CustomerPast";
import Traces from "../pages/Traces";
import Organization from "../pages/Organization";
import Notifications from "../pages/Notifications";
import Limits from "../pages/Limits";
import QuotasHorizontal from "../pages/QuotasHorizontal";
import Billing from "../pages/Billing/index";
import Activity from "../pages/Activity";
import SSO from "../pages/SSO";
import TracesConnections from "../pages/Traces_Connections";
import Problems from "../pages/Problems";
import OrganizationsAdminActive from "../pages/OrganizationsAdminActive";
import OrganizationsAdminPast from "../pages/OrganizationsAdminPast";

const authProtectedRoutes = [
  // { path: "/", component: <Dashboard /> },
  { path: "/subscriptions", component: <Subscriptions /> },
  { path: "/apikeys", component: <Apikeys /> },
  { path: "/apikeys/apikeyhistory", component: <ApikeyHistory /> },
  { path: "/apikeys/apikeyauth", component: <ApiKeyAuthConfiguration /> },
  // { path: "/invoices", component: <Invoices /> },
  { path: "/", component: <Subscriptions /> },
  // { path: "/payments", component: <Payments /> },
  { path: "/metrics", component: <Metrics /> },
  { path: "/users", component: <Users /> },
  { path: "/support-tickets", component: <SupportTickets /> },
  { path: "/support-tickets/details", component: <TicketDetailsIdx /> },
  { path: "/support-tickets/add", component: <AddNewTicket />},
  { path: "/impersonate", component: <Impersonate /> },
  { path: "/events", component: <CustomerEvents /> },
  { path: "/agent-tickets", component: <AgentTickets /> },
  { path: "/domain", component: <DomainView /> },
  { path: "/customer-active", component: <CustomerActive /> },
  { path: "/customer-past", component: <CustomerPast /> },
  { path: "/traces", component: <Traces /> },
  { path: "/traces/connections", component: <TracesConnections /> },
  { path: "/organization", component: <Organization /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/limits", component: <Limits /> },
  { path: "/quotaHorizontal", component: <QuotasHorizontal /> },
  { path: "/billing", component: <Billing /> },
  { path: "/activity", component: <Activity /> },
  { path: "/problems", component: <Problems /> },
  { path: "/organization-info", component: <OrganizationsAdminActive /> },
  { path: "/organization-info-past", component: <OrganizationsAdminPast /> },
];

const publicRoutes = [  
  { path: "*", component: <Basic404 /> },
  { path: "/access/sso", component: <SSO /> },
];

export { authProtectedRoutes, publicRoutes };
